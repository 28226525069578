import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    public isMobile(): boolean {
        return this.isAndroid() || this.isIOS() || this.isBlackBerry() || this.isOpera() || this.isWindows();
    }

    public isAndroid(): boolean {
        return !!navigator.userAgent.match(/Android/i);
    }

    public isBlackBerry(): boolean {
        return !!navigator.userAgent.match(/BlackBerry/i);
    }

    public isIOS(): boolean {
        return !!navigator.userAgent.match(/iPhone|iPod/i);
    }

    public isOpera(): boolean {
        return !!navigator.userAgent.match(/Opera Mini/i);
    }

    public isWindows(): boolean {
        return !!navigator.userAgent.match(/IEMobile/i) || !!navigator.userAgent.match(/WPDesktop/i);
    }

    public isTablet(): boolean {
        return window.innerWidth <= 1024;
    }
}
