import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AnalyticConfig } from 'src/app/types/google-analytics.types';

@Injectable({
    providedIn: 'root'
})
export class AnalyticService {
    constructor(private google: GoogleAnalyticsService) {}

    public event(config: AnalyticConfig, label: string): void {
        this.google.event(config.action, config.category, label, 1, true);
    }
}
