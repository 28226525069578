/* eslint-disable import/no-named-default */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getLocations } from 'src/app/store/selectors/location.selectors';
import { Location } from 'src/app/types/locations.types';
import { ICONS, REGEXP, Roles, ROLES } from 'src/app/types/types';

import * as _moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { default as _rollupMoment, Moment } from 'moment';
import { getEmail, getGainerInfo, getPassword } from 'src/app/store/selectors/auth.selectors';
import { Gainer, GAINER_DEFAULT } from 'src/app/types/api.types';
import { DomSanitizer } from '@angular/platform-browser';
import { SetLocationsAction } from 'src/app/store/actions/location/set-locations.action';
import { GetLocationsAction } from 'src/app/store/actions/location/get-locations.action';
import { SetGainerInfo } from 'src/app/store/actions/auth/set-gainer-info.action';
import { SetStepAction } from 'src/app/store/actions/auth/set-step.action';
import { RegisterGainerEffectAction } from 'src/app/store/actions/auth/register-gainer-effect.action';
import { AnalyticConfig, AnalyticTypes, ANALYTIC_TYPES, SAVE_PROFILE_CLICKED } from 'src/app/types/google-analytics.types';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY'
    },
    display: {
        dateInput: 'YYYY',
        monthYearLabel: 'YYYY',
        monthYearA11yLabel: 'YYYY'
    }
};

@Component({
    selector: 'app-gainer-info',
    templateUrl: './gainer-info.component.html',
    styleUrls: ['./gainer-info.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class GainerInfoComponent implements OnInit {
    @Input() register: boolean;

    @Output() valid: EventEmitter<boolean> = new EventEmitter();

    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public SAVE_PROFILE_CLICKED: AnalyticConfig = { ...SAVE_PROFILE_CLICKED };

    public ROLES: Roles = { ...ROLES };

    public registerForm: FormGroup = this.fb.group({});

    public ICONS = { ...ICONS };

    public max: Date = new Date();

    public min: Date = new Date(new Date().setFullYear(1900));

    public gainer: Gainer = { ...GAINER_DEFAULT };

    public locations$: Observable<Array<Location>>;

    constructor(private fb: FormBuilder, private store: Store, private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        this.locations$ = this.store.select(getLocations);

        this.store.select(getGainerInfo).subscribe((gainer: Gainer) => {
            this.gainer = { ...gainer };

            this.registerForm = this.fb.group({
                name: [this.sanitizer.sanitize(1, gainer.name), [Validators.required, Validators.pattern(REGEXP.LATIN)]],
                location: [this.sanitizer.sanitize(1, gainer.location), [Validators.required]],
                birth: [
                    moment(new Date(new Date().setFullYear(Number(gainer.year_of_birth) ? Number(gainer.year_of_birth) : 0)))
                        .year()
                        .toString(),
                    [Validators.required]
                ],
                lat: [gainer.lat],
                lng: [gainer.lng]
            });

            this.registerForm.valueChanges.subscribe(() => {
                this.store.dispatch(
                    new SetGainerInfo({
                        ...this.gainer,
                        location: this.registerForm.controls.location.value,
                        name: this.registerForm.controls.name.value,
                        lat: this.registerForm.controls.lat.value,
                        lng: this.registerForm.controls.lng.value,
                        year_of_birth:
                            typeof this.registerForm.controls.birth.value === 'string'
                                ? this.registerForm.controls.birth.value
                                : this.registerForm.controls.birth.value.year().toString()
                    })
                );

                this.valid.emit(this.registerForm.valid);
            });
        });
    }

    public setLocation(location: Location): void {
        this.registerForm = this.fb.group({
            name: [this.registerForm.controls.name.value, [Validators.required]],
            location: [location.place_name, [Validators.required]],
            birth: [
                moment(new Date(new Date().setFullYear(this.registerForm.controls.birth.value)))
                    .year()
                    .toString(),
                [Validators.required]
            ],
            lat: [location.center[1]],
            lng: [location.center[0]]
        });

        this.store.dispatch(
            new SetGainerInfo({
                ...this.gainer,
                location: this.registerForm.controls.location.value,
                name: this.registerForm.controls.name.value,
                lat: this.registerForm.controls.lat.value,
                lng: this.registerForm.controls.lng.value,
                year_of_birth:
                    typeof this.registerForm.controls.birth.value === 'string'
                        ? this.registerForm.controls.birth.value
                        : this.registerForm.controls.birth.value.year().toString()
            })
        );

        this.store.dispatch(new SetLocationsAction([]));
    }

    public goBack(): void {
        this.store.dispatch(new SetStepAction(0));
    }

    public submit(): void {
        const passwordSelector = this.store.select(getPassword).subscribe((password: string) => {
            if (password.trim()) {
                const emailSelector = this.store.select(getEmail).subscribe((email: string) => {
                    if (email.trim()) {
                        this.store.dispatch(
                            new RegisterGainerEffectAction({
                                gainer: {
                                    ...this.gainer
                                },
                                user: {
                                    email,
                                    password,
                                    role: ROLES.GAINER,
                                    accessToken: '',
                                    approved: false
                                }
                            })
                        );
                        emailSelector.unsubscribe();
                        passwordSelector.unsubscribe();
                    } else {
                        emailSelector.unsubscribe();
                        passwordSelector.unsubscribe();
                    }
                });
            } else {
                passwordSelector.unsubscribe();
            }
        });
    }

    public selectBirthdayYear(normalizedYear: Moment, datepicker: MatDatepicker<Moment>): void {
        this.registerForm.controls.birth.setValue(moment().year(normalizedYear.year()));
        datepicker.close();
    }

    public searchLocations($event): void {
        if ($event.target.value.length > 1) {
            this.store.dispatch(new GetLocationsAction($event.target.value));
            return;
        }

        this.store.dispatch(new SetLocationsAction([]));
    }
}
