import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { RestoreEffectAction } from 'src/app/store/actions/auth/restore-effect.action';

@Component({
    selector: 'app-restore',
    templateUrl: './restore.component.html',
    styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit {
    private token: string = '';

    public showStrength: boolean = false;

    public hideConfirmPassword: boolean = true;

    public restoreForm: FormGroup = this.fb.group({});

    constructor(private fb: FormBuilder, private store: Store, private route: ActivatedRoute) {}

    public ngOnInit(): void {
        this.restoreForm = this.fb.group({
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
            repeatPassword: new FormControl('', [Validators.required, this.mismatchValidator()])
        });

        this.route.queryParams.subscribe((params: Params) => {
            if (!!params.token && !!params.token.trim()) {
                this.token = params.token;
            }
        });
    }

    private mismatchValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const mismatch = this.restoreForm.value.password !== control.value;
            return mismatch ? { mismatch: { value: control.value } } : null;
        };
    }

    public restore(): void {
        this.store.dispatch(
            new RestoreEffectAction({
                token: this.token,
                password: this.restoreForm.value.password,
                confirmPassword: this.restoreForm.value.repeatPassword
            })
        );
    }
}
