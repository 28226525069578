import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'app-crop-modal',
    templateUrl: './crop-modal.component.html',
    styleUrls: ['./crop-modal.component.scss']
})
export class CropModalComponent {
    public croppedImage: string = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: { src: string }, public dialogRef: MatDialogRef<CropModalComponent>) {}

    public imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
    }

    public cropImage(): void {
        this.dialogRef.close(this.croppedImage);
    }

    public close(): void {
        this.dialogRef.close('cancel');
    }
}
