<section class="reply">
    <div mat-dialog-title class="modal__title">
        <div>
            <span lang key="REPLY.REPLY_TO"></span>
            <span class="modal__title--name">{{ data.review.user.name }}</span>
        </div>
    </div>

    <div mat-dialog-title class="modal__content">
        <app-review [review]="data.review"></app-review>

        <mat-form-field>
            <mat-label>
                <span lang key="REVIEW.ADD_MESSAGE"></span>
            </mat-label>
            <textarea matInput type="text" sanitize [(ngModel)]="reply.text"></textarea>
        </mat-form-field>

        <section *ngIf="reply.images.length !== 0">
            <section class="upload">
                <section class="upload--image" *ngFor="let image of reply.images; let i = index">
                    <img (click)="show(IMAGE_PATH + image.name)" [src]="IMAGE_PATH + image.name" />
                    <mat-icon (click)="removeImage(i)" class="image__remove">{{ ICONS.CLOSE }}</mat-icon>
                </section>
            </section>
        </section>
    </div>

    <div mat-dialog-actions class="modal__actions">
        <div class="modal__actions--left">
            <input multiple id="images" accept=".jpg,.jpeg,.png" type="file" (change)="uploadImage($event)" />
            <button class="reviews__button--upload" mat-button>
                <label for="images">
                    <span lang key="ACTIONS.BROWSE_FILE"></span>
                </label>
            </button>
        </div>

        <div class="modal__actions--right">
            <button (click)="close()" mat-button>
                <span lang key="ACTIONS.CANCEL"></span>
            </button>
            <button [disabled]="!reply.text.trim()" class="reviews__button--send" mat-button (click)="send()">
                <span lang key="ACTIONS.SEND"></span>
            </button>
        </div>
    </div>
</section>
