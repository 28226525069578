import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { API } from 'src/app/types/api.types';
import { COOKIES, Dialog, IMAGES } from 'src/app/types/types';
import { ModalComponent } from 'src/app/templates/modals/modal/modal.component';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { SetMenuAction } from 'src/app/store/actions/menu/set-menu,action';
import { SetAccessTokenAction } from 'src/app/store/actions/auth/set-access-token.action';
import { SetEmailAction } from 'src/app/store/actions/auth/set-email.action';
import { LogOutEffect } from 'src/app/store/actions/auth/logout-effect.action';

@Injectable({
    providedIn: 'root'
})
export class ResponseErrorHandler implements ErrorHandler {
    constructor(private cookie: CookieService, private dialog: MatDialog, private injector: Injector) {}

    handleError(error: any) {
        const store = this.injector.get(Store);

        switch (error.url) {
            case environment.apiUrl + API.GET.ACADEMY:
            case environment.apiUrl + API.GET.GAINER: {
                break;
            }
            case environment.apiUrl + API.POST.UPLOAD: {
                this.modal({
                    message: 'ERRORS.WRONG',
                    confirm: 'TRY_AGAIN',
                    image: IMAGES.ERROR
                });
                break;
            }
            case environment.apiUrl + API.POST.CHANGE_PASSWORD: {
                switch (error.status) {
                    case 403: {
                        this.cookie.delete(COOKIES.TOKEN);
                        this.cookie.delete(COOKIES.EMAIL);
                        this.cookie.delete(COOKIES.ACADEMY);

                        store.dispatch(new SetEmailAction(''));
                        store.dispatch(new SetAccessTokenAction(''));
                        store.dispatch(
                            new SetMenuAction({
                                page: '',
                                open: false
                            })
                        );

                        store.dispatch(new LogOutEffect());
                        break;
                    }

                    case 422: {
                        this.modal({
                            message: 'ERRORS.CHANGE_PASSWORD',
                            confirm: 'TRY_AGAIN',
                            image: IMAGES.ERROR
                        });
                        break;
                    }
                    default:
                        break;
                }
                break;
            }
            case environment.apiUrl + API.GET.CHECK_EMAIL: {
                switch (error.status) {
                    case 422: {
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__error',
                            data: {
                                message: 'ERRORS.REGISTER_EXIST_EMAIL',
                                confirm: 'OK',
                                image: IMAGES.ERROR
                            }
                        });

                        break;
                    }
                    default:
                        break;
                }
                break;
            }
            case environment.apiUrl + API.POST.REGISTER_GAINER:
            case environment.apiUrl + API.POST.REGISTER: {
                switch (error.status) {
                    case 422: {
                        this.modal({
                            message: 'ERRORS.WRONG',
                            confirm: 'TRY_AGAIN',
                            image: IMAGES.ERROR
                        });

                        break;
                    }
                    default:
                        break;
                }
                break;
            }
            case environment.apiUrl + API.POST.LOGIN: {
                this.cookie.delete(COOKIES.TOKEN);
                switch (error.status) {
                    case 403: {
                        if (error.message === 'Your account has been blocked') {
                            this.modal({
                                message: 'ERRORS.BLOCKED',
                                confirm: 'OK',
                                image: IMAGES.ERROR
                            });
                        } else {
                            this.modal({
                                message: 'ERRORS.NOT_APPROVED_YET',
                                confirm: 'OK',
                                image: IMAGES.ERROR
                            });
                        }

                        break;
                    }
                    default: {
                        this.modal({
                            message: 'ERRORS.LOGIN_INVALID',
                            confirm: 'TRY_AGAIN',
                            image: IMAGES.ERROR
                        });
                    }
                }

                break;
            }
            case environment.apiUrl + API.POST.LOGOUT: {
                switch (error.status) {
                    case 403: {
                        this.cookie.delete(COOKIES.TOKEN);
                        this.cookie.delete(COOKIES.EMAIL);
                        this.cookie.delete(COOKIES.ACADEMY);
                        store.dispatch(new SetEmailAction(''));
                        store.dispatch(new SetAccessTokenAction(''));
                        break;
                    }
                    default: {
                        break;
                    }
                }
                break;
            }
            case environment.apiUrl + API.POST.RESTORE: {
                switch (error.status) {
                    case 422: {
                        this.modal({
                            message: 'ERRORS.RESTORE',
                            confirm: 'OK',
                            image: IMAGES.ERROR
                        });
                        break;
                    }
                    default: {
                        break;
                    }
                }

                break;
            }
            case environment.apiUrl + API.POST.RESTORE_CONFIRM: {
                switch (error.status) {
                    case 403: {
                        this.modal({
                            message: 'ERRORS.RESTORE_REQUEST',
                            confirm: 'OK',
                            image: IMAGES.ERROR
                        });
                        break;
                    }

                    default: {
                        this.modal({
                            message: 'RESTORE_CONFIRM_EXPIRED',
                            confirm: 'OK',
                            image: IMAGES.ERROR
                        });
                        break;
                    }
                }
                break;
            }
            case environment.apiUrl + API.PATCH.ACADEMY: {
                switch (error.status) {
                    default: {
                        this.modal({
                            message: 'ERRORS.WRONG',
                            confirm: 'TRY_AGAIN',
                            image: IMAGES.ERROR
                        });
                        break;
                    }
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    private modal(data: Dialog): void {
        if (this.dialog.getDialogById('modal__error--injector')) {
            return;
        }
        this.dialog.open(ModalComponent, {
            panelClass: 'modal__error',
            id: 'modal__error--injector',
            data: {
                ...data
            }
        });
    }
}
