<mat-chip-list class="schedule__week">
    <mat-chip class="schedule__day" [color]="'#F0F3F5'">
        <span class="schedule__title--short" lang key="SCHEDULE.SUNDAY_SHORT"></span>
        <span class="schedule__start-time">{{ schedule.sunday.start_time }}</span>
        <span class="schedule__end-time">{{ schedule.sunday.end_time }}</span>
    </mat-chip>

    <mat-chip class="schedule__day" [color]="'#F0F3F5'">
        <span class="schedule__title--short" lang key="SCHEDULE.MONDAY_SHORT"></span>
        <span class="schedule__start-time">{{ schedule.monday.start_time }}</span>
        <span class="schedule__end-time">{{ schedule.monday.end_time }}</span>
    </mat-chip>

    <mat-chip class="schedule__day" [color]="'#F0F3F5'">
        <span class="schedule__title--short" lang key="SCHEDULE.TUESDAY_SHORT"></span>
        <span class="schedule__start-time">{{ schedule.tuesday.start_time }}</span>
        <span class="schedule__end-time">{{ schedule.tuesday.end_time }}</span>
    </mat-chip>

    <mat-chip class="schedule__day" [color]="'#F0F3F5'">
        <span class="schedule__title--short" lang key="SCHEDULE.WEDNESDAY_SHORT"></span>
        <span class="schedule__start-time">{{ schedule.wednesday.start_time }}</span>
        <span class="schedule__end-time">{{ schedule.wednesday.end_time }}</span>
    </mat-chip>

    <mat-chip class="schedule__day" [color]="'#F0F3F5'">
        <span class="schedule__title--short" lang key="SCHEDULE.THURSDAY_SHORT"></span>
        <span class="schedule__start-time">{{ schedule.thursday.start_time }}</span>
        <span class="schedule__end-time">{{ schedule.thursday.end_time }}</span>
    </mat-chip>

    <mat-chip class="schedule__day" [color]="'#F0F3F5'">
        <span class="schedule__title--short" lang key="SCHEDULE.FRIDAY_SHORT"></span>
        <span class="schedule__start-time">{{ schedule.friday.start_time }}</span>
        <span class="schedule__end-time">{{ schedule.friday.end_time }}</span>
    </mat-chip>

    <mat-chip class="schedule__day" [color]="'#F0F3F5'">
        <span class="schedule__title--short" lang key="SCHEDULE.SATURDAY_SHORT"></span>
        <span class="schedule__start-time">{{ schedule.saturday.start_time }}</span>
        <span class="schedule__end-time">{{ schedule.saturday.end_time }}</span>
    </mat-chip>
</mat-chip-list>
