import { Store } from '@ngrx/store';
import { Location, LOCATION_DEFAULT } from 'src/app/types/locations.types';
import { Component, OnInit } from '@angular/core';
import { PRICE_FILTER, Price, AcademyType, PriceType, ACADEMY_TYPES } from 'src/app/types/academy.types';
import { Amenity } from 'src/app/types/amenity.types';
import { getType, searchEvent } from 'src/app/store/selectors/academy.selectors';
import { ICONS } from 'src/app/types/types';
import { getCurrentLocation } from 'src/app/store/selectors/location.selectors';
import { getAmenities } from 'src/app/store/selectors/amenity.selectors';
import { Skill } from 'src/app/types/skill.types';
import { ToggleFilterAction } from 'src/app/store/actions/academy/toggle-filter.action';
import { SearchAction } from 'src/app/store/actions/academy/search.action';
import { SetFilterCountAction } from 'src/app/store/actions/academy/set-filter-count.action';
import { FilterAcademyEffectAction } from 'src/app/store/actions/academy/filter-academy-effect.action';
import { AnalyticTypes, ANALYTIC_TYPES, FILTER_SELECTED } from 'src/app/types/google-analytics.types';
import { AnalyticService } from 'src/app/core/services/analytic.service';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public cleanSkills: boolean = false;

    public name: string = '';

    private count: number = 0;

    private skillsCount: number = 0;

    public TYPES = { ...ACADEMY_TYPES };

    public ICONS = { ...ICONS };

    public type: AcademyType;

    public location: Location = LOCATION_DEFAULT;

    public filterSkills: Array<number> = [];

    public filterPrice: Array<Price> = new Array(...PRICE_FILTER);

    public filterAmenities: Array<number> = [];

    public amenities: Array<Amenity>;

    private price: Array<PriceType> = [];

    private skills: Array<Skill> = [];

    constructor(private store: Store, private analytics: AnalyticService) {}

    public ngOnInit(): void {
        this.store.select(getAmenities).subscribe((amenities: Array<Amenity>) => {
            this.amenities = new Array(
                ...amenities.map((amenity: Amenity) => ({
                    ...amenity,
                    model: false
                }))
            );
        });

        this.store.select(getCurrentLocation).subscribe((location: Location) => {
            this.location = { ...location };
        });

        this.store.select(getType).subscribe((type: AcademyType) => {
            this.type = type;
            this.filter(false);
        });

        this.store.select(searchEvent).subscribe((search: boolean) => {
            if (search) {
                this.filter(false);
                this.store.dispatch(new SearchAction(false));
            }
        });
    }

    public switchPrice(price: Price): void {
        try {
            const index = this.price.findIndex((p: PriceType) => p === price.type);
            if (price.model) {
                if (index === -1) {
                    this.count += 1;
                    this.price = [...this.price, price.type];
                }
            } else if (index !== -1) {
                this.count -= 1;
                this.price = [...this.price.filter((p) => p !== price.type)];
            }

            this.store.dispatch(new SetFilterCountAction(this.count + this.skillsCount));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('error switch price range', error);
        }
    }

    public switchAmenity(amenity: Amenity): void {
        try {
            const index = this.filterAmenities.findIndex((a: number) => a === amenity.id);
            if (amenity.model) {
                if (index === -1) {
                    this.count += 1;
                    this.filterAmenities = [...this.filterAmenities, amenity.id];
                }
            } else if (index !== -1) {
                this.count -= 1;
                this.filterAmenities = [...this.filterAmenities.filter((a) => a !== amenity.id)];
            }

            this.store.dispatch(new SetFilterCountAction(this.count + this.skillsCount));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('error switch amenity range', error);
        }
    }

    public filter(close?: boolean): void {
        this.store.dispatch(
            new FilterAcademyEffectAction({
                lat: this.location.center[1].toString(),
                lng: this.location.center[0].toString(),
                type: this.type,
                name: this.name,
                skills: this.skills,
                price: this.price,
                amenities: this.filterAmenities
            })
        );

        if (close) {
            this.store.dispatch(new ToggleFilterAction());
        }

        if (this.skills.length) {
            this.skills.forEach((skill: Skill) => {
                this.analytics.event(FILTER_SELECTED, skill.name);
            });
        }
    }

    public clear(): void {
        this.cleanSkills = !this.cleanSkills;
        this.skills = [];
        this.price = [];
        this.filterAmenities = [];

        this.count = 0;
        this.skillsCount = 0;

        this.name = '';

        this.filterPrice.forEach((_, index: number) => {
            this.filterPrice[index].model = false;
        });

        this.amenities.forEach((_, index: number) => {
            this.amenities[index].model = false;
        });

        this.store.dispatch(
            new FilterAcademyEffectAction({
                lat: this.location.center[1].toString(),
                lng: this.location.center[0].toString(),
                type: this.type,
                name: '',
                skills: [],
                price: [],
                amenities: []
            })
        );
        this.store.dispatch(new ToggleFilterAction());
        this.store.dispatch(new SetFilterCountAction(this.count + this.skillsCount));
    }

    public selectedSkills(skills: Array<Skill>): void {
        this.skills = [...skills]; // .map((skill: Skill) => skill.id);

        this.skillsCount = this.skills.length;
        this.store.dispatch(new SetFilterCountAction(this.count + this.skillsCount));
    }
}
