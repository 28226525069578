import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { GetLocationByCoordsEffect } from 'src/app/store/actions/location/get-location-by-coords-effect.action';
import { GetLocationsAction } from 'src/app/store/actions/location/get-locations.action';
import { SetLocationAction } from 'src/app/store/actions/location/set-location.action';
import { SetLocationsAction } from 'src/app/store/actions/location/set-locations.action';
import { getCurrentLocation, getLocations } from 'src/app/store/selectors/location.selectors';
import { ModalComponent } from 'src/app/templates/modals/modal/modal.component';
import { AcademyType, TYPES } from 'src/app/types/academy.types';
import { AnalyticTypes, ANALYTIC_TYPES } from 'src/app/types/google-analytics.types';
import { CurrentPositionError, Location, LOCATION_DEFAULT } from 'src/app/types/locations.types';
import { COOKIES, ICONS, IMAGES } from 'src/app/types/types';

@Component({
    selector: 'search-modal',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchModalComponent implements OnInit {
    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public focus: boolean = false;

    public step: number = 0;

    public search: string = '';

    public ICONS: Record<string, string> = { ...ICONS };

    public location: Location;

    public type: AcademyType;

    public types: Array<AcademyType> = [...TYPES];

    public locations: Array<Location> = [];

    constructor(
        public current: MatDialogRef<SearchModalComponent>,
        private dialog: MatDialog,
        private cookie: CookieService,
        private store: Store
    ) {}

    public ngOnInit(): void {
        this.store.select(getLocations).subscribe((locations: Array<Location>) => {
            this.locations = locations;
        });
    }

    public continue(): void {
        this.step = 1;
    }

    public close(): void {
        this.current.close({
            location: this.location,
            type: this.type
        });
    }

    public selectLocation(location: Location): void {
        this.cookie.set(COOKIES.LOCATION, JSON.stringify(location));
        this.location = { ...location };

        this.search = this.location.place_name;

        this.locations = [];
        this.store.dispatch(new SetLocationsAction([]));
    }

    public getLocation(): void {
        if (this.search.length > 0) {
            this.store.dispatch(new GetLocationsAction(this.search));
        }
    }

    public switchType(type: AcademyType): void {
        this.type = type;
    }

    public findLocationByUser(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.store.dispatch(new SetLocationAction(LOCATION_DEFAULT));
                    this.store.dispatch(
                        new GetLocationByCoordsEffect({
                            lat: position.coords.longitude,
                            lng: position.coords.latitude
                        })
                    );

                    const locationSelector = this.store.select(getCurrentLocation).subscribe((location: Location) => {
                        if (location.place_name !== '') {
                            this.search = location.place_name;
                            this.location = location;
                            this.cookie.set(COOKIES.LOCATION, JSON.stringify(location));
                            this.focus = false;
                            locationSelector.unsubscribe();
                        }
                    });
                },
                (error: CurrentPositionError) => {
                    this.focus = false;
                    switch (error.code) {
                        case 1: {
                            this.dialog.open(ModalComponent, {
                                panelClass: 'modal__error',
                                data: {
                                    message: 'ERRORS.USER_DENIED_GEOLOCATION',
                                    confirm: 'OK',
                                    image: IMAGES.ERROR
                                }
                            });
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
            );
        }
    }
}
