import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetAmenitiesEffectAction } from './store/actions/amenity/get-amenities-effect.action';
import { GetSkillsEffectAction } from './store/actions/skills/get-skills-effect.action';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    constructor(private store: Store) {
        this.store.dispatch(new GetSkillsEffectAction());
        this.store.dispatch(new GetAmenitiesEffectAction());
    }
}
