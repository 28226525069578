<section>
    <section *ngIf="thumbnail">
        <h4><span lang key="AUTH.THUMBNAIL_IMAGE"></span> *</h4>
        <section class="upload--thumbnail">
            <section>
                <img
                    [src]="
                        (academy$ | async).thumbnail.name !== IMAGE_DEFAULT
                            ? IMAGE_PATH + (academy$ | async).thumbnail.name
                            : (academy$ | async).thumbnail.name
                    "
                />

                <mat-icon
                    *ngIf="(academy$ | async).thumbnail.name !== IMAGE_DEFAULT"
                    (click)="removeImage('thumbnail')"
                    class="image__remove"
                    >{{ ICONS.CLOSE }}</mat-icon
                >
            </section>

            <label for="thumbnail" class="upload--upload">
                <span lang key="ACTIONS.BROWSE_FILE"></span>
            </label>

            <input
                id="thumbnail"
                [(ngModel)]="thumbnailFile"
                accept=".jpg,.jpeg,.png"
                type="file"
                (change)="uploadImage($event, 'thumbnail')"
            />
        </section>
    </section>

    <section *ngIf="gallery">
        <h4><span lang key="AUTH.GALLERY_IMAGES"></span> *</h4>

        <section class="upload--gallery">
            <section *ngFor="let gallery of (academy$ | async).gallery; let i = index">
                <img
                    [src]="gallery.name !== IMAGE_DEFAULT ? IMAGE_PATH + gallery.name : gallery.name"
                    [style.objectFit]="gallery.name !== IMAGE_DEFAULT ? 'cover' : 'contain'"
                />
                <mat-icon *ngIf="gallery.name !== IMAGE_DEFAULT" (click)="removeImage('gallery', i)" class="image__remove">
                    {{ ICONS.CLOSE }}
                </mat-icon>
            </section>
            <label for="gallery" class="upload--upload">
                <span lang key="ACTIONS.BROWSE_FILE"></span>
            </label>
            <input multiple id="gallery" accept=".jpg,.jpeg,.png" type="file" (change)="uploadImage($event, 'gallery')" />
        </section>

        <h5><span class="upload--gallery-info" lang key="AUTH.GALLERY_INFO"></span></h5>
    </section>

    <section *ngIf="schedule">
        <h4><span lang key="AUTH.SCHEDULE_IMAGE"></span></h4>
        <section class="upload--gallery">
            <img *ngIf="(academy$ | async).schedule.length === 0" [src]="IMAGE_DEFAULT" [style.objectFit]="'contain'" />
            <section *ngFor="let schedule of (academy$ | async).schedule; let i = index">
                <img
                    [src]="schedule.name !== IMAGE_DEFAULT ? IMAGE_PATH + schedule.name : schedule.name"
                    [style.objectFit]="schedule.name !== IMAGE_DEFAULT ? 'cover' : 'contain'"
                />
                <mat-icon *ngIf="schedule.name !== IMAGE_DEFAULT" (click)="removeImage('schedule', i)" class="image__remove">
                    {{ ICONS.CLOSE }}
                </mat-icon>
            </section>
            <label for="schedule" class="upload--upload">
                <span lang key="ACTIONS.BROWSE_FILE"></span>
            </label>
            <input multiple id="schedule" accept=".jpg,.jpeg,.png" type="file" (change)="uploadImage($event, 'schedule')" />
        </section>
    </section>
</section>
