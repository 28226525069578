import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getAcademy } from 'src/app/store/selectors/academy.selectors';
import { Academy } from 'src/app/types/academy.types';
import { COLORS } from 'src/app/types/map.types';
import { ICONS } from 'src/app/types/types';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-contact-info-modal',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoModalComponent implements OnInit {
    public ICONS = ICONS;

    public academy$: Observable<Academy>;

    public colors: Map<string, string> = new Map(COLORS);

    constructor(public dialogRef: MatDialogRef<ModalComponent>, private store: Store) {}

    public ngOnInit(): void {
        this.academy$ = this.store.select(getAcademy);
    }

    public close(status: string): void {
        this.dialogRef.close(status);
    }
}
