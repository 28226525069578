import { Action } from '@ngrx/store';
import { Filter } from 'src/app/types/academy.types';

export const FILTER_ACADEMIES = '[Filter] filter academies effect';

export class FilterAcademyEffectAction implements Action {
    readonly type = FILTER_ACADEMIES;

    constructor(public filter: Filter) {}
}
