import { Action } from '@ngrx/store';
import { ImageItem } from 'src/app/types/academy.types';

export const UPLOAD_GALLERY_IMAGE = '[AUTH] upload gallery image effect response';

export class UploadGalleryAction implements Action {
    readonly type = UPLOAD_GALLERY_IMAGE;

    constructor(public image: ImageItem) {}
}
