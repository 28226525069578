import { Document, DOCUMENT_DEFAULT } from 'src/app/types/document.types';
import { DocumentActions } from '../actions/documents.actions';
import { SET_DOCUMENT } from '../actions/documnets/set-document.action';

const initialState: Document = {
    ...DOCUMENT_DEFAULT
};

export const documentReducer = (state = initialState, action: DocumentActions) => {
    switch (action.type) {
        case SET_DOCUMENT:
            return {
                ...state,
                ...action.document
            };
        default:
            return state;
    }
};

export const getCurrentDocumentState = (state: Document) => {
    return { ...state };
};
