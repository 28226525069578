import { AuthState, SELECTORS } from 'src/app/types/state.types';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
    getAccessTokenState,
    getOwnAcademyState,
    getRoleState,
    getStepState,
    isAuthorizedState,
    getEmailState,
    getPasswordState,
    getOwnAcademyTypeState,
    getGainerState,
    getSubscriptionsState,
    getFavoritesState
} from '../reducers/auth.reducer';

export const getAuthState = createFeatureSelector<AuthState>(SELECTORS.AUTH);

export const isAuthorized = createSelector(getAuthState, isAuthorizedState);

export const getAccessToken = createSelector(getAuthState, getAccessTokenState);

export const getOwnAcademy = createSelector(getAuthState, getOwnAcademyState);

export const getOwnAcademyType = createSelector(getAuthState, getOwnAcademyTypeState);

export const getRole = createSelector(getAuthState, getRoleState);

export const getStep = createSelector(getAuthState, getStepState);

export const getPassword = createSelector(getAuthState, getPasswordState);

export const getEmail = createSelector(getAuthState, getEmailState);

export const getGainerInfo = createSelector(getAuthState, getGainerState);

export const getSubscriptions = createSelector(getAuthState, getSubscriptionsState);

export const getFavorites = createSelector(getAuthState, getFavoritesState);
