<section class="main__input">
    <form [formGroup]="searchForm" (ngSubmit)="search()">
        <div class="main__container" [style.width.%]="select ? 50 : 100">
            <div class="input__label">
                <span lang key="MAIN.FIND"></span>
            </div>
            <input
                (click)="openSearchModal()"
                (focus)="focus = true"
                formControlName="location"
                placeholder="Search by city, address, or postcode"
                type="text"
                sanitize
                (keydown.enter)="(false)"
                (input)="getLocation()"
            />
        </div>
        <i class="search__separator" *ngIf="select"></i>
        <div *ngIf="select" class="main__container--select">
            <div class="select__label" [style.height.px]="searchForm.controls.type.value == '' ? 65 : 16">
                <div>
                    <span lang key="MAIN.LOOKING_FOR"></span>
                </div>
                <div class="select__label--empty" *ngIf="searchForm.controls.type.value == ''">
                    <span lang key="MAIN.ACADEMY_TYPE"></span>
                </div>
            </div>

            <mat-select formControlName="type" panelClass="main__types">
                <mat-option
                    *ngFor="let type of types"
                    analytics
                    [type]="ANALYTIC_TYPES.ACADEMY_TYPE_SELECTED"
                    [label]="type"
                    [value]="type"
                >
                    <span class="main__types--option">{{ type }}</span>
                </mat-option>
            </mat-select>
        </div>
    </form>

    <button
        *ngIf="button"
        mat-raised-button
        analytics
        class="main__input-button"
        [type]="ANALYTIC_TYPES.SEARCH_PERFORMED"
        [label]="location.place_name"
        (click)="search()"
        [disabled]="!location || !selected"
    >
        <mat-icon class="icon__search">{{ ICONS.SEARCH }}</mat-icon>
    </button>
</section>

<section class="results" *ngIf="(menu$ | async).open === false && showResults()" click-outside (outsideClick)="focus = false">
    <div *ngFor="let location of locations">
        <span (click)="selectLocation(location); focus = false">{{ location.place_name }}</span>
    </div>
    <i class="underline" *ngIf="!!locations.length"></i>
    <div class="results__footer">
        <div *ngIf="currentLocation()" class="results__by-user" (click)="findLocationByUser(); focus = false">
            <mat-icon class="icon__search">{{ ICONS.SEARCH }}</mat-icon>
            <span lang key="HOME.SEARCH_NEAR"></span>
        </div>
        <mat-icon *ngIf="!!locations.length" (click)="hideResults()" class="menu__close">{{ ICONS.CLOSE }}</mat-icon>
    </div>
</section>
