import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticTypes, ANALYTIC_TYPES, SignUpLabels, SIGN_UP_LABELS } from 'src/app/types/google-analytics.types';
import { Dialog } from 'src/app/types/types';

@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public SIGN_UP_LABELS: SignUpLabels = { ...SIGN_UP_LABELS };

    constructor(@Inject(MAT_DIALOG_DATA) public data: Dialog, public dialogRef: MatDialogRef<ModalComponent>) {}

    public close(status: string): void {
        this.dialogRef.close(status);
    }
}
