import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api-service.service';
import { Document } from 'src/app/types/document.types';
import { GetDocumentEffectAction, GET_DOCUMENT_EFFECT } from '../actions/documnets/get-document-effect.action';
import { SetDocumentAction } from '../actions/documnets/set-document.action';

@Injectable()
export class DocumentsEffects {
    constructor(private actions$: Actions, private api: ApiService, private sanitizer: DomSanitizer) {}

    public getDocumentEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_DOCUMENT_EFFECT),
            mergeMap((action: GetDocumentEffectAction) =>
                this.api.getDocuments(action.slug).pipe(
                    map((document: Document) => {
                        const content = this.sanitizer.sanitize(1, document.content);

                        return new SetDocumentAction({
                            ...document,
                            content
                        });
                    })
                )
            )
        )
    );
}
