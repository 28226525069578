import { Action } from '@ngrx/store';
import { Coords } from 'src/app/types/locations.types';

export const GET_LOCATION_BY_COORDS = '[LOCATION] get by coords search';

export class GetLocationByCoordsEffect implements Action {
    readonly type = GET_LOCATION_BY_COORDS;

    constructor(public coords: Coords) {}
}
