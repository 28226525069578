import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getAcademy } from 'src/app/store/selectors/academy.selectors';
import { Academy } from 'src/app/types/academy.types';
import { AnalyticTypes, ANALYTIC_TYPES } from 'src/app/types/google-analytics.types';

@Component({
    selector: 'app-social-links',
    templateUrl: './social-links.component.html',
    styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnInit {
    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public academy$: Observable<Academy>;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.academy$ = this.store.select(getAcademy);
    }
}
