import { Action } from '@ngrx/store';
import { ReviewsRequest } from 'src/app/types/api.types';

export const GET_ACADEMY_REVIEWS_EFFECT = '[Reviews] get review list by academy id';

export class GetAcademyReviewsEffect implements Action {
    readonly type = GET_ACADEMY_REVIEWS_EFFECT;

    constructor(public request: ReviewsRequest) {}
}
