import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SendReportEffect } from 'src/app/store/actions/academy/send-report.action';

import { AcademyTypeList, ACADEMY_TYPES, Report, REPORT_DEFAULT, ReportDialog } from 'src/app/types/academy.types';

import { ICONS } from 'src/app/types/types';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
    public IMAGE_PATH: string = environment.images;

    public TYPES: AcademyTypeList = { ...ACADEMY_TYPES };

    public ICONS = { ...ICONS };

    public report: Report = { ...REPORT_DEFAULT };

    constructor(private store: Store, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: ReportDialog) {}

    public ngOnInit(): void {
        this.report.academy_id = this.data.academy_id;
    }

    public send(): void {
        this.store.dispatch(new SendReportEffect(this.report));
        this.report = { ...REPORT_DEFAULT };
        this.dialog.closeAll();
    }

    public close(): void {
        this.dialog
            .open(ModalComponent, {
                panelClass: 'modal__error',
                data: {
                    message: 'MENU.SURE',
                    confirm: 'YES',
                    continue: 'CANCEL'
                }
            })
            .afterClosed()
            .subscribe((status: string) => {
                if (status === 'confirm') {
                    this.dialog.closeAll();
                }
            });
    }
}
