import { Action } from '@ngrx/store';
import { Reviews } from 'src/app/types/reviews.types';

export const APPEND_ACADEMY_REVIEWS = '[REVIEWS] append reviews';

export class AppendAcademyReviews implements Action {
    readonly type = APPEND_ACADEMY_REVIEWS;

    constructor(public response: Reviews) {}
}
