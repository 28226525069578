import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';

import { RestoreComponent } from 'src/app/sidenav/restore/restore.component';
import { ForgotComponent } from 'src/app/sidenav/forgot/forgot.component';
import { LoginComponent } from 'src/app/sidenav/login/login.component';
import { SidenavComponent } from 'src/app/sidenav/sidenav.component';
import { MenuComponent } from 'src/app/sidenav/menu/menu.component';
import { MaterialModule } from '../material.module';
import { CoreModule } from '../core/core.module';

import { RegisterComponent } from './register/register.component';
import { TemplatesModule } from '../templates/templates.module';
import { PasswordComponent } from './password/password.component';

@NgModule({
    declarations: [
        RestoreComponent,
        LoginComponent,
        ForgotComponent,
        SidenavComponent,
        MenuComponent,
        RegisterComponent,
        PasswordComponent
    ],
    imports: [CommonModule, MaterialModule, TemplatesModule, CoreModule],
    exports: [RestoreComponent, LoginComponent, ForgotComponent, MenuComponent, SidenavComponent],
    providers: []
})
export class MenuModule {}
