import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Reviews } from 'src/app/types/reviews.types';
import { SELECTORS } from 'src/app/types/state.types';
import {
    getReviewListState,
    getTotalState,
    getReviewSkillsState,
    getReviewImagesState,
    getReplyImagesState,
    getReviewsPaginationState
} from '../reducers/reviews.reducer';

export const getReviewsState = createFeatureSelector<Reviews>(SELECTORS.REVIEWS);

export const getReviewsTotal = createSelector(getReviewsState, getTotalState);

export const getReviews = createSelector(getReviewsState, getReviewListState);

export const getReviewSkills = createSelector(getReviewsState, getReviewSkillsState);

export const getReviewImages = createSelector(getReviewsState, getReviewImagesState);

export const getReplyImages = createSelector(getReviewsState, getReplyImagesState);

export const getReviewsPagination = createSelector(getReviewsState, getReviewsPaginationState);
