import { Action } from '@ngrx/store';
import { Amenity } from 'src/app/types/amenity.types';

export const SET_AMENITIES = '[AMENITIES] set amenity list';

export class SetAmenitiesAction implements Action {
    readonly type = SET_AMENITIES;

    constructor(public amenities: Array<Amenity>) {}
}
