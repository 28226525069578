import { Component, OnInit } from '@angular/core';
import { Skill } from 'src/app/types/skill.types';
import { Academy, ACADEMY_DEFAULT } from 'src/app/types/academy.types';
import { Role, ROLES, Roles, ROUTES } from 'src/app/types/types';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getGainerInfo, getOwnAcademy, getRole, isAuthorized } from 'src/app/store/selectors/auth.selectors';
import { Gainer } from 'src/app/types/api.types';
import { Router } from '@angular/router';
import { GetOwnAcademyEffectAction } from 'src/app/store/actions/auth/get-own-academy-effect.action';
import { GetGainerInfoEffect } from 'src/app/store/actions/auth/get-gainer-info-effect.action';
import { UpdateAcademyEffectAction } from 'src/app/store/actions/auth/update-academy-effect.action';
import { UpdateGainerInfoEffect } from 'src/app/store/actions/auth/update-gainer-info-effect.action';
import { SetOwnAcademyAction } from 'src/app/store/actions/auth/set-own-academy.action';

@Component({
    selector: 'app-menu-settings',
    templateUrl: './profile-settings.component.html',
    styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {
    public academyProfileValid: boolean = true;

    public contactInfoValid: boolean = true;

    public gainerValid: boolean = false;

    public scheduleValid: boolean = true;

    public hasChanges: boolean = false;

    private current: string = JSON.stringify({ ...ACADEMY_DEFAULT });

    public ROLES: Roles = { ...ROLES };

    public academy: Academy;

    public gainer: Gainer;

    public role$: Observable<Role>;

    constructor(private store: Store, private router: Router) {}

    ngOnInit(): void {
        this.role$ = this.store.select(getRole);

        this.role$.subscribe((role: Role) => {
            switch (role) {
                case ROLES.ACADEMY: {
                    const auth = this.store.select(isAuthorized).subscribe((status) => {
                        if (status) {
                            this.store.select(getOwnAcademy).subscribe((academy: Academy) => {
                                if (academy.id === 0) {
                                    this.store.dispatch(new GetOwnAcademyEffectAction());
                                }
                                this.academy = { ...academy };

                                if (this.current === JSON.stringify({ ...ACADEMY_DEFAULT })) {
                                    this.current = JSON.stringify({ ...academy });
                                }

                                if (!this.hasChanges) {
                                    if (this.current !== JSON.stringify({ ...academy })) {
                                        this.hasChanges = true;
                                    }
                                }
                            });
                        } else {
                            this.router.navigate([ROUTES.GAINER_LANDING]);
                            auth.unsubscribe();
                        }
                    });

                    break;
                }
                case ROLES.GAINER: {
                    this.store.select(getGainerInfo).subscribe((gainer: Gainer) => {
                        this.gainer = { ...gainer };
                        if (!gainer.name.trim()) {
                            const auth = this.store.select(isAuthorized).subscribe((status) => {
                                if (status) {
                                    this.store.dispatch(new GetGainerInfoEffect());
                                }

                                auth.unsubscribe();
                            });
                        }
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }

    public saveAcademy(): void {
        this.store.dispatch(new UpdateAcademyEffectAction(this.academy));
        this.hasChanges = false;
    }

    public saveGainer(): void {
        this.store.dispatch(new UpdateGainerInfoEffect(this.gainer));
        this.gainerValid = false;
    }

    public selected(skills: Array<Skill>): void {
        if (JSON.stringify(skills) !== JSON.stringify(this.academy.skills)) {
            this.store.dispatch(
                new SetOwnAcademyAction({
                    ...this.academy,
                    skills: new Array(...skills)
                })
            );
        }
    }

    public setScheduleValid(status: boolean): void {
        this.scheduleValid = status;
    }
}
