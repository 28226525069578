import { Action } from '@ngrx/store';
import { Subscriptions } from 'src/app/types/state.types';

export const SET_ACADEMY_SUBSCRIPTIONS = '[ACCOUNT SETTINGS] set academy subscriptions';

export class SetAcademySubscriptions implements Action {
    readonly type = SET_ACADEMY_SUBSCRIPTIONS;

    constructor(public subscriptions: Subscriptions) {}
}
