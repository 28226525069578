import { ICONS, Page, PAGES_LIST, Role, ROLE_LIST, ROLE_ROUTE } from 'src/app/types/types';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Document, DOCUMENT_DEFAULT } from 'src/app/types/document.types';
import { getDocument } from 'src/app/store/selectors/documents.selectors';
import { Location } from '@angular/common';
import { SetDocumentAction } from 'src/app/store/actions/documnets/set-document.action';
import { GetDocumentEffectAction } from 'src/app/store/actions/documnets/get-document-effect.action';
import { SetMenuAction } from 'src/app/store/actions/menu/set-menu,action';
import { SetRoleAction } from 'src/app/store/actions/auth/set-role,action';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
    public ICONS = ICONS;

    public document$: Observable<Document>;

    public previewRoute: string;

    public page: Page = '';

    public role: Role | '' = '';

    constructor(private store: Store, private route: ActivatedRoute, private location: Location, private router: Router) {}

    public ngOnInit(): void {
        this.document$ = this.store.select(getDocument);

        this.route.queryParams.subscribe((params: Params) => {
            this.store.dispatch(new SetDocumentAction(params.slug));
            if (params.slug) {
                this.store.dispatch(new SetDocumentAction(DOCUMENT_DEFAULT));
                this.store.dispatch(new GetDocumentEffectAction(params.slug));
            }

            if (params.page && PAGES_LIST.includes(params.page)) {
                this.page = params.page;
            }

            if (params.role && ROLE_LIST.includes(params.role)) {
                this.role = params.role;
            }
        });
    }

    public goBack(): void {
        if (this.page && this.role && ROLE_ROUTE.has(this.role)) {
            this.router.navigate([ROLE_ROUTE.get(this.role)]);
            this.store.dispatch(
                new SetMenuAction({
                    page: this.page,
                    open: true
                })
            );
            return;
        }

        if (this.role) {
            this.store.dispatch(new SetRoleAction(this.role));
        }
        this.location.back();
    }
}
