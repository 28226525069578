import { ProfileSettingsComponent } from 'src/app/pages/profile-settings/profile-settings.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { effects } from 'src/app/store/app.effects';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { reducers, metaReducers } from './store/app.reducers';

import { HomeComponent } from './pages/home/home.component';
import { SearchComponent } from './pages/search/search.component';
import { AcademyComponent } from './pages/academy/academy.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { FavoritesComponent } from './pages/favorites/favorites.component';

/* another modules */
import { MaterialModule } from './material.module';
import { CoreModule } from './core/core.module';
import { MenuModule } from './sidenav/sidenav.module';
import { TemplatesModule } from './templates/templates.module';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        SearchComponent,
        AcademyComponent,
        DocumentsComponent,
        ProfileSettingsComponent,
        AccountSettingsComponent,
        FavoritesComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        TemplatesModule,
        CoreModule,
        MenuModule,
        StoreModule.forRoot(reducers, {
            metaReducers
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        EffectsModule.forRoot(effects),
        StoreRouterConnectingModule.forRoot(),
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics.trackingID)
        // NgxGoogleAnalyticsRouterModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
