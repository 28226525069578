/* eslint-disable no-restricted-syntax */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClearReviewImages } from 'src/app/store/actions/reviews/clear-review-images.action';
import { RemoveReviewImage } from 'src/app/store/actions/reviews/remove-review-image.action';
import { SendReviewEffect } from 'src/app/store/actions/reviews/send-review-effect.action';
import { UploadReviewImageEffect } from 'src/app/store/actions/reviews/upload-review-image-effect.action';
import { getAcademy } from 'src/app/store/selectors/academy.selectors';
import { getReviewImages } from 'src/app/store/selectors/reviews.selectors';
import { Academy, AcademyTypeList, ACADEMY_TYPES, ImageItem } from 'src/app/types/academy.types';
import { Review, REVIEW_DEFAULT } from 'src/app/types/reviews.types';
import { Skill } from 'src/app/types/skill.types';
import { ICONS, IMAGES } from 'src/app/types/types';
import { environment } from 'src/environments/environment';
import { GalleryComponent } from '../gallery/gallery.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-leave-review',
    templateUrl: './leave-review.component.html',
    styleUrls: ['./leave-review.component.scss']
})
export class LeaveReviewComponent implements OnInit {
    public IMAGE_PATH: string = environment.images;

    public TYPES: AcademyTypeList = { ...ACADEMY_TYPES };

    public ICONS = { ...ICONS };

    public review: Review = { ...REVIEW_DEFAULT };

    public skills: Array<Skill> = [];

    public academy$: Observable<Academy>;

    constructor(private store: Store, private dialog: MatDialog) {
        this.review = { ...REVIEW_DEFAULT };
    }

    public ngOnInit(): void {
        this.academy$ = this.store.select(getAcademy);
        this.store.select(getReviewImages).subscribe((images: ImageItem[]) => {
            this.review.images = images;
        });
        this.academy$.subscribe((academy: Academy) => {
            this.review.academy_id = academy.id;
            this.review.academyType = academy.type;
            this.skills = new Array(
                ...academy.skills.map((skill: Skill, index: number) => {
                    return {
                        ...skill,
                        model: index === 0
                    };
                })
            );
            if (this.skills.length) {
                this.review.skills.push(this.skills[0]);
            }
        });
    }

    public send(): void {
        this.store.dispatch(new SendReviewEffect(this.review));
        this.store.dispatch(new ClearReviewImages());
        this.review = { ...REVIEW_DEFAULT };
        this.dialog.closeAll();
    }

    public selectSkills(skill: Skill): void {
        const index = this.skills.findIndex((s: Skill) => skill.id === s.id);
        if (index !== -1) {
            this.skills[index].model = !this.skills[index].model;
            if (this.skills[index].model) {
                this.review.skills.push(this.skills[index]);
            } else {
                const i = this.review.skills.findIndex((s) => s.id === this.skills[index].id);
                if (i !== -1) {
                    this.review.skills.splice(i, 1);
                }
            }
        }
    }

    public removeImage(index: number): void {
        this.store.dispatch(new RemoveReviewImage(index));
    }

    public uploadImage(event): void {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files.length + this.review.images.length <= 3) {
                for (const i of event.target.files) {
                    if (i.size < 2000000) {
                        const imagesReader = new FileReader();
                        imagesReader.onload = (e: any) => {
                            this.dataUrlToFile(e.target.result, i.name).then((res) => {
                                this.store.dispatch(new UploadReviewImageEffect(res));
                            });
                        };
                        imagesReader.readAsDataURL(i);
                    } else {
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__error',
                            data: {
                                message: 'ERRORS.IMAGE_SIZE',
                                confirm: 'OK',
                                image: IMAGES.ERROR
                            }
                        });
                    }
                }
            } else {
                this.dialog.open(ModalComponent, {
                    panelClass: 'modal__warning',
                    data: {
                        message: 'WARNING.REVIEW_IMAGE_LIMIT',
                        confirm: 'TRY_AGAIN',
                        image: IMAGES.ERROR
                    }
                });
            }
        }
    }

    private async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }

    public show(current: string): void {
        this.dialog.open(GalleryComponent, {
            panelClass: 'galley-modal',
            data: {
                current,
                images: this.review.images
            }
        });
    }

    public close(): void {
        this.dialog
            .open(ModalComponent, {
                panelClass: 'modal__error',
                data: {
                    message: 'MENU.SURE',
                    confirm: 'YES',
                    continue: 'CANCEL'
                }
            })
            .afterClosed()
            .subscribe((status: string) => {
                if (status === 'confirm') {
                    this.store.dispatch(new ClearReviewImages());
                    this.dialog.closeAll();
                }
            });
    }
}
