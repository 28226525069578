import { Location } from 'src/app/types/locations.types';
import { LocationState, LOCATION_STATE_DEFAULT } from 'src/app/types/state.types';
import { COOKIES } from 'src/app/types/types';
import { LOCATION_DEFAULT } from '../../types/locations.types';
import { LocationActions } from '../actions/location.actions';
import { SET_LOCATION } from '../actions/location/set-location.action';
import { SET_LOCATIONS } from '../actions/location/set-locations.action';

export function getCookieLocation(): Location {
    const matches = document.cookie.match(
        new RegExp('(?:^|; )'.concat(COOKIES.LOCATION.replace(/([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, '\\$1'), '=([^;]*)'))
    );
    if (matches) {
        try {
            return JSON.parse(decodeURIComponent(matches[1]));
        } catch (error) {
            return LOCATION_DEFAULT;
        }
    }
    return LOCATION_DEFAULT;
}

const initialState: LocationState = {
    ...LOCATION_STATE_DEFAULT,
    location: getCookieLocation()
};

export const locationReducer = (state = initialState, action: LocationActions) => {
    switch (action.type) {
        case SET_LOCATION:
            return {
                ...state,
                location: {
                    ...action.location
                }
            };
        case SET_LOCATIONS:
            return {
                ...state,
                locations: action.locations
            };
        default:
            return state;
    }
};

export const getLocation = (state: LocationState) => {
    return {
        ...state.location
    };
};

export const getLocationsState = (state: LocationState) => {
    return new Array(...state.locations);
};
