import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SetMenuAction } from 'src/app/store/actions/menu/set-menu,action';
import { isAuthorized } from 'src/app/store/selectors/auth.selectors';
import { PAGES, ROUTES } from 'src/app/types/types';
import { FeedbackComponent } from '../modals/feedback/feedback.component';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    public isHome: boolean = false;

    public authorized: boolean = false;

    public ROUTES: Record<string, string> = { ...ROUTES };

    constructor(private router: Router, private dialog: MatDialog, private store: Store) {
        this.store.select(isAuthorized).subscribe((status: boolean) => {
            this.authorized = status;
        });
    }

    public goToDocuments(slug: string): void {
        this.router.navigate([ROUTES.DOCUMENTS], {
            queryParams: {
                slug
            }
        });
    }

    public goTo(route: string): void {
        this.router.navigate([route]);
    }

    public feedback(): void {
        if (this.authorized) {
            this.dialog.open(FeedbackComponent, {
                panelClass: 'feedback-modal',
                disableClose: true
            });
        } else {
            this.dialog
                .open(ModalComponent, {
                    panelClass: 'modal__warning',
                    data: {
                        message: 'WARNING.FEEDBACK_AUTH',
                        confirm: 'LOGIN',
                        continue: 'SIGN_IN'
                    }
                })
                .afterClosed()
                .subscribe((status: string) => {
                    switch (status) {
                        case 'confirm':
                            this.store.dispatch(
                                new SetMenuAction({
                                    open: true,
                                    page: PAGES.LOGIN
                                })
                            );
                            break;

                        case 'continue':
                            this.store.dispatch(
                                new SetMenuAction({
                                    open: true,
                                    page: PAGES.REGISTER
                                })
                            );
                            break;
                        default: {
                            break;
                        }
                    }
                });
        }
    }
}
