import { Academy, ACADEMY_DEFAULT, IMAGE_ITEM_DEFAULT } from 'src/app/types/academy.types';
import { Gainer, GAINER_DEFAULT } from 'src/app/types/api.types';
import { AuthState, AUTH_STATE_DEFAULT } from 'src/app/types/state.types';
import { COOKIES, IMAGES, ROLES } from 'src/app/types/types';
import { AuthActions } from '../actions/auth.actions';
import { CLEAR_AUTH_STATE_ACTION } from '../actions/auth/clear-auth.action';
import { REMOVE_GALLERY_IMAGE } from '../actions/auth/remove-gallery-item.action';
import { REMOVE_SCHEDULE_IMAGE } from '../actions/auth/remove-schedule-item.action';
import { SET_ACADEMY_SUBSCRIPTIONS } from '../actions/auth/set-academy-subscriptions.action';
import { SET_ACCESS_TOKEN } from '../actions/auth/set-access-token.action';
import { SET_EMAIL } from '../actions/auth/set-email.action';
import { SET_GAINER_INFO } from '../actions/auth/set-gainer-info.action';
import { SET_IS_APPROVED } from '../actions/auth/set-is-approved.action';
import { SET_OWN_ACADEMY } from '../actions/auth/set-own-academy.action';
import { SET_OWN_AMENITIES } from '../actions/auth/set-own-amenities.action';
import { PASSWORD } from '../actions/auth/set-password.actiion';
import { SET_ROLE } from '../actions/auth/set-role,action';
import { SET_SCHEDULE } from '../actions/auth/set-schedule.action';
import { SET_REGISTER_STEP } from '../actions/auth/set-step.action';
import { SET_THUMBNAIL_IMAGE } from '../actions/auth/set-trumbnail.action';
import { SET_FAVORITES } from '../actions/auth/set-vaforite-academies.action';
import { UPLOAD_GALLERY_IMAGE } from '../actions/auth/upload-gallery.action';
import { UPLOAD_SCHEDULE_IMAGE } from '../actions/auth/upload-schedule.action';

function getCookie(key: string): string {
    const matches = document.cookie.match(
        new RegExp('(?:^|; )'.concat(key.replace(/([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, '\\$1'), '=([^;]*)'))
    );
    return matches ? decodeURIComponent(matches[1]) : '';
}

function getCookieAcademy(): Academy {
    const matches = document.cookie.match(
        new RegExp('(?:^|; )'.concat(COOKIES.ACADEMY.replace(/([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, '\\$1'), '=([^;]*)'))
    );
    try {
        const academy = JSON.parse(decodeURIComponent(matches[1]));
        return academy;
    } catch (error) {
        return ACADEMY_DEFAULT;
    }
}

function getCookieGainer(): Gainer {
    const matches = document.cookie.match(
        new RegExp('(?:^|; )'.concat(COOKIES.GAINER.replace(/([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, '\\$1'), '=([^;]*)'))
    );

    try {
        const gainer = JSON.parse(decodeURIComponent(matches[1]));
        return gainer;
    } catch (error) {
        return GAINER_DEFAULT;
    }
}

const initialState: AuthState = {
    ...AUTH_STATE_DEFAULT,
    accessToken: getCookie('token'),
    approved: !!getCookie('token'),
    email: getCookie('email'),
    gainer: getCookieGainer(),
    academy: getCookieAcademy(),
    role: getCookieAcademy() !== ACADEMY_DEFAULT ? ROLES.ACADEMY : ROLES.GAINER
};

export const authReducer = (state = initialState, action: AuthActions) => {
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.token
            };
        case SET_EMAIL:
            return {
                ...state,
                email: action.email
            };
        case SET_ROLE:
            if (state.approved && !!state.accessToken) {
                return state;
            }
            return {
                ...state,
                role: action.role
            };
        case PASSWORD:
            return {
                ...state,
                password: action.password
            };
        case SET_OWN_ACADEMY:
            return {
                ...state,
                role: ROLES.ACADEMY,
                academy: {
                    ...state.academy,
                    ...action.academy
                }
            };
        case SET_THUMBNAIL_IMAGE:
            return {
                ...state,
                academy: {
                    ...state.academy,
                    thumbnail: {
                        ...action.image
                    }
                }
            };
        case UPLOAD_SCHEDULE_IMAGE: {
            const schedule = new Array(...state.academy.schedule);
            if (schedule.length === 1 && schedule[0].name === IMAGES.DEFAULT) {
                schedule.pop();
            }

            schedule.push({
                name: action.image.name,
                id: action.image.id
            });
            return {
                ...state,
                academy: {
                    ...state.academy,
                    schedule
                }
            };
        }
        case UPLOAD_GALLERY_IMAGE: {
            const gallery = new Array(...state.academy.gallery);
            if (gallery.length === 1 && gallery[0].name === IMAGES.DEFAULT) {
                gallery.pop();
            }

            gallery.push({
                name: action.image.name,
                id: action.image.id
            });
            return {
                ...state,
                academy: {
                    ...state.academy,
                    gallery
                }
            };
        }
        case REMOVE_SCHEDULE_IMAGE: {
            let schedule = new Array(...state.academy.schedule);

            if (schedule.length === 1) {
                schedule = new Array({
                    ...IMAGE_ITEM_DEFAULT
                });
            } else {
                schedule.splice(action.index, 1);
            }

            return {
                ...state,
                academy: {
                    ...state.academy,
                    schedule
                }
            };
        }
        case REMOVE_GALLERY_IMAGE: {
            let gallery = new Array(...state.academy.gallery);

            if (gallery.length === 1) {
                gallery = new Array({
                    ...IMAGE_ITEM_DEFAULT
                });
            } else {
                gallery.splice(action.index, 1);
            }

            return {
                ...state,
                academy: {
                    ...state.academy,
                    gallery
                }
            };
        }
        case SET_REGISTER_STEP: {
            return {
                ...state,
                step: action.step
            };
        }
        case SET_SCHEDULE: {
            return {
                ...state,
                academy: {
                    ...state.academy,
                    week_schedule: action.schedule
                }
            };
        }
        case SET_OWN_AMENITIES: {
            return {
                ...state,
                academy: {
                    ...state.academy,
                    amenities: new Array(...action.amenities)
                }
            };
        }
        case SET_GAINER_INFO: {
            return {
                ...state,
                role: ROLES.GAINER,
                gainer: action.gainer
            };
        }
        case SET_IS_APPROVED: {
            return {
                ...state,
                approved: action.status
            };
        }
        case CLEAR_AUTH_STATE_ACTION: {
            return {
                ...AUTH_STATE_DEFAULT,
                role: state.role,
                landing: state.landing
            };
        }
        case SET_ACADEMY_SUBSCRIPTIONS: {
            return {
                ...state,
                subscriptions: {
                    is_profile_update_subscribe: !!action.subscriptions.is_profile_update_subscribe,
                    is_new_review_added_subscribe: !!action.subscriptions.is_new_review_added_subscribe,
                    is_get_digest_subscribe: !!action.subscriptions.is_get_digest_subscribe
                }
            };
        }
        case SET_FAVORITES: {
            return {
                ...state,
                favorites: action.favorites
            };
        }
        default:
            return state;
    }
};

export const getAccessTokenState = (state: AuthState) => {
    return state.accessToken;
};

export const isAuthorizedState = (state: AuthState) => {
    return state.role === ROLES.ACADEMY ? !!state.accessToken && state.approved : !!state.accessToken;
};

export const getRoleState = (state: AuthState) => {
    return state.role;
};

export const getEmailState = (state: AuthState) => {
    return state.email;
};

export const getPasswordState = (state: AuthState) => {
    return state.password;
};

export const getOwnAcademyState = (state: AuthState) => {
    return state.academy;
};

export const getOwnAcademyTypeState = (state: AuthState) => {
    return state.academy.type;
};

export const getStepState = (state: AuthState) => {
    return state.step;
};

export const getGainerState = (state: AuthState) => {
    return state.gainer;
};

export const getSubscriptionsState = (state: AuthState) => {
    return state.subscriptions;
};

export const getFavoritesState = (state: AuthState) => {
    return state.favorites;
};
