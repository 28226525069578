<section class="register--container">
    <div class="register">
        <h1 *ngIf="register">
            <span lang key="AUTH.GAINER_TITLE"></span>
        </h1>
        <form class="register__form" [formGroup]="registerForm">
            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="AUTH.NAME"></span>
                    <i *ngIf="!registerForm.controls.name.invalid" class="icon__success"></i>
                </mat-label>
                <input
                    [class.error]="registerForm.controls.name.invalid && registerForm.controls.name.touched"
                    type="text"
                    sanitize
                    matInput
                    formControlName="name"
                />
                <mat-error *ngIf="registerForm.controls.name.invalid">
                    <span lang key="ERRORS.NAME"></span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="AUTH.LOCATION"></span>
                    <i *ngIf="!registerForm.controls.location.invalid" class="icon__success"></i>
                </mat-label>
                <input
                    autocomplete="off"
                    (input)="searchLocations($event)"
                    [class.error]="registerForm.controls.location.invalid && registerForm.controls.location.touched"
                    type="text"
                    sanitize
                    matInput
                    formControlName="location"
                />
                <section class="register__form--location-results" *ngIf="(locations$ | async).length">
                    <section>
                        <div (click)="setLocation(location)" *ngFor="let location of locations$ | async">
                            <span>{{ location.place_name }}</span>
                        </div>
                    </section>
                </section>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="AUTH.BIRTHDAY_YEAR"></span>
                    <i *ngIf="!registerForm.controls.birth.invalid" class="icon__success"></i>
                </mat-label>
                <input
                    [class.error]="registerForm.controls.birth.invalid && registerForm.controls.birth.touched"
                    matInput
                    formControlName="birth"
                    [disabled]="true"
                    [max]="max"
                    [min]="min"
                    [matDatepicker]="picker"
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker
                    #picker
                    startView="multi-year"
                    disabled="false"
                    (yearSelected)="selectBirthdayYear($event, picker)"
                ></mat-datepicker>
            </mat-form-field>

            <button
                *ngIf="register"
                [disabled]="registerForm.invalid"
                analytics
                [type]="ANALYTIC_TYPES.SAVE_PROFILE_CLICKED"
                [label]="SAVE_PROFILE_CLICKED.label(ROLES.GAINER, gainer.email)"
                mat-raised-button
                class="register__button"
                (click)="submit()"
            >
                <span lang key="ACTIONS.SAVE_PROFILE"></span>
            </button>
        </form>
    </div>
</section>
