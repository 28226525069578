import { Action } from '@ngrx/store';
import { Gainer } from 'src/app/types/api.types';

export const UPDATE_GAINER_INFO_EFFECT = '[AUTH] update gainer info';

export class UpdateGainerInfoEffect implements Action {
    readonly type = UPDATE_GAINER_INFO_EFFECT;

    constructor(public gainer: Gainer) {}
}
