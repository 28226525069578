import { Action } from '@ngrx/store';
import { Reviews } from 'src/app/types/reviews.types';

export const SET_ACADEMY_REVIEWS = '[Reviews] set review list';

export class SetAcademyReviewsAction implements Action {
    readonly type = SET_ACADEMY_REVIEWS;

    constructor(public response: Reviews) {}
}
