import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAmenities } from 'src/app/store/selectors/amenity.selectors';
import { getOwnAcademy } from 'src/app/store/selectors/auth.selectors';
import { Academy, AcademyType, ACADEMY_TYPES, AcademyTypeList } from 'src/app/types/academy.types';
import { Amenity } from 'src/app/types/amenity.types';
import { ICONS } from 'src/app/types/types';

@Component({
    selector: 'app-amenities',
    templateUrl: './amenities.component.html',
    styleUrls: ['./amenities.component.scss']
})
export class AmenitiesComponent implements OnInit, OnChanges {
    @Input() expanded: boolean;

    @Input() type: AcademyType;

    @Input() clean: boolean;

    @Output() selected: EventEmitter<Array<Amenity>> = new EventEmitter();

    public TYPES: AcademyTypeList = ACADEMY_TYPES;

    public ICONS = ICONS;

    public amenities: Array<Amenity>;

    public selectedAmenities: Array<Amenity> = [];

    constructor(private store: Store) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.clean && changes.clean.currentValue && changes.clean.currentValue !== changes.clean.previousValue) {
            if (this.amenities.length) {
                this.amenities = new Array(
                    ...this.amenities.map((amenity: Amenity) => ({
                        ...amenity,
                        model: false
                    }))
                );
            }
        }
    }

    public ngOnInit(): void {
        this.store.select(getOwnAcademy).subscribe((academy: Academy) => {
            academy.amenities.forEach((a) => this.selectedAmenities.push(a));
            this.store.select(getAmenities).subscribe((amenities: Array<Amenity>) => {
                this.amenities = new Array(
                    ...amenities.map((amenity: Amenity) => ({
                        ...amenity,
                        model: academy.amenities.findIndex((s) => s.id === amenity.id) !== -1
                    }))
                );
            });
        });
    }

    public switchAmenity(amenity: Amenity): void {
        const i = this.amenities.findIndex((a) => a.id === amenity.id);
        if (i !== -1) {
            this.amenities[i] = {
                ...this.amenities[i],
                model: !this.amenities[i].model
            };
        }
        if (!amenity.model) {
            this.selectedAmenities = [...this.selectedAmenities, amenity];
        } else {
            this.selectedAmenities = new Array(...this.selectedAmenities.filter((a) => a.id !== amenity.id));
        }

        this.selected.emit(this.selectedAmenities);
    }
}
