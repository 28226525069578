import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DeleteFromFavoriteAcademies } from 'src/app/store/actions/auth/delete-from-favorites-academies-effect.action';
import { GetFavoriteAcademies } from 'src/app/store/actions/auth/get-favorite-academies-effect-action';
import { getFavorites } from 'src/app/store/selectors/auth.selectors';
import { Favorite } from 'src/app/types/state.types';
import { ICONS } from 'src/app/types/types';

@Component({
    selector: 'app-favorites',
    templateUrl: './favorites.component.html',
    styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
    public ICONS = ICONS;

    public favorites$: Observable<Array<Favorite>>;

    constructor(private location: Location, private store: Store) {}

    public ngOnInit(): void {
        this.favorites$ = this.store.select(getFavorites);
        this.store.dispatch(new GetFavoriteAcademies());
    }

    public goBack(): void {
        this.location.back();
    }

    public remove(id: number): void {
        this.store.dispatch(new DeleteFromFavoriteAcademies(id));
    }
}
