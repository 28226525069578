import { Action } from '@ngrx/store';
import { Skills } from 'src/app/types/skill.types';

export const SET_SKILLS = '[Skills] set specialization list';

export class SetSkillsAction implements Action {
    readonly type = SET_SKILLS;

    constructor(public skills: Skills) {}
}
