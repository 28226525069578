import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ChangePasswordEffectAction } from 'src/app/store/actions/auth/change-password-effect.action';
import { ModalComponent } from 'src/app/templates/modals/modal/modal.component';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
    public hideOldPassword: boolean = true;

    public showStrength: boolean = false;

    public hideConfirmPassword: boolean = true;

    public changePasswordForm: FormGroup = this.fb.group({});

    constructor(private fb: FormBuilder, private store: Store, private dialog: MatDialog) {}

    public ngOnInit(): void {
        this.changePasswordForm = this.fb.group({
            oldPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
            password: new FormControl('', [Validators.required, Validators.minLength(8), this.matchValidator()]),
            repeatPassword: new FormControl('', [Validators.required, this.mismatchValidator()])
        });
    }

    private mismatchValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const mismatch = this.changePasswordForm.value.password !== control.value;
            return mismatch ? { mismatch: { value: control.value } } : null;
        };
    }

    private matchValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const mismatch = this.changePasswordForm.value.oldPassword === control.value;
            return mismatch ? { mismatch: { value: control.value } } : null;
        };
    }

    public changePassword(): void {
        this.dialog
            .open(ModalComponent, {
                panelClass: 'modal__confirm',
                data: {
                    message: 'AUTH.CHANGE_PASSWORD_CONFIRM',
                    confirm: 'CHANGE_PASSWORD',
                    continue: 'CANCEL'
                }
            })
            .afterClosed()
            .subscribe((status: string) => {
                switch (status) {
                    case 'confirm': {
                        this.store.dispatch(
                            new ChangePasswordEffectAction({
                                current_password: this.changePasswordForm.value.oldPassword,
                                password: this.changePasswordForm.value.password,
                                confirm_password: this.changePasswordForm.value.repeatPassword
                            })
                        );
                        break;
                    }
                    default:
                        break;
                }
            });
    }
}
