export const environment = {
    production: false,
    apiUrl: 'https://api.gain.staging.dev.magora.uk/',
    images: 'https://api.gain.staging.dev.magora.uk/images/',
    domain: 'https://gain.staging.dev.magora.uk',
    assets: {
        images: 'assets/images/',
        icons: 'assets/icons/'
    },
    mapBox: {
        token: '143d484129bfd384d1370fdb7d89b8011ba01086068890d1a1127d7ab998c37c',
        accessToken: 'pk.eyJ1IjoiY2h1Z2hlczU4MCIsImEiOiJja25yYmN3YnAwbjNzMm9wZmdsMWk2dXUxIn0.-3qeX1i01ZFJDisfgop1cg'
    },
    googleAnalytics: {
        trackingID: 'UA-180993219-1'
    }
};
