<section class="modal">
    <div mat-dialog-title class="modal__title">
        <span lang key="ACADEMY.CONTACT_INFO"></span>
        <mat-icon (click)="close('cancel')" class="menu__close">{{ ICONS.CLOSE }}</mat-icon>
    </div>

    <div mat-dialog-title class="modal__content">
        <div>
            <span>{{ (academy$ | async).phone }}</span>
            <span>{{ (academy$ | async).email }}</span>
            <span>{{ (academy$ | async).location }}</span>
            <app-social-links class="modal__social"></app-social-links>
        </div>
        <app-map
            class="modal__map"
            [lat]="(academy$ | async).lat"
            [lng]="(academy$ | async).lng"
            [marker]="colors.get((academy$ | async).type)"
            [static]="true"
        >
        </app-map>
    </div>
</section>
