<section *ngIf="type === TYPES.GYM || type === TYPES.SPORTS_CLUB">
    <mat-accordion class="filter__amenities">
        <mat-expansion-panel [expanded]="expanded">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4><span lang key="ACADEMY.AMENITIES"></span></h4>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="filter__amenities--dropdown" *ngFor="let amenity of amenities">
                <div [ngSwitch]="amenity.type">
                    <div *ngSwitchCase="TYPES.SPORTS_CLUB">
                        <mat-checkbox
                            *ngIf="type === TYPES.SPORTS_CLUB"
                            [(ngModel)]="amenity.model"
                            (click)="switchAmenity(amenity)"
                            class="filter__checkbox"
                        >
                            <div class="amenity">
                                <i class=" academy__amenity--icon icon__amenity--{{ amenity.id }}"></i>
                                <span>{{ amenity.name }}</span>
                            </div>
                        </mat-checkbox>
                    </div>

                    <div *ngSwitchCase="TYPES.GYM">
                        <mat-checkbox
                            *ngIf="type === TYPES.GYM"
                            [(ngModel)]="amenity.model"
                            (click)="switchAmenity(amenity)"
                            class="filter__checkbox"
                        >
                            <div class="amenity">
                                <i class=" academy__amenity--icon icon__amenity--{{ amenity.id }}"></i>
                                <span>{{ amenity.name }}</span>
                            </div>
                        </mat-checkbox>
                    </div>
                    <div *ngSwitchDefault></div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
