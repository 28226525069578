import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api-service.service';
import { ModalComponent } from 'src/app/templates/modals/modal/modal.component';
import { ImageItem, IMAGE_TYPES } from 'src/app/types/academy.types';
import { Skill } from 'src/app/types/skill.types';
import { IMAGES } from 'src/app/types/types';
import { AppendAcademyReviews } from '../actions/reviews/append-academy-reviews.action';
import { GetAcademyReviewsEffect, GET_ACADEMY_REVIEWS_EFFECT } from '../actions/reviews/get-academy-reviews-effect.action';
import { LoadMoreAcademyReviewsEffect, LOAD_MORE_ACADEMY_REVIEWS_EFFECT } from '../actions/reviews/load-more-academy-reviews-effect.action';
import { SendReplyEffect, SEND_REPLY_EFFECT } from '../actions/reviews/send-reply-effect.action';
import { SendReviewEffect, SEND_REVIEW_EFFECT } from '../actions/reviews/send-review-effect.action';
import { SendReviewResponse } from '../actions/reviews/send-review-response.action';
import { SetAcademyReviewsAction } from '../actions/reviews/set-academy-reviews.action';
import { UploadReplyImageEffect, UPLOAD_REPLY_IMAGE_EFFECT } from '../actions/reviews/upload-reply-image-effect.action';
import { UploadReplyImage } from '../actions/reviews/upload-reply-image.action';
import { UploadReviewImageEffect, UPLOAD_REVIEW_IMAGE_EFFECT } from '../actions/reviews/upload-review-image-effect.action';
import { UploadReviewImage } from '../actions/reviews/upload-review-image.action';

@Injectable()
export class ReviewsEffects {
    constructor(private actions$: Actions, private api: ApiService, private dialog: MatDialog) {}

    public getAcademyReviewsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_ACADEMY_REVIEWS_EFFECT),
            debounceTime(500),
            mergeMap((action: GetAcademyReviewsEffect) =>
                this.api.getReviews(action.request).pipe(
                    map((response: any) => {
                        return new SetAcademyReviewsAction({
                            pagination: {
                                current_page: response.reviews.current_page,
                                last_page: response.reviews.last_page,
                                per_page: response.reviews.per_page,
                                from: response.reviews.from,
                                to: response.reviews.to,
                                total: response.reviews.total
                            },
                            reviews: response.reviews.data,
                            skills: response.skills.map((skill: Skill) => {
                                return {
                                    ...skill,
                                    model: action.request.skills.findIndex((id) => id === skill.id) !== -1
                                };
                            })
                        });
                    })
                )
            )
        )
    );

    public loadMoreAcademyReviewsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOAD_MORE_ACADEMY_REVIEWS_EFFECT),
            debounceTime(500),
            mergeMap((action: LoadMoreAcademyReviewsEffect) =>
                this.api.getReviews(action.request).pipe(
                    map((response: any) => {
                        return new AppendAcademyReviews({
                            pagination: {
                                current_page: response.reviews.current_page,
                                last_page: response.reviews.last_page,
                                per_page: response.reviews.per_page,
                                from: response.reviews.from,
                                to: response.reviews.to,
                                total: response.reviews.total
                            },
                            reviews: response.reviews.data,
                            skills: response.skills.map((skill: Skill) => {
                                return {
                                    ...skill,
                                    model: action.request.skills.findIndex((id) => id === skill.id) !== -1
                                };
                            })
                        });
                    })
                )
            )
        )
    );

    public createReview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SEND_REVIEW_EFFECT),
            debounceTime(500),
            mergeMap((action: SendReviewEffect) =>
                this.api.sendReview(action.review).pipe(
                    map(() => {
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__success',
                            data: {
                                message: 'SUCCESS.REVIEW_SEND',
                                confirm: 'OK',
                                image: IMAGES.SUCCESS
                            }
                        });
                        return new SendReviewResponse();
                    })
                )
            )
        )
    );

    public createReply$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SEND_REPLY_EFFECT),
            debounceTime(500),
            mergeMap((action: SendReplyEffect) =>
                this.api.sendReply(action.reply).pipe(
                    map(
                        () =>
                            new GetAcademyReviewsEffect({
                                page: 1,
                                skills: [],
                                id: action.academy_id
                            })
                    )
                )
            )
        )
    );

    public uploadReviewImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPLOAD_REVIEW_IMAGE_EFFECT),
            mergeMap((action: UploadReviewImageEffect) =>
                this.api.uploadImage(action.image, IMAGE_TYPES.REVIEW).pipe(map((res: ImageItem) => new UploadReviewImage(res)))
            )
        )
    );

    public uploadReplyImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPLOAD_REPLY_IMAGE_EFFECT),
            mergeMap((action: UploadReplyImageEffect) =>
                this.api.uploadImage(action.image, IMAGE_TYPES.REPLY).pipe(map((res: ImageItem) => new UploadReplyImage(res)))
            )
        )
    );
}
