<section class="login--container">
    <div class="login">
        <h1>
            <div [ngSwitch]="role">
                <div *ngSwitchCase="ROLES.GAINER"><span lang key="AUTH.LOGIN_GAINER_TITLE"></span></div>
                <div *ngSwitchCase="ROLES.ACADEMY"><span lang key="AUTH.LOGIN_ACADEMY_TITLE"></span></div>
            </div>
        </h1>
        <form class="login__form" [formGroup]="loginForm">
            <mat-form-field appearance="fill">
                <mat-label><span lang key="AUTH.EMAIL"></span></mat-label>
                <input
                    type="email"
                    sanitize
                    matInput
                    formControlName="email"
                    [class.error]="loginForm.controls.email.invalid && loginForm.touched"
                />
                <mat-error *ngIf="loginForm.controls.email.invalid">
                    <span lang key="ERRORS.EMAIL"></span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label><span lang key="AUTH.PASSWORD"></span></mat-label>
                <input
                    sanitize
                    [class.error]="loginForm.controls.password.invalid && loginForm.touched"
                    matInput
                    [type]="hidePassword ? 'password' : 'text'"
                    formControlName="password"
                />
                <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword">
                    <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="loginForm.controls.password.invalid">
                    <span lang key="ERRORS.PASSWORD"></span>
                </mat-error>
            </mat-form-field>
            <button class="login__form--forgot" (click)="setMenuConfig('FORGOT', true)">
                <span lang key="AUTH.FORGOT"></span>
            </button>
            <button
                (click)="login()"
                [disabled]="loginForm.controls.email.invalid || loginForm.controls.password.invalid"
                mat-raised-button
                class="login__button"
            >
                <span lang key="ACTIONS.LOGIN"></span>
            </button>
            <section class="login__form--register">
                <span lang key="AUTH.NO_ACCOUNT"></span>
                <button (click)="setMenuConfig('REGISTER', true)">
                    <span lang key="AUTH.REGISTER_HERE"></span>
                </button>
            </section>

            <div [ngSwitch]="role">
                <div *ngSwitchCase="ROLES.GAINER">
                    <section class="login__form--change-role">
                        <span lang key="AUTH.JOIN_ACADEMY"></span>
                        <button (click)="changeRole(ROLES.ACADEMY); setMenuConfig('REGISTER', true)">
                            <span lang key="ACTIONS.REGISTER_HERE"></span>
                        </button>
                    </section>
                </div>
                <div *ngSwitchCase="ROLES.ACADEMY">
                    <section class="login__form--change-role">
                        <span lang key="AUTH.JOIN_GAINER"></span>
                        <button (click)="changeRole(ROLES.GAINER); setMenuConfig('REGISTER', true)">
                            <span lang key="ACTIONS.REGISTER_HERE"></span>
                        </button>
                    </section>
                </div>
            </div>
        </form>
    </div>
</section>
