<section class="review__container">
    <section class="review__title">
        <div>
            <span class="review__date">{{ date }}</span>
            <span class="review__user">{{ review.user.name }}</span>
        </div>
        <section class="review__skills" *ngIf="review.skills.length !== 0">
            <mat-chip-list>
                <mat-chip class="skill__ship" *ngFor="let skill of review.skills" [color]="'#F0F3F5'">
                    <span class="skill__name">{{ skill.name }}</span>
                </mat-chip>
            </mat-chip-list>
        </section>
    </section>

    <section class="review__marks">
        <div class="review__equipment" *ngIf="(academy$ | async).type === TYPES.GYM">
            <span lang key="REVIEW.EQUIPMENT"></span>
            <i class="icon__star"></i>
            <span class="mark"> {{ review.equipment_rating.toFixed(1) }}</span>
        </div>
        <div class="review__money" *ngIf="(academy$ | async).type === TYPES.COACH">
            <span lang key="REVIEW.MONEY"></span>
            <i class="icon__star"></i>
            <span class="mark"> {{ review.value_for_money_rating.toFixed(1) }}</span>
        </div>
        <div class="review__willingness" *ngIf="(academy$ | async).type !== TYPES.SPORTS_CLUB">
            <span lang key="REVIEW.WILLINGNESS"></span>
            <i class="icon__star"></i>
            <span class="mark"> {{ review.willingness_to_refer_rating.toFixed(1) }}</span>
        </div>
        <div class="review__overall">
            <span lang key="REVIEW.OVERALL"></span>
            <i class="icon__star"></i>
            <span class="mark"> {{ review.overall_impression_rating.toFixed(1) }}</span>
        </div>
    </section>

    <section class="review__text">
        <div>
            {{ review.text }}
        </div>
    </section>

    <section class="review__images" *ngIf="review.images.length !== 0">
        <section class="review__image" *ngFor="let image of review.images; let i = index">
            <img (click)="show(IMAGE_PATH + image.name)" [src]="IMAGE_PATH + image.name" />
        </section>
    </section>

    <section class="review__actions">
        <button mat-button *ngIf="reply && !review.reply" (click)="replyOpen()">
            <span lang key="ACTIONS.REPLY"></span>
        </button>
    </section>

    <section *ngIf="review.reply" class="review__reply">
        <span>{{ (academy$ | async).name }}</span>
        <span class="reply__text">{{ review.reply.text }}</span>
        <section class="reply__images" *ngIf="review.images.length !== 0">
            <section class="reply__image" *ngFor="let image of review.reply.images; let i = index">
                <img (click)="showReplyImages(IMAGE_PATH + image.name)" [src]="IMAGE_PATH + image.name" />
            </section>
        </section>
    </section>
</section>
