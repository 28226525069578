<mat-dialog-content>
    <image-cropper
        [imageChangedEvent]="data.src"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        format="jpeg"
        (imageCropped)="imageCropped($event)"
        [cropperMinWidth]="400"
        [cropperMinHeight]="400"
    ></image-cropper>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="close()" mat-dialog-close class="cancel__button">
        <span lang key="ACTIONS.CANCEL"></span>
    </button>
    <button (click)="cropImage()" mat-button mat-dialog-close class="crop__button">
        <span lang key="ACTIONS.CROP"></span>
    </button>
</mat-dialog-actions>
