import { Directive, ElementRef, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
    selector: '[sanitize]'
})
export class SanitizeDirective {
    @HostListener('change', ['$event.target.value'])
    onChange(value) {
        this.elementRef.nativeElement.value = this.sanitizer.sanitize(1, value);
    }

    constructor(private elementRef: ElementRef, private sanitizer: DomSanitizer) {}
}
