import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { getEmail, getOwnAcademy, getOwnAcademyType, getPassword } from 'src/app/store/selectors/auth.selectors';
import { AcademyTypeList, Academy, ACADEMY_TYPES, AcademyType } from 'src/app/types/academy.types';
import { ICONS, REGEXP, ROLES } from 'src/app/types/types';
import { Location } from 'src/app/types/locations.types';
import { Amenity } from 'src/app/types/amenity.types';
import { DomSanitizer } from '@angular/platform-browser';
import { SetOwnAcademyAction } from 'src/app/store/actions/auth/set-own-academy.action';
import { ApproveAcademyEffectAction } from 'src/app/store/actions/auth/approve-academy-effect.action';
import { RegisterEffectAction } from 'src/app/store/actions/auth/register-effect-action';
import { SetStepAction } from 'src/app/store/actions/auth/set-step.action';
import { SetOwnAmenitiesAction } from 'src/app/store/actions/auth/set-own-amenities.action';
import { AnalyticConfig, AnalyticTypes, ANALYTIC_TYPES, SAVE_PROFILE_CLICKED } from 'src/app/types/google-analytics.types';

@Component({
    selector: 'app-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
    @Input() register: boolean;

    @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public SAVE_PROFILE_CLICKED: AnalyticConfig = { ...SAVE_PROFILE_CLICKED };

    public TYPES: AcademyTypeList = ACADEMY_TYPES;

    public ICONS = ICONS;

    public registerForm: FormGroup = this.fb.group({});

    public locations$: Observable<Array<Location>>;

    public academy$: Observable<Academy>;

    public academy: Academy;

    public type$: Observable<AcademyType>;

    constructor(private fb: FormBuilder, private store: Store, private sanitizer: DomSanitizer) {}

    public ngOnInit(): void {
        this.academy$ = this.store.select(getOwnAcademy);
        this.type$ = this.store.select(getOwnAcademyType);

        this.academy$.subscribe((academy: Academy) => {
            this.academy = academy;

            this.registerForm = this.fb.group({
                contactEmail: [
                    this.sanitizer.sanitize(1, academy.email),
                    [Validators.required, Validators.pattern(REGEXP.EMAIL), Validators.pattern(REGEXP.LATIN)]
                ],
                phone: [this.sanitizer.sanitize(1, academy.phone), [Validators.required, Validators.pattern(REGEXP.PHONE)]],
                youtube: [this.sanitizer.sanitize(1, academy.social_links.youtube), [Validators.pattern(REGEXP.YOUTUBE)]],
                facebook: [this.sanitizer.sanitize(1, academy.social_links.facebook), [Validators.pattern(REGEXP.FACEBOOK)]],
                tiktok: [this.sanitizer.sanitize(1, academy.social_links.tiktok), [Validators.pattern(REGEXP.TIKTOK)]],
                web: [this.sanitizer.sanitize(1, academy.social_links.personal_website), [Validators.pattern(REGEXP.WEB)]],
                linkedIn: [this.sanitizer.sanitize(1, academy.social_links.linkedin), [Validators.pattern(REGEXP.LINKEDIN)]],
                twitter: [this.sanitizer.sanitize(1, academy.social_links.twitter), [Validators.pattern(REGEXP.TWITTER)]],
                instagram: [this.sanitizer.sanitize(1, academy.social_links.instagram), [Validators.pattern(REGEXP.INSTAGRAM)]]
            });

            this.registerForm.valueChanges.subscribe(() => {
                this.store.dispatch(
                    new SetOwnAcademyAction({
                        ...this.academy,
                        email: this.registerForm.controls.contactEmail.value,
                        phone: this.registerForm.controls.phone.value,
                        social_links: {
                            ...this.academy.social_links,
                            facebook: this.registerForm.controls.facebook.value,
                            instagram: this.registerForm.controls.instagram.value,
                            linkedin: this.registerForm.controls.linkedIn.value,
                            tiktok: this.registerForm.controls.tiktok.value,
                            twitter: this.registerForm.controls.twitter.value,
                            personal_website: this.registerForm.controls.web.value,
                            youtube: this.registerForm.controls.youtube.value
                        }
                    })
                );
                this.valid.emit(this.registerForm.valid);
            });
        });
    }

    public registerSubmit(): void {
        if (this.academy.id !== 0 && this.register) {
            this.store.dispatch(new ApproveAcademyEffectAction(this.academy));
        } else if (this.academy.id === 0 && this.register) {
            const passwordSelector = this.store.select(getPassword).subscribe((password: string) => {
                if (password.trim()) {
                    const emailSelector = this.store.select(getEmail).subscribe((email: string) => {
                        if (email) {
                            this.store.dispatch(
                                new RegisterEffectAction({
                                    academy: {
                                        ...this.academy
                                    },
                                    user: {
                                        email,
                                        password,
                                        role: ROLES.ACADEMY,
                                        accessToken: '',
                                        approved: false
                                    }
                                })
                            );
                            emailSelector.unsubscribe();
                            passwordSelector.unsubscribe();
                        } else {
                            emailSelector.unsubscribe();
                            passwordSelector.unsubscribe();
                        }
                    });
                } else {
                    passwordSelector.unsubscribe();
                }
            });
        }
    }

    public goBack(): void {
        this.store.dispatch(new SetStepAction(2));
    }

    public selectedAmenities(amenities: Array<Amenity>): void {
        this.store.dispatch(new SetOwnAmenitiesAction(amenities));
    }
}
