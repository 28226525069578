/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api-service.service';
import { ACADEMY_TYPES } from 'src/app/types/academy.types';
import { Specialization, Skills, SKILLS_DEFAULT } from 'src/app/types/skill.types';
import { GET_SKILLS_EFFECT } from '../actions/skills/get-skills-effect.action';
import { SetSkillsAction } from '../actions/skills/set-skills.action';

@Injectable()
export class SkillsEffects {
    constructor(private actions$: Actions, private api: ApiService) {}

    public getSkillsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_SKILLS_EFFECT),
            mergeMap(() =>
                this.api.getSpecializations().pipe(
                    map((specializations: Array<Specialization>) => {
                        const skills: Skills = { ...SKILLS_DEFAULT };
                        try {
                            specializations.forEach((specialization: Specialization) => {
                                skills[specialization.type] = [...skills[specialization.type], specialization];
                            });
                            skills[ACADEMY_TYPES.SOMETHING_NEW] = new Array(...specializations);
                            skills[ACADEMY_TYPES.COACH] = new Array(...specializations);
                        } catch (error) {
                            console.error('[parse skills] error: ', error);
                        }

                        return new SetSkillsAction(skills);
                    })
                )
            )
        )
    );
}
