import { Action } from '@ngrx/store';
import { ImageItem } from 'src/app/types/academy.types';

export const UPLOAD_REVIEW_IMAGE = '[REVIEW] add review image';

export class UploadReviewImage implements Action {
    readonly type = UPLOAD_REVIEW_IMAGE;

    constructor(public image: ImageItem) {}
}
