<div class="search__scroll">
    <div class="search__container">
        <section [class.background]="filterOpen$ | async" class="filter__background">
            <button mat-button (click)="toggleFilter()" class="filter__background--close">
                <mat-icon class="filter__background--close-icon">{{ ICONS.CLOSE }}</mat-icon>
            </button>
        </section>
        <section [class.background-mobile]="filterOpen$ | async" class="filter__background" (click)="toggleFilter()"></section>
        <section [class.open]="filterOpen$ | async" [style.zIndex]="setIndex()" class="filter">
            <section class="filter__logo">
                <span lang key="MAIN.GAIN"></span>
            </section>
            <app-filter></app-filter>
        </section>

        <section class="search">
            <div [style.display]="'block'">
                <div class="filter__location">
                    <h4>{{ (location$ | async).text }}</h4>
                    <button class="filter__toggle" (click)="toggleFilter()">
                        <i class="icon__filter"></i>
                        <mat-hint class="filter__count"
                            ><span>{{ filterCount$ | async }}</span></mat-hint
                        >
                    </button>
                </div>
                <section class="academy__type">
                    <span
                        [class.active]="type === (type$ | async)"
                        analytics
                        [type]="ANALYTIC_TYPES.ACADEMY_TYPE_SELECTED"
                        [label]="type"
                        (click)="switchType(type)"
                        *ngFor="let type of TYPES"
                        >{{ type }}</span
                    >
                </section>
            </div>

            <section class="search__results">
                <section class="search__results--academies scroll">
                    <div *ngIf="(academies$ | async).length !== 0">
                        <app-card
                            *ngFor="let academy of academies$ | async"
                            (mouseover)="selectAcademyMap(academy.id)"
                            (mouseout)="selectAcademyMap(0)"
                            [academy]="academy"
                        ></app-card>
                        <button
                            *ngIf="pagination.current_page < pagination.last_page"
                            class="search__results--show-more"
                            (click)="loadMore(pagination.current_page + 1)"
                        >
                            <span lang key="ACTIONS.SHOW_MORE"></span>
                        </button>
                    </div>

                    <section class="search__empty" *ngIf="!(academies$ | async).length">
                        <span lang key="SEARCH.NOT_FOUND_ACADEMY"></span>
                        <span class="search__empty--help" lang key="SEARCH.NOT_FOUND_ACADEMY_HELP"></span>
                    </section>
                    <app-footer class="mobile"></app-footer>
                </section>
                <section class="search__results--map" *ngIf="!device.isMobile()">
                    <div class="search__map">
                        <app-map
                            [lat]="(location$ | async)?.center[1]"
                            [selected]="selected"
                            [lng]="(location$ | async)?.center[0]"
                            [controls]="true"
                        ></app-map>
                    </div>
                </section>
            </section>
        </section>
    </div>
</div>
<app-footer></app-footer>
