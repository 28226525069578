export interface Document {
    id: number;
    title: string;
    slug: string;
    content: string;
}

export const DOCUMENT_DEFAULT: Document = {
    id: 0,
    title: '',
    slug: '',
    content: ''
};

export const DOCUMENTS = {
    TERMS_OF_USE: 'terms-of-use',
    PRIVACY_AND_COOKIES_STATEMENT: 'Privacy and Cookies Statement'
};
