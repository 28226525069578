<section class="register--container">
    <div class="register">
        <h1 *ngIf="register">
            <mat-icon *ngIf="register" class="register--back" (click)="goBack()">{{ ICONS.BACK }}</mat-icon>

            <div [ngSwitch]="(academy$ | async).type">
                <div *ngSwitchCase="TYPES.COACH">
                    <span lang key="AUTH.COACH_TITLE"></span>
                </div>
                <div *ngSwitchCase="TYPES.GYM">
                    <span lang key="AUTH.GYM_TITLE"></span>
                </div>
                <div *ngSwitchCase="TYPES.SPORTS_CLUB">
                    <span lang key="AUTH.SPORTS_CLUB_TITLE"></span>
                </div>
            </div>
        </h1>
        <h2><span lang key="ACADEMY.CONTACT_INFO"></span></h2>

        <form class="register__form" [formGroup]="registerForm">
            <app-upload-image *ngIf="register" [schedule]="true"></app-upload-image>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="AUTH.CONTACT_EMAIL"></span>
                </mat-label>
                <input
                    sanitize
                    required
                    [class.error]="registerForm.controls.contactEmail.invalid && registerForm.controls.contactEmail.touched"
                    type="email"
                    matInput
                    formControlName="contactEmail"
                />
                <mat-error *ngIf="registerForm.controls.contactEmail.invalid">
                    <span lang key="ERRORS.EMAIL"></span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="AUTH.PHONE"></span>
                </mat-label>
                <input
                    sanitize
                    required
                    [class.error]="registerForm.controls.phone.invalid && registerForm.controls.phone.touched"
                    type="tel"
                    matInput
                    formControlName="phone"
                />
                <mat-error *ngIf="registerForm.controls.phone.invalid">
                    <span lang key="ERRORS.PHONE"></span>
                </mat-error>
            </mat-form-field>

            <!-- social -->

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="SOCIAL.YOUTUBE"></span>
                </mat-label>
                <input
                    sanitize
                    [class.error]="registerForm.controls.youtube.invalid && registerForm.controls.youtube.touched"
                    type="url"
                    matInput
                    formControlName="youtube"
                />
                <mat-error *ngIf="registerForm.controls.youtube.invalid">
                    <span lang key="ERRORS.SOCIAL_PREFIX"></span>
                    <span lang key="SOCIAL.YOUTUBE"></span>
                    <span lang key="ERRORS.SOCIAL_SUFFIX"></span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="SOCIAL.FACEBOOK"></span>
                </mat-label>
                <input
                    [class.error]="registerForm.controls.facebook.invalid && registerForm.controls.facebook.touched"
                    type="url"
                    sanitize
                    matInput
                    formControlName="facebook"
                />
                <mat-error *ngIf="registerForm.controls.facebook.invalid">
                    <span lang key="ERRORS.SOCIAL_PREFIX"></span>
                    <span lang key="SOCIAL.FACEBOOK"></span>
                    <span lang key="ERRORS.SOCIAL_SUFFIX"></span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="SOCIAL.TIKTOK"></span>
                </mat-label>
                <input
                    [class.error]="registerForm.controls.tiktok.invalid && registerForm.controls.tiktok.touched"
                    type="url"
                    sanitize
                    matInput
                    formControlName="tiktok"
                />
                <mat-error *ngIf="registerForm.controls.tiktok.invalid">
                    <span lang key="ERRORS.SOCIAL_PREFIX"></span>
                    <span lang key="SOCIAL.TIKTOK"></span>
                    <span lang key="ERRORS.SOCIAL_SUFFIX"></span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="SOCIAL.WEB"></span>
                </mat-label>
                <input
                    sanitize
                    [class.error]="registerForm.controls.web.invalid && registerForm.controls.web.touched"
                    type="url"
                    matInput
                    formControlName="web"
                />
                <mat-error *ngIf="registerForm.controls.tiktok.invalid">
                    <span lang key="ERRORS.SOCIAL_PREFIX"></span>
                    <span lang key="ERRORS.SOCIAL_SUFFIX"></span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="SOCIAL.LINKEDIN"></span>
                </mat-label>
                <input
                    sanitize
                    [class.error]="registerForm.controls.linkedIn.invalid && registerForm.controls.linkedIn.touched"
                    type="url"
                    matInput
                    formControlName="linkedIn"
                />
                <mat-error *ngIf="registerForm.controls.linkedIn.invalid">
                    <span lang key="ERRORS.SOCIAL_PREFIX"></span>
                    <span lang key="SOCIAL.LINKEDIN"></span>
                    <span lang key="ERRORS.SOCIAL_SUFFIX"></span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="SOCIAL.TWITTER"></span>
                </mat-label>
                <input
                    sanitize
                    [class.error]="registerForm.controls.twitter.invalid && registerForm.controls.twitter.touched"
                    type="url"
                    matInput
                    formControlName="twitter"
                />
                <mat-error *ngIf="registerForm.controls.twitter.invalid">
                    <span lang key="ERRORS.SOCIAL_PREFIX"></span>
                    <span lang key="SOCIAL.TWITTER"></span>
                    <span lang key="ERRORS.SOCIAL_SUFFIX"></span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="SOCIAL.INSTAGRAM"></span>
                </mat-label>
                <input
                    sanitize
                    [class.error]="registerForm.controls.instagram.invalid && registerForm.controls.instagram.touched"
                    type="url"
                    matInput
                    formControlName="instagram"
                />
                <mat-error *ngIf="registerForm.controls.instagram.invalid">
                    <span lang key="ERRORS.SOCIAL_PREFIX"></span>
                    <span lang key="SOCIAL.INSTAGRAM"></span>
                    <span lang key="ERRORS.SOCIAL_SUFFIX"></span>
                </mat-error>
            </mat-form-field>

            <app-amenities [type]="type$ | async" [expanded]="true" (selected)="selectedAmenities($event)"></app-amenities>

            <button
                *ngIf="register"
                analytics
                [type]="ANALYTIC_TYPES.SAVE_PROFILE_CLICKED"
                [label]="SAVE_PROFILE_CLICKED.label(academy.type, academy.email)"
                mat-raised-button
                class="register__button"
                [disabled]="registerForm.invalid"
                (click)="registerSubmit()"
            >
                <span lang key="ACTIONS.REGISTER"></span>
            </button>
        </form>
    </div>
</section>
