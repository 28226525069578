import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AnalyticConfig, AnalyticType, ANALYTIC_CONFIG_DEFAULT, ANALYTICS } from 'src/app/types/google-analytics.types';

@Directive({
    selector: '[analytics]'
})
export class AnalyticsDirective implements OnInit {
    @Input() type: AnalyticType;

    @Input() label: string;

    private config: AnalyticConfig = { ...ANALYTIC_CONFIG_DEFAULT };

    private disable: boolean = true;

    constructor(private google: GoogleAnalyticsService) {}

    public ngOnInit(): void {
        if (ANALYTICS.has(this.type)) {
            this.config = ANALYTICS.get(this.type);
            this.disable = false;
        }
    }

    @HostListener('click', ['$event']) onClick() {
        if (!this.disable) {
            this.google.event(this.config.action, this.config.category, this.label, 1, true);
        }
    }
}
