import { environment } from 'src/environments/environment';

export type Page = 'LOGIN' | 'REGISTER' | 'FORGOT' | 'RESTORE' | 'PROFILE' | 'PASSWORD' | '';

export type Role = 'gainer' | 'academy';

export type Cookie = 'token' | 'location' | 'email' | 'academy' | 'gainer';

export type Route = '/academy-landing' | '/gainer-landing' | '/favorites' | '/documents' | '/academy' | '/search';

export interface Pages {
    [key: string]: Page;
}
export interface User {
    email: string;
    password: string;
    role: Role;
    accessToken: string;
    approved: boolean;
}
export interface Roles {
    [key: string]: Role;
}

export interface MenuConfig {
    page: Page;
    open: boolean;
}
export interface Dialog {
    message: string;
    confirm: string;
    image?: string;
    continue?: string;
}

export interface Feedback {
    text: string;
}

export const MENU_CONFIG_DEFAULT: MenuConfig = {
    page: '',
    open: false
};

export const PAGES: Pages = {
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    FORGOT: 'FORGOT',
    RESTORE: 'RESTORE',
    PROFILE: 'PROFILE',
    PASSWORD: 'PASSWORD'
};

export const PAGES_LIST: Page[] = [PAGES.LOGIN, PAGES.REGISTER, PAGES.FORGOT, PAGES.RESTORE, PAGES.PROFILE, PAGES.PASSWORD];

export const ROLES: Roles = {
    GAINER: 'gainer',
    ACADEMY: 'academy'
};

export const ROLE_LIST: Role[] = [ROLES.GAINER, ROLES.ACADEMY];

export const ROUTES: Record<string, Route> = {
    ACADEMY_LANDING: '/academy-landing',
    GAINER_LANDING: '/gainer-landing',
    FAVORITES: '/favorites',
    DOCUMENTS: '/documents',
    ACADEMY: '/academy',
    SEARCH: '/search'
};

export const ROLE_ROUTE: Map<Role, Route> = new Map([
    [ROLES.GAINER, ROUTES.GAINER_LANDING],
    [ROLES.ACADEMY, ROUTES.ACADEMY_LANDING]
]);

export const IMAGES: Record<string, string> = {
    DEFAULT: `${environment.assets.icons}placeholder.svg`,
    DEFAULT_IMAGE: `${environment.assets.icons}img.svg`,
    HOME_BACKGROUND: `url('${environment.assets.images}home_bg.jpg')`,
    DEFAULT_BACKGROUND: `url('${environment.assets.icons}placeholder.svg')`,
    SUCCESS: `${environment.assets.icons}success.svg`,
    ERROR: `${environment.assets.icons}error.svg`
};

export const ICONS: Record<string, string> = {
    MENU: 'menu',
    CLOSE: 'close',
    Filter: 'settings_input_composite',
    SEARCH: 'search',
    PLACE: 'place',
    BACK: 'arrow_back',
    FAVORITE_BORDER: 'favorite_border',
    FAVORITE: 'favorite',
    ZOOM_OUT: 'zoom_out',
    ZOOM_IN: 'zoom_in',
    KEYBOARD_DOWN: 'keyboard_arrow_down'
};

export const REGEXP: Record<string, RegExp> = {
    EMAIL: /^(([^<>()\\[\]\\.,;:\s@\\"%]+(\.[^<>()\\[\]\\.,;:\s@\\"%]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"%]+\.)+[^<>()[\]\\.,;:\s@\\"%]{2,})$/,
    PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/,
    YOUTUBE: /^(http|https):\/\/[\s\S]{0,}youtube.[\s\S]{0,}/,
    TIKTOK: /^(http|https):\/\/[\s\S]{0,}tiktok.[\s\S]{0,}/,
    WEB: /^(http|https):\/\/[\s\S]{0,}/,
    INSTAGRAM: /^(http|https):\/\/[\s\S]{0,}instagram.[\s\S]{0,}/,
    FACEBOOK: /^(http|https):\/\/[\s\S]{0,}facebook.[\s\S]{0,}/,
    TWITTER: /^(http|https):\/\/[\s\S]{0,}twitter.[\s\S]{0,}/,
    LINKEDIN: /^(http|https):\/\/[\s\S]{0,}linkedin.[\s\S]{0,}/,
    LATIN: /^[^А-я\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]+$/,
    ACADEMY_PROFILE_ROUTE: /\/academy\?id=([\d]{1,})$/,
    RESTORE_ROUTE: /^\/auth\/restore\/confirm/,
    SEARCH_ROUTE: /^\/search/,
    HOME_ROUTE: /^\/gainer-landing|academy-landing$/,
    GAINER_LANDING_ROUTE: /^\/gainer-landing$/,
    ACADEMY_LANDING_ROUTE: /^\/academy-landing$/,
    PRICE: /^[0-9]{1,2}(,|.)([0-9]{1,2})$/,
    PHONE: /^[+]{0,1}[0-9]{3,11}$/,
    SCHEDULE_TIME: /^(\d{2}):(\d{2})$/
};

export const COOKIES: Record<string, Cookie> = {
    TOKEN: 'token',
    LOCATION: 'location',
    EMAIL: 'email',
    ACADEMY: 'academy',
    GAINER: 'gainer'
};

export const FEEDBACK_DEFAULT: Feedback = {
    text: ''
};

export const FILE_SIZE_MAX: number = 2 * 1024 * 1024;
