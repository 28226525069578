<section class="schedule">
    <h4 class="schedule__title"><span lang key="ACADEMY.WeekSchedule"></span></h4>
    <div *ngFor="let day of weekList">
        <h5>{{ day.day }}</h5>

        <div class="schedule__day">
            <input
                [ngxTimepicker]="startTime"
                [format]="24"
                (input)="onStartTimeChange($event, day.day)"
                [(ngModel)]="day.start_time"
                [class.error]="!day.start_time_valid"
            />
            <ngx-material-timepicker #startTime (timeSet)="setStartTime($event, day.day)"></ngx-material-timepicker>

            <input
                [ngxTimepicker]="endTime"
                [format]="24"
                [(ngModel)]="day.end_time"
                (input)="onEndTimeChange($event, day.day)"
                [class.error]="!day.end_time_valid"
            />
            <ngx-material-timepicker #endTime (timeSet)="setEndTime($event, day.day)"></ngx-material-timepicker>
        </div>
    </div>
</section>
