import { Action } from '@ngrx/store';
import { WeekSchedule } from 'src/app/types/academy.types';

export const SET_SCHEDULE = '[AUTH] set week schedule';

export class SetScheduleAction implements Action {
    readonly type = SET_SCHEDULE;

    constructor(public schedule: WeekSchedule) {}
}
