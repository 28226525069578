import { AcademyType, ACADEMY_TYPES, ImageItem, Pagination, PAGINATION_DEFAULT } from './academy.types';
import { Skill } from './skill.types';

export interface Review {
    id: number;
    academy_id: number;
    overall_impression_rating: number;
    willingness_to_refer_rating: number;
    value_for_money_rating: number;
    equipment_rating: number;
    total_rating: number;
    user_id: number;
    text: string;
    academyType: AcademyType;
    created_at: Date;
    reply: Reply;
    user?: ReviewUser;
    skills: Array<Skill>;
    images: Array<ImageItem>;
}

export interface ReviewUser {
    name: string;
}

export interface Reviews {
    pagination: Pagination;
    reviews: Array<Review>;
    skills: Array<Skill>;
    review_images?: ImageItem[];
    reply_images?: ImageItem[];
}

export const REVIEWS_DEFAULT: Reviews = {
    pagination: PAGINATION_DEFAULT,
    reviews: [],
    skills: [],
    review_images: [],
    reply_images: []
};

export interface ReplyDialog {
    review: Review;
}

export interface Reply {
    review_id: number;
    id: number;
    text: string;
    images: ImageItem[];
}

export const REPLY_DEFAULT: Reply = {
    review_id: 0,
    id: 0,
    text: '',
    images: []
};

export const REVIEW_DEFAULT: Review = {
    academy_id: 0,
    id: 0,
    user_id: 0,
    overall_impression_rating: 1,
    willingness_to_refer_rating: 1,
    value_for_money_rating: 1,
    equipment_rating: 1,
    total_rating: 1,
    academyType: ACADEMY_TYPES.COACH,
    text: '',
    created_at: new Date(),
    reply: REPLY_DEFAULT,
    skills: [],
    images: []
};
