import { Action } from '@ngrx/store';
import { Gainer } from 'src/app/types/api.types';

export const SET_GAINER_INFO = '[AUTH] set gainer info';

export class SetGainerInfo implements Action {
    readonly type = SET_GAINER_INFO;

    constructor(public gainer: Gainer) {}
}
