import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api-service.service';
import { Amenity } from 'src/app/types/amenity.types';
import { GET_AMENITIES_EFFECT } from '../actions/amenity/get-amenities-effect.action';
import { SetAmenitiesAction } from '../actions/amenity/set-amenities.action';

@Injectable()
export class AmenityEffects {
    constructor(private actions$: Actions, private api: ApiService) {}

    public getAmenitiesEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_AMENITIES_EFFECT),
            mergeMap(() =>
                this.api.getAmenities().pipe(
                    map((amenities: Array<Amenity>) => {
                        return new SetAmenitiesAction(amenities);
                    })
                )
            )
        )
    );
}
