import { MenuConfig } from 'src/app/types/types';
import { MenuState, MENU_STATE_DEFAULT } from 'src/app/types/state.types';
import { MenuActions } from 'src/app/store/actions/menu.actions';
import { SET_RIGHT_BAR } from '../actions/menu/set-menu,action';

const initialState: MenuState = {
    ...MENU_STATE_DEFAULT
};

export const MenuReducer = (state = initialState, action: MenuActions) => {
    switch (action.type) {
        case SET_RIGHT_BAR:
            return {
                ...state,
                menu: {
                    ...action.bar
                }
            };
        default:
            return state;
    }
};

export const getMenuConfig = (state: MenuState): MenuConfig => {
    return {
        ...state.menu
    };
};
