import { Action } from '@ngrx/store';
import { ImageItem } from 'src/app/types/academy.types';

export const SET_THUMBNAIL_IMAGE = '[AUTH] upload thumbnail effect response';

export class SetThumbnailAction implements Action {
    readonly type = SET_THUMBNAIL_IMAGE;

    constructor(public image: ImageItem) {}
}
