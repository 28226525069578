import { Reviews, REVIEWS_DEFAULT } from 'src/app/types/reviews.types';
import { Skill } from 'src/app/types/skill.types';
import { ReviewsActions } from '../actions/reviews.actions';
import { APPEND_ACADEMY_REVIEWS } from '../actions/reviews/append-academy-reviews.action';
import { CLEAR_REPLY_IMAGES } from '../actions/reviews/clear-reply-images.action';
import { CLEAR_REVIEW_IMAGES } from '../actions/reviews/clear-review-images.action';
import { REMOVE_REPLY_IMAGE } from '../actions/reviews/remove-reply-image.action';
import { REMOVE_REVIEW_IMAGE } from '../actions/reviews/remove-review-image.action';
import { SET_ACADEMY_REVIEWS } from '../actions/reviews/set-academy-reviews.action';
import { TOGGLE_REVIEW_SKILL } from '../actions/reviews/toggle-skill.action';
import { UPLOAD_REPLY_IMAGE } from '../actions/reviews/upload-reply-image.action';
import { UPLOAD_REVIEW_IMAGE } from '../actions/reviews/upload-review-image.action';

const initialState: Reviews = {
    ...REVIEWS_DEFAULT
};

export const reviewsReducer = (state = initialState, action: ReviewsActions) => {
    switch (action.type) {
        case SET_ACADEMY_REVIEWS: {
            return {
                ...state,
                ...action.response,
                review_images: state.review_images,
                reply_images: state.reply_images
            };
        }
        case APPEND_ACADEMY_REVIEWS: {
            return {
                ...state,
                ...action.response,
                reviews: [...state.reviews, ...action.response.reviews],
                review_images: state.review_images,
                reply_images: state.reply_images
            };
        }
        case TOGGLE_REVIEW_SKILL: {
            const skills = new Array(...state.skills);
            try {
                const index = skills.findIndex((skill: Skill) => skill.id === action.id);
                if (index !== -1) {
                    skills[index] = {
                        ...skills[index],
                        model: !skills[index].model
                    };
                }
            } catch (error) {
                /* eslint-disable no-console */
                console.error('[toggle review skill] error: ', error);
            }

            return {
                ...state,
                skills: new Array(...skills)
            };
        }
        case UPLOAD_REVIEW_IMAGE: {
            const images = new Array(...state.review_images);

            images.push({
                name: action.image.name,
                id: action.image.id
            });
            return {
                ...state,
                review_images: images
            };
        }
        case REMOVE_REVIEW_IMAGE: {
            const images = new Array(...state.review_images);

            images.splice(action.index, 1);

            return {
                ...state,
                review_images: images
            };
        }
        case UPLOAD_REPLY_IMAGE: {
            const images = new Array(...state.reply_images);

            images.push({
                name: action.image.name,
                id: action.image.id
            });
            return {
                ...state,
                reply_images: images
            };
        }
        case REMOVE_REPLY_IMAGE: {
            const images = new Array(...state.reply_images);

            images.splice(action.index, 1);

            return {
                ...state,
                reply_images: images
            };
        }
        case CLEAR_REVIEW_IMAGES: {
            return {
                ...state,
                review_images: []
            };
        }
        case CLEAR_REPLY_IMAGES: {
            return {
                ...state,
                reply_images: []
            };
        }
        default:
            return state;
    }
};

export const getTotalState = (state: Reviews) => {
    return state.pagination.total;
};

export const getReviewListState = (state: Reviews) => {
    return state.reviews;
};

export const getReviewSkillsState = (state: Reviews) => {
    return state.skills;
};

export const getReviewImagesState = (state: Reviews) => {
    return state.review_images;
};

export const getReplyImagesState = (state: Reviews) => {
    return state.reply_images;
};

export const getReviewsPaginationState = (state: Reviews) => {
    return state.pagination;
};
