import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LangDirective } from 'src/app/core/directives/lang.directive';
import { OutsideClickDirective } from 'src/app/core/directives/outside-click.directive';

import { HeaderInterceptor } from 'src/app/core/injectors/header.interceptor';
import { ResponseErrorHandler } from 'src/app/core/injectors/error.interceptor';
import { MaterialModule } from '../material.module';
import { SanitizeDirective } from './directives/sanitize.directive';
import { AnalyticsDirective } from './directives/analytics.directive';

@NgModule({
    declarations: [LangDirective, OutsideClickDirective, SanitizeDirective, AnalyticsDirective],
    imports: [CommonModule, MaterialModule],
    exports: [LangDirective, OutsideClickDirective, SanitizeDirective, AnalyticsDirective],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useExisting: ResponseErrorHandler
        }
    ]
})
export class CoreModule {}
