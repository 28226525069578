import { AcademyState, ACADEMY_STATE_DEFAULT } from 'src/app/types/state.types';
import { AcademiesActions } from '../actions/academy.actions';
import { APPEND_ACADEMIES } from '../actions/academy/append-academies.action';
import { SEARCH_ACADEMIES } from '../actions/academy/search.action';
import { SET_ACADEMIES_PAGINATION } from '../actions/academy/set-academies-pagination.action';
import { SET_ACADEMIES } from '../actions/academy/set-academies.action';
import { SET_ACADEMY } from '../actions/academy/set-academy.action';
import { SET_FILTER_COUNT } from '../actions/academy/set-filter-count.action';
import { SET_ACADEMIES_FILTER } from '../actions/academy/set-filter.action';
import { SET_TYPE } from '../actions/academy/set-type.action';
import { TOGGLE_FILTER } from '../actions/academy/toggle-filter.action';

const initialState: AcademyState = {
    ...ACADEMY_STATE_DEFAULT
};

export const academyReducer = (state = initialState, action: AcademiesActions) => {
    switch (action.type) {
        case SET_TYPE:
            return {
                ...state,
                type: action.academyType
            };
        case SET_ACADEMIES:
            return {
                ...state,
                academies: action.academies
            };
        case APPEND_ACADEMIES: {
            return {
                ...state,
                academies: [...state.academies, ...action.academies]
            };
        }
        case SET_ACADEMIES_PAGINATION: {
            return {
                ...state,
                pagination: action.pagination
            };
        }
        case SET_ACADEMY:
            return {
                ...state,
                academy: action.academy
            };
        case SET_FILTER_COUNT:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    count: action.count
                }
            };
        case SET_ACADEMIES_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    lat: action.filter.lat,
                    lng: action.filter.lng,
                    type: action.filter.type,
                    skills: action.filter.skills,
                    amenities: action.filter.amenities,
                    price: action.filter.price
                }
            };
        case SEARCH_ACADEMIES:
            return {
                ...state,
                search: action.status
            };
        case TOGGLE_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    open: !state.filter.open
                }
            };
        default:
            return state;
    }
};

export const getTypeState = (state: AcademyState) => {
    return state.type;
};

export const filterState = (state: AcademyState) => {
    return {
        ...state.filter
    };
};

export const searchState = (state: AcademyState) => {
    return state.search;
};

export const getAcademiesState = (state: AcademyState) => {
    return state.academies.length ? new Array(...state.academies) : [];
};

export const getSelectedAcademyState = (state: AcademyState) => {
    return state.academy;
};

export const getFilterState = (state: AcademyState) => {
    return state.filter;
};

export const getFilterCountState = (state: AcademyState) => {
    return state.filter.count;
};

export const getFilterOpenState = (state: AcademyState) => {
    return state.filter.open;
};

export const getAcademyAmenitiesState = (state: AcademyState) => {
    return new Array(...state.academy.amenities);
};

export const getAcademiesPaginationState = (state: AcademyState) => {
    return state.pagination;
};

export const hasSocialLinksState = (state: AcademyState) => {
    return Boolean(Object.values(state.academy.social_links).filter(Boolean).length);
};
