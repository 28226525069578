import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Page, REGEXP, Role, ROLES, ROUTES } from 'src/app/types/types';
import { getRole } from 'src/app/store/selectors/auth.selectors';
import { Router } from '@angular/router';
import { SetMenuAction } from 'src/app/store/actions/menu/set-menu,action';
import { SetRoleAction } from 'src/app/store/actions/auth/set-role,action';
import { LoginEffectAction } from 'src/app/store/actions/auth/login-effect.action';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public hidePassword: boolean = true;

    public ROLES = ROLES;

    public loginForm: FormGroup;

    public role: Role;

    constructor(private fb: FormBuilder, private store: Store, private router: Router) {}

    public ngOnInit(): void {
        this.store.select(getRole).subscribe((role: Role) => {
            this.role = role;
        });

        if (this.router.url === ROUTES.ACADEMY_LANDING) {
            this.store.dispatch(new SetRoleAction(ROLES.ACADEMY));
        }

        if (this.router.url === ROUTES.GAINER_LANDING) {
            this.store.dispatch(new SetRoleAction(ROLES.GAINER));
        }

        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(REGEXP.EMAIL)]],
            password: ['', [Validators.required, Validators.pattern(REGEXP.PASSWORD)]]
        });
    }

    public login(): void {
        this.store.dispatch(
            new LoginEffectAction({
                email: this.loginForm.controls.email.value,
                password: this.loginForm.controls.password.value
            })
        );
    }

    public setMenuConfig(page: Page, open: boolean): void {
        this.store.dispatch(
            new SetMenuAction({
                page,
                open
            })
        );
    }

    public changeRole(role: Role): void {
        this.store.dispatch(new SetRoleAction(role));
    }
}
