import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getOwnAcademy } from 'src/app/store/selectors/auth.selectors';
import { AcademyTypeList, Academy, ACADEMY_TYPES, ACADEMY_DEFAULT, PRICE_DEFAULT } from 'src/app/types/academy.types';
import { ICONS } from 'src/app/types/types';
import { Location } from 'src/app/types/locations.types';
import { getLocations } from 'src/app/store/selectors/location.selectors';
import { DomSanitizer } from '@angular/platform-browser';
import { SetLocationsAction } from 'src/app/store/actions/location/set-locations.action';
import { GetLocationsAction } from 'src/app/store/actions/location/get-locations.action';
import { SetOwnAcademyAction } from 'src/app/store/actions/auth/set-own-academy.action';
import { SetStepAction } from 'src/app/store/actions/auth/set-step.action';

@Component({
    selector: 'app-academy-profile',
    templateUrl: './academy-profile.component.html',
    styleUrls: ['./academy-profile.component.scss']
})
export class AcademyMenuComponent implements OnInit {
    @Input() register: boolean;

    @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

    public hidePassword: boolean = true;

    public hideConfirmPassword: boolean = true;

    public scheduleValid: boolean = true;

    public TYPES: AcademyTypeList = ACADEMY_TYPES;

    public ICONS = { ...ICONS };

    public academy: Academy = { ...ACADEMY_DEFAULT };

    public locations$: Observable<Array<Location>>;

    public academy$: Observable<Academy>;

    public registerForm: FormGroup = this.fb.group({
        title: ['', [Validators.required]],
        shortDescription: ['', [Validators.required, Validators.maxLength(175)]],
        description: ['', [Validators.required, Validators.maxLength(1000)]],
        location: ['', [Validators.required]],
        price: [0, [Validators.minLength(0), Validators.maxLength(99)]],
        lat: [0],
        lng: [0]
    });

    constructor(private fb: FormBuilder, private store: Store, private sanitizer: DomSanitizer) {}

    public ngOnInit(): void {
        this.academy$ = this.store.select(getOwnAcademy);
        this.locations$ = this.store.select(getLocations);

        this.academy$.subscribe((academy: Academy) => {
            this.academy = academy;

            this.registerForm = this.fb.group({
                title: [this.sanitizer.sanitize(1, this.academy.name), [Validators.required]],
                shortDescription: [this.academy.short_description, [Validators.required, Validators.maxLength(175)]],
                description: [this.academy.description, [Validators.required]],
                location: [this.sanitizer.sanitize(1, this.academy.location), [Validators.required]],
                price: [this.academy.price.price, [Validators.minLength(0), Validators.maxLength(100000)]],
                lat: [this.academy.lat],
                lng: [this.academy.lng]
            });

            this.registerForm.valueChanges.subscribe(() => {
                this.store.dispatch(
                    new SetOwnAcademyAction({
                        ...this.academy,
                        description: this.registerForm.controls.description.value,
                        location: this.registerForm.controls.location.value,
                        price: {
                            ...PRICE_DEFAULT,
                            price: this.registerForm.controls.price.value
                        },
                        short_description: this.registerForm.controls.shortDescription.value,
                        name: this.registerForm.controls.title.value,
                        lat: this.registerForm.controls.lat.value,
                        lng: this.registerForm.controls.lng.value
                    })
                );
                this.valid.emit(this.registerForm.valid);
            });
        });
    }

    public setLocation(location: Location): void {
        this.store.dispatch(new SetLocationsAction([]));
        this.registerForm.controls.location.setValue(location.place_name);
        this.store.dispatch(
            new SetOwnAcademyAction({
                ...this.academy,
                location: location.place_name,
                name: this.registerForm.controls.title.value,
                short_description: this.registerForm.controls.shortDescription.value,
                description: this.registerForm.controls.description.value,
                price: {
                    ...PRICE_DEFAULT,
                    price: this.registerForm.controls.price.value
                },
                lat: location.center[1],
                lng: location.center[0]
            })
        );
    }

    public searchLocations($event): void {
        if ($event.target.value.length > 1) {
            this.store.dispatch(new GetLocationsAction($event.target.value));
            return;
        }

        this.store.dispatch(new SetLocationsAction([]));
    }

    public nextStep(): void {
        this.store.dispatch(new SetStepAction(2));
    }

    public priceFocusOut(): void {
        this.registerForm.controls.price.setValue(Number(this.registerForm.controls.price.value).toFixed(2));
    }

    public setScheduleValid(status: boolean): void {
        this.scheduleValid = status;
    }
}
