import { AMENITY_STATE_DEFAULT, AmenityState } from 'src/app/types/state.types';
import { AmenitiesActions } from '../actions/amenity.actions';
import { SET_AMENITIES } from '../actions/amenity/set-amenities.action';

const initialState: AmenityState = {
    ...AMENITY_STATE_DEFAULT
};

export const amenityReducer = (state = initialState, action: AmenitiesActions) => {
    switch (action.type) {
        case SET_AMENITIES:
            return {
                ...state,
                amenities: new Array(...action.amenities)
            };
        default:
            return state;
    }
};

export const getAmenitiesState = (state: AmenityState) => {
    return state.amenities;
};
