import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getRole, getStep } from 'src/app/store/selectors/auth.selectors';
import { Role } from 'src/app/types/types';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    public step$: Observable<number>;

    public role$: Observable<Role>;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.role$ = this.store.select(getRole);
        this.step$ = this.store.select(getStep);
    }
}
