import { Action } from '@ngrx/store';
import { Location } from 'src/app/types/locations.types';

export const SET_LOCATIONS = '[Location] set location list';

export class SetLocationsAction implements Action {
    readonly type = SET_LOCATIONS;

    constructor(public locations: Array<Location>) {}
}
