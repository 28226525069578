import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { DeviceService } from 'src/app/core/services/device.service';
import { SetTypeAction } from 'src/app/store/actions/academy/set-type.action';
import { GetLocationByCoordsEffect } from 'src/app/store/actions/location/get-location-by-coords-effect.action';
import { GetLocationsAction } from 'src/app/store/actions/location/get-locations.action';
import { SetLocationAction } from 'src/app/store/actions/location/set-location.action';
import { SetLocationsAction } from 'src/app/store/actions/location/set-locations.action';
import { getType } from 'src/app/store/selectors/academy.selectors';
import { getCurrentLocation, getLocations } from 'src/app/store/selectors/location.selectors';
import { getMenu } from 'src/app/store/selectors/menu.selectors';
import { ModalComponent } from 'src/app/templates/modals/modal/modal.component';
import { SearchModalComponent } from 'src/app/templates/modals/search/search.component';
import { AcademyType, TYPES, ACADEMY_TYPES } from 'src/app/types/academy.types';
import { AnalyticTypes, ANALYTIC_TYPES } from 'src/app/types/google-analytics.types';
import { CurrentPositionError, Location, LOCATION_DEFAULT } from 'src/app/types/locations.types';
import { COOKIES, ICONS, IMAGES, MenuConfig, REGEXP, ROUTES } from 'src/app/types/types';

@Component({
    selector: 'search-location',
    templateUrl: './search-location.component.html',
    styleUrls: ['./search-location.component.scss']
})
export class SearchLocationComponent implements OnInit {
    @Input() select: boolean = false;

    @Input() button: boolean = false;

    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public ICONS: Record<string, string> = { ...ICONS };

    public focus: boolean = false;

    public isMobile: boolean = false;

    public userLocation: boolean = false;

    public selected: boolean = false;

    public menu$: Observable<MenuConfig>;

    public location: Location = { ...LOCATION_DEFAULT };

    public types: Array<string> = new Array(...TYPES);

    public locations: Array<Location> = [];

    public searchForm: FormGroup = this.fb.group({
        location: ['', [Validators.required]],
        type: ['', [Validators.required]]
    });

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private cookie: CookieService,
        private device: DeviceService,
        private router: Router,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.store
            .select(getCurrentLocation)
            .subscribe((location: Location) => {
                if (location.id) {
                    this.location = { ...location };
                    this.selected = true;
                    this.store
                        .select(getType)
                        .subscribe((type: AcademyType) => {
                            this.searchForm = this.fb.group({
                                location: [location.place_name, [Validators.required]],
                                type: [type === ACADEMY_TYPES.SOMETHING_NEW ? '' : type, [Validators.required]]
                            });
                        })
                        .unsubscribe();
                }
            })
            .unsubscribe();

        this.isMobile = this.device.isMobile();
        this.menu$ = this.store.select(getMenu);
        this.store.select(getLocations).subscribe((locations: Array<Location>) => {
            if (locations.length > 0) {
                if (this.searchForm.controls.location.value.length > 0) {
                    this.locations = locations;
                }
            }
        });
    }

    public selectLocation(location: Location): void {
        const currentType = this.searchForm.controls.type.value;
        this.cookie.set(COOKIES.LOCATION, JSON.stringify(location));
        this.location = { ...location };
        this.searchForm = this.fb.group({
            location: [location.place_name, [Validators.required]],
            type: [currentType, [Validators.required]]
        });
        this.userLocation = false;
        this.selected = true;
        this.locations = [];
        this.store.dispatch(new SetLocationsAction([]));
    }

    public search(): void {
        if (this.searchForm.value.location.length) {
            this.store.dispatch(new SetLocationAction(this.location));
            if (this.searchForm.controls.type.value && this.select) {
                this.store.dispatch(new SetTypeAction(this.searchForm.controls.type.value));
            }

            if (this.userLocation) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        this.router.navigate([ROUTES.SEARCH], {
                            queryParams: { lat: position.coords.latitude, lng: position.coords.longitude }
                        });
                    });
                }
            } else {
                this.router.navigate([ROUTES.SEARCH], {
                    queryParams: { lat: this.location.center[1], lng: this.location.center[0] }
                });
            }
        }
    }

    public getLocation(): void {
        if (this.searchForm.controls.location.value.length > 0) {
            this.store.dispatch(new GetLocationsAction(this.searchForm.controls.location.value));
        } else {
            this.locations = [];
            this.store.dispatch(new SetLocationsAction([]));
            this.focus = false;
        }
    }

    public currentLocation(): boolean {
        return !this.isMobile || new RegExp(REGEXP.SEARCH_ROUTE).test(this.router.url);
    }

    public openSearchModal(): void {
        if (this.device.isMobile() && new RegExp(REGEXP.SEARCH_ROUTE).test(this.router.url) === false) {
            this.dialog
                .open(SearchModalComponent, {
                    panelClass: 'modal__search'
                })
                .afterClosed()
                .subscribe((response: any) => {
                    this.searchForm.controls.type.setValue(response.type);
                    this.selectLocation(response.location);
                    this.search();
                });
        }
    }

    public findLocationByUser(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.store.dispatch(new SetLocationAction(LOCATION_DEFAULT));
                    this.store.dispatch(
                        new GetLocationByCoordsEffect({
                            lat: position.coords.longitude,
                            lng: position.coords.latitude
                        })
                    );

                    const locationSelector = this.store.select(getCurrentLocation).subscribe((location: Location) => {
                        if (location.place_name !== '') {
                            this.searchForm.controls.location.setValue(location.place_name);

                            this.userLocation = true;
                            this.selected = true;
                            this.location = location;
                            this.cookie.set(COOKIES.LOCATION, JSON.stringify(location));
                            this.focus = false;
                            locationSelector.unsubscribe();
                        }
                    });
                },
                (error: CurrentPositionError) => {
                    this.focus = false;
                    switch (error.code) {
                        case 1: {
                            this.dialog.open(ModalComponent, {
                                panelClass: 'modal__error',
                                data: {
                                    message: 'ERRORS.USER_DENIED_GEOLOCATION',
                                    confirm: 'OK',
                                    image: IMAGES.ERROR
                                }
                            });
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
            );
        }
    }

    public showResults(): boolean {
        return (this.locations.length || this.focus) && (!this.isMobile || new RegExp(REGEXP.SEARCH_ROUTE).test(this.router.url));
    }

    public hideResults(): void {
        this.focus = false;
        this.locations = [];
        this.store.dispatch(new SetLocationsAction(this.locations));
    }
}
