export type LangType = 'en';

export type LangKey =
    | 'SEARCH'
    | 'ACTIONS'
    | 'AUTH'
    | 'ERRORS'
    | 'SUCCESS'
    | 'HOME'
    | 'MENU'
    | 'ACADEMY'
    | 'SCHEDULE'
    | 'SOCIAL'
    | 'MAIN'
    | 'WARNING'
    | 'REVIEW'
    | 'REPLY'
    | 'REPORTS'
    | 'FEEDBACK'
    | 'ANALYTICS';

export type LangConfig = {
    [key in LangKey]: ConfigItem;
};

export interface ConfigItem {
    [key: string]: string;
}

export const EN: LangType = 'en';

export const EN_CONFIG: LangConfig = {
    ANALYTICS: {
        SEARCH_PERFORMED: 'Find the Sport in your city',
        ACADEMY_TYPE_SELECTED: 'Select type of the academy place',
        FILTER_SELECTED: 'Apply specializations',
        ACADEMY_PROFILE_OPENED: 'Open academy page',
        PERSONAL_WEBSITE_CLICKED: 'Open academy personal website',
        INSTAGRAM_CLICKED: 'Open academy instagram',
        SIGN_UP: 'Sign up click',
        ANALYTIC_CONFIG_DEFAULT: '',
        CREATE_ACCOUNT_CLICKED: 'Create account (first registration step)',
        SAVE_PROFILE_CLICKED: 'Create account (last registration step)'
    },
    ACTIONS: {
        SAVE_PROFILE: 'Save my profile',
        CLEAR: 'Clear',
        SAVE: 'Save',
        SEND: 'Send',
        RESTORE: 'Restore password',
        CLOSE: 'Close',
        CHANGE_PASSWORD: 'Change your password',
        CONTINUE: 'Continue',
        LEAVE_REVIEW: 'Leave a review',
        SHOW_CONTACT_INFO: 'Show contact information',
        SHOW_SCHEDULE: 'Show schedule',
        REPORT: 'Report',
        LOGIN: 'Login',
        SHOW_ALL: 'Show all',
        SHOW_LESS: 'Show less',
        PRIVACY: 'Privacy',
        TERMS: 'Terms',
        TERMS_OF_USE: 'Terms of Use',
        FEEDBACK: 'Feedback',
        CONTACTS: 'Contacts',
        TRY_AGAIN: 'Try again',
        OK: 'Ok',
        LEAVE: 'Leave',
        STAY: 'Stay',
        LOGOUT: 'Logout',
        CREATE_ACCOUNT: 'Create an Account',
        BROWSE_FILE: 'Browse File',
        CROP: 'Crop',
        CANCEL: 'Cancel',
        SIGN_IN: 'Register',
        REGISTER: 'Complete Registration',
        SEND_REVIEW: 'Send review',
        REPLY: 'Reply',
        LOGIN_HERE: 'Login here!',
        REGISTER_HERE: 'Register here!',
        YES: 'Yes',
        SHOW_MORE: 'Show more',
        COOKIES: 'Cookies'
    },
    SEARCH: {
        NOT_FOUND_ACADEMY: "Sorry - your search filters don't match any active profiles..",
        NOT_FOUND_ACADEMY_HELP:
            'Try adjusting your search by changing the Specializations, removing advanced filters, or changing the location in the search bar above',
        PRICE_RANGE: 'Gym Price',
        SPECIALIZATIONS: 'Specializations',
        FILTER: 'Filter',
        SEARCH: 'Search'
    },
    AUTH: {
        LOGIN_AS_GAINER: 'Want to log in as an Gainer?',
        LOGIN_AS_ACADEMY: 'Want to log in as an Academy?',
        JOIN_ACADEMY: 'Want to join with your fitness business?',
        JOIN_GAINER: 'Want to join as an Gainer?',
        NAME: 'Name',
        BIRTHDAY_YEAR: 'Birthday year',
        CHANGE_PASSWORD: 'Change password',
        CHANGE_PASSWORD_CONFIRM: 'Change your password?',
        OLD_PASSWORD: 'Current Password',
        ENTER_NEW_PASSWORD: 'New password',
        NEW_PASSWORD_REPEAT: 'Confirm new Password',
        FORGOT: 'Forgot password?',
        RESTORE_PASSWORD: 'Restore your password by Email',
        NEW_PASSWORD: 'Change password',
        LOGIN_GAINER_TITLE: 'Welcome back!',
        LOGIN_ACADEMY_TITLE: 'Welcome back!',
        EMAIL: 'Email',
        TITLE: 'Title',
        CONTACT_EMAIL: 'Contact email',
        TERMS: 'I agree to the ',
        PASSWORD: 'Password',
        NO_ACCOUNT: "Don't have an account yet?",
        HAVE_ACCOUNT: 'Already have the account?',
        REGISTER_HERE: 'Register here!',
        PASSWORD_REPEAT: 'Confirm password',
        REGISTER_GAINER_TITLE: 'Join our community to Gain together',
        REGISTER_ACADEMY_TITLE: 'Grow confidence with your customers by using Gain!',
        COACH_TITLE: 'Tell us more about you and your business',
        COACH_SUB_TITLE_HEADER: 'From our research, if you include in your description: ',
        COACH_SUB_TITLE_FOOTER:
            'It will lead to a 65% higher click through rate on your profile. First impressions are everything, so be sure to put your best foot forward!',
        COACH_SUB_TITLE_FIRST_ITEM: 'Your area of specialization',
        COACH_SUB_TITLE_SECOND_ITEM: 'Any awards achieved personally or professionally achieved',
        COACH_SUB_TITLE_THIRD_ITEM: 'Catering towards a cleaner and more hygienic gym',
        GYM_TITLE: 'Tell us more about your Gym',
        GYM_SUB_TITLE_HEADER: 'From our research, if you include in your description: ',
        GYM_SUB_TITLE_FOOTER:
            'It will lead to a 60% higher click through rate on your profile. First impressions are everything, so be sure to put your best foot forward!',
        GYM_SUB_TITLE_FIRST_ITEM: 'Clubs associated with the Gym',
        GYM_SUB_TITLE_SECOND_ITEM: 'Additional perks of membership i.e. free online classes',
        GYM_SUB_TITLE_THIRD_ITEM: 'Adding a personal touch',
        SPORTS_CLUB_TITLE: 'Tell me about your Club and its history',
        SPORTS_CLUB_SUB_TITLE_HEADER: 'From our research, if you include in your description: ',
        SPORTS_CLUB_SUB_TITLE_FOOTER:
            'It will lead to a 65% higher click through rate on your profile. First impressions are everything, so be sure to put your best foot forward!',
        SPORTS_CLUB_SUB_TITLE_FIRST_ITEM: 'Skill level as per each team',
        SPORTS_CLUB_SUB_TITLE_SECOND_ITEM: 'What age groups you cater for',
        SPORTS_CLUB_SUB_TITLE_THIRD_ITEM: 'Previous awards achieved',
        REGISTER_ACADEMY_SUB_TITLE: 'Create your partner account ',
        REGISTER_GAINER_SUB_TITLE: 'Create your gainer account ',
        CLOSE: "Are you sure you don't want to continue our partnership?",
        THUMBNAIL_IMAGE: 'Thumbnail image',
        GALLERY_IMAGES: 'Gallery image(s)',
        PROFILE_INFO: 'Profile information',
        SHORT_DESCRIPTION: 'Short description',
        DESCRIPTION: 'Main Description',
        LOCATION: 'Location',
        PRICE: 'Price per annual membership',
        REGISTER_SKILLS_TITLE: 'Which sport do you facilitate?',
        GALLERY_INFO: 'You can upload up to 10 photos, 2 MB maximum, .png or .jpg',
        SCHEDULE_IMAGE: 'Schedule image(s)',
        PHONE: 'Phone',
        GAINER_TITLE: 'Tell us more about yourself',
        CONFIRM_LOGOUT: 'Are you sure you want to log out?'
    },
    ERRORS: {
        USER_DENIED_GEOLOCATION: 'User denied Geolocation',
        RESTORE: "We don't have any users with this email. Please make sure the email is correct or try to register instead.",
        ERROR: 'Error',
        RESTORE_REQUEST: 'Something went wrong, please try again later.',
        RESTORE_CONFIRM_EXPIRED: 'Your link has expired. Try to restore the password again.',
        CHANGE_PASSWORD: 'The current password is not valid.',
        PASSWORD: "The password doesn't match",
        OLD_PASSWORD: "The old password doesn't match",
        NEW_PASSWORD: "The new password doesn't match",
        NEW_REPEAT_PASSWORD: 'The confirm password and new password must match.',
        REPEAT_PASSWORD: 'The confirm password and password must match.',
        THUMBNAIL: 'Thumbnail image is required!',
        GYM_TITLE: 'Name cannot be empty!',
        SHORT_DESCRIPTION: 'Short Description cannot be empty!',
        DESCRIPTION: 'Description cannot be empty!',
        Location: 'Location cannot be empty!',
        PRICE: 'Monthly Price cannot be empty!',
        EMAIL: 'Please check that email is correct.',
        PHONE_REQUIRED: 'Phone cannot be empty!',
        EMPTY_GALLERY: 'Gallery cannot be empty!',
        NOT_APPROVED: 'Your profile is not approved. Please update your information.',
        DECLINED: 'Your account has been declined. You can contact the administrator for further information.',
        LOGIN_INVALID: 'Invalid login details',
        PHONE: 'Phone is not valid',
        SOCIAL_PREFIX: 'This is not a ',
        REGISTER_EXIST_EMAIL: 'The email has already been taken.',
        SOCIAL_SUFFIX: ' domain',
        WRONG: 'Something went wrong. Please, try again',
        BLOCKED: 'Your profile is blocked. You can contact the administrator for further information.',
        NOT_APPROVED_YET: 'Your profile is not approved yet.',
        IMAGE_SIZE: 'You can upload images that are not bigger than 2 MB'
    },
    SUCCESS: {
        RESTORE: 'We will send a link to reset your password, please check your email.',
        SUCCESS: 'Success',
        RESTORE_REQUEST: 'The password has been successfully changed.',
        LOGIN: 'Login successful',
        REGISTER:
            "Thank you for applying to be a partner of Gain! Your Information has been submitted for review. Your profile will be visible for others after it's confirmed.",
        CHANGE_PASSWORD: "You've successfully changed your password",
        UPDATE: 'Updated successfully',
        APPROVE: 'Your account successfully updated. Your profile will be checked soon.',
        REPORT_SEND: 'Your report has been sent successfully',
        FEEDBACK_SEND: 'Your feedback has been sent successfully',
        REVIEW_SEND: 'The review successfully created, it will be confirmed soon.'
    },
    HOME: {
        TITLE_GAINER: 'Gain, set match',
        TITLE_ACADEMY: 'Built to make the Health & Fitness industry better.',
        SEARCH_NEAR: 'Search near me',
        DESCRIPTION_GAINER:
            "Let us help you discover your true potential through health & fitness. Whether you're experienced in fitness, looking to start a new sport, or trying to find alternative ways to get fit, discover what’s going on in your area through Gain.",
        DESCRIPTION_ACADEMY:
            "Gain gives you a platform to succeed in today's fast-paced environment. We provide an accreditation tool that will showcase your quality product and services. Track and learn about your potential new clients through our industry-leading progressive profiling system. This allows you to understand more about your customers and what they want from you. Gain today, in a better way."
    },
    MENU: {
        FAVORITES: 'Favourites',
        FEEDBACK: 'Feedback',
        PROFILE: 'Profile',
        ACCOUNT_SETTINGS: 'Account settings',
        PROFILE_SETTINGS: 'Profile settings',
        LOGIN: 'Sign in',
        REGISTER: "Sign up - it's free!",
        MENU: 'Menu',
        YOUR_EMAIL: 'Your email',
        YOUR_PASSWORD: 'Your password',
        SURE: 'Are you sure?',
        SUBSCRIPTIONS: 'Subscriptions',
        PROFILE_UPDATES: 'Profile updates',
        NEW_REVIEWS: 'New reviews',
        WEEKLY_DEIGEST: 'Weekly digest',
        PERFORMANCE: 'Get insights about your profile performance.',
        REVIEW_EMAIL: 'Recieve email notifications when you get new reviews',
        ADMIN_EDIT: 'Stay informed when your profile was edited by the admin',
        FAVORITES_LIST: 'My favorites list',
        OPEN_ACADEMY_PAGE: 'Open academy page'
    },
    ACADEMY: {
        ADD_TO_FAVORITES: 'Add to favorites',
        AMENITIES: 'Amenities',
        REVIEWS: 'Reviews',
        NO_REVIEWS: 'No reviews yet',
        NO_AMENITIES: 'No amenities',
        REVIEW_BE_FIRST: 'Be the first to write a review!',
        WeekSchedule: 'Week schedule',
        CONTACT: 'Please contact the academy for the most recent information',
        CONTACT_INFO: 'Contact information'
    },
    SCHEDULE: {
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday',
        MONDAY_SHORT: 'Mo',
        TUESDAY_SHORT: 'Tu',
        WEDNESDAY_SHORT: 'We',
        THURSDAY_SHORT: 'Th',
        FRIDAY_SHORT: 'Fr',
        SATURDAY_SHORT: 'Sa',
        SUNDAY_SHORT: 'Su'
    },
    SOCIAL: {
        TIKTOK: 'TikTok',
        WEB: 'Web site',
        INSTAGRAM: 'Instagram',
        FACEBOOK: 'Facebook',
        YOUTUBE: 'Youtube',
        TWITTER: 'Twitter',
        LINKEDIN: 'LinkedIn'
    },
    MAIN: {
        GAIN: 'Gain',
        NUMBER: '(302) 555-0107',
        LOCATION: 'London SW4 6DH, United Kingdom',
        COPYRIGHT: '© 2021 Gain, Inc. All rights reserved',
        CURRENCY: '€',
        LANDINGS: 'Landings',
        DOCUMENTS: 'Documents',
        ACADEMY: 'Academy',
        GAINER: 'Gainer',
        ACADEMY_TYPE: 'Type of the academy',
        LOOKING_FOR: "I'm looking for",
        FIND: 'Find the Sport in your city',
        SEARCH_EMPTY: 'Search by city, address, or postcode'
    },
    WARNING: {
        SCHEDULE_OPEN: 'To view this information, please register or log in',
        REVIEW_AUTH: 'To view this information, please ',
        REVIEW_AUTH_OR: ' or ',
        SCHEDULE_EMPTY: 'This academy does not have uploaded schedule',
        REVIEW_IMAGE_LIMIT: 'You can upload up to 3 photos: 2 MB maximum, .png or .jpg',
        FEEDBACK_AUTH: 'To leave feedback, please register or log in'
    },
    REVIEW: {
        LEAVE: 'Leave a review for ',
        EQUIPMENT: 'Equipment',
        WILLINGNESS: 'Willingness to refer',
        OVERALL: 'Overall impression',
        ADD_MESSAGE: 'Add your message',
        UPLOAD: 'Upload images',
        MONEY: 'Value for money'
    },
    REPLY: {
        REPLY_TO: 'Reply to '
    },
    REPORTS: {
        TITLE: 'Report the incorrect information',
        SUB_TITLE: 'Please let us know if anything is missing or incorrect'
    },
    FEEDBACK: {
        TITLE: 'Feedback',
        SYB_TITLE: 'Enter your feedback message'
    }
};
