import { isAuthorized } from 'src/app/store/selectors/auth.selectors';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { ROUTES } from 'src/app/types/types';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private authorized: boolean;

    constructor(private router: Router, private store: Store) {
        this.store.select(isAuthorized).subscribe((status: boolean) => {
            this.authorized = status;
        });
    }

    public canActivate(): boolean {
        if (this.authorized) {
            return true;
        }

        this.router.navigate([ROUTES.GAINER_LANDING]);
        return false;
    }
}
