<section class="social">
    <div class="social__link" *ngIf="(academy$ | async).social_links.facebook">
        <a target="_blank" rel="noreferer noopener" href="{{ (academy$ | async).social_links.facebook }}">
            <i class="icon__social--facebook"></i>
        </a>
    </div>

    <div class="social__link" rel="noreferer noopener" *ngIf="(academy$ | async).social_links.linkedin">
        <a target="_blank" href="{{ (academy$ | async).social_links.linkedin }}">
            <i class="icon__social--linkedin"></i>
        </a>
    </div>

    <div class="social__link" rel="noreferer noopener" *ngIf="(academy$ | async).social_links.youtube">
        <a target="_blank" href="{{ (academy$ | async).social_links.youtube }}">
            <i class="icon__social--youtube"></i>
        </a>
    </div>

    <div class="social__link" rel="noreferer noopener" *ngIf="(academy$ | async).social_links.tiktok">
        <a target="_blank" href="{{ (academy$ | async).social_links.tiktok }}">
            <i class="icon__social--tiktok"></i>
        </a>
    </div>

    <div class="social__link" rel="noreferer noopener" *ngIf="(academy$ | async).social_links.instagram">
        <a
            analytics
            [type]="ANALYTIC_TYPES.INSTAGRAM_CLICKED"
            [label]="(academy$ | async).social_links.instagram"
            target="_blank"
            href="{{ (academy$ | async).social_links.instagram }}"
        >
            <i class="icon__social--instagram"></i>
        </a>
    </div>

    <div class="social__link" rel="noreferer noopener" *ngIf="(academy$ | async).social_links.twitter">
        <a target="_blank" href="{{ (academy$ | async).social_links.twitter }}">
            <i class="icon__social--twitter"></i>
        </a>
    </div>

    <div class="social__link" rel="noreferer noopener" *ngIf="(academy$ | async).social_links.personal_website">
        <a
            analytics
            [type]="ANALYTIC_TYPES.PERSONAL_WEBSITE_CLICKED"
            [label]="(academy$ | async).social_links.personal_website"
            target="_blank"
            href="{{ (academy$ | async).social_links.personal_website }}"
        >
            <i class="icon__social--web"></i>
        </a>
    </div>
</section>
