import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Academy, ACADEMY_TYPES } from 'src/app/types/academy.types';
import { AnalyticTypes, ANALYTIC_TYPES } from 'src/app/types/google-analytics.types';
import { Skill } from 'src/app/types/skill.types';
import { IMAGES, ROUTES } from 'src/app/types/types';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, AfterViewInit {
    @Input() academy: Academy;

    @ViewChild('skills') private skills: any;

    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public all: boolean = false;

    public default: string = IMAGES.DEFAULT;

    public IMAGE_PATH: string = environment.images;

    public TYPES = ACADEMY_TYPES;

    public collapsedSkills: Array<Skill> = [];

    constructor(private router: Router) {}

    public ngOnInit(): void {
        this.collapsedSkills = new Array(...this.academy.skills);
    }

    public ngAfterViewInit(): void {
        try {
            // eslint-disable-next-line no-underscore-dangle
            let listWidth: number = this.skills._elementRef.nativeElement.offsetWidth - 60;

            this.collapsedSkills = [];

            // eslint-disable-next-line no-underscore-dangle
            this.skills.chips._results.forEach((chip, index) => {
                // eslint-disable-next-line no-underscore-dangle
                if (listWidth - chip._elementRef.nativeElement.offsetWidth > 0 && index < 3) {
                    this.collapsedSkills.push(this.academy.skills[index]);

                    // eslint-disable-next-line no-underscore-dangle
                    listWidth -= chip._elementRef.nativeElement.offsetWidth;
                }
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('calc skills styles error: ', error);
        }
    }

    public academyOpen(): void {
        this.router.navigate([ROUTES.ACADEMY], { queryParams: { id: this.academy.id } });
    }
}
