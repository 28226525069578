import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getAcademies } from 'src/app/store/selectors/academy.selectors';
import { Academy } from 'src/app/types/academy.types';
import { COLORS, Marker, MarkerPopup, MARKER_POPUP_DEFAULT, SELECTED } from 'src/app/types/map.types';
import { IMAGES, ICONS, ROUTES } from 'src/app/types/types';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, OnChanges {
    @Input() lat: number;

    @Input() lng: number;

    @Input() marker: string;

    @Input() controls: boolean;

    @Input() static: boolean;

    @Input() selected: number;

    public zoom: number = 10;

    public markers: Array<Marker> = [];

    public fitBoundsOptions = { maxZoom: 10 };

    public positionOptions = { enableHighAccuracy: true };

    private colors: Map<string, string> = new Map(COLORS);

    private IMAGE_DEFAULT: string = IMAGES.DEFAULT;

    public ICONS = ICONS;

    public popup: MarkerPopup = { ...MARKER_POPUP_DEFAULT };

    constructor(private store: Store, private router: Router, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (!this.static) {
            this.store.select(getAcademies).subscribe((academies: Array<Academy>) => {
                this.markers = [];
                if (academies.length) {
                    academies.forEach((academy: Academy) => {
                        this.markers.push({
                            lat: academy.lat,
                            lng: academy.lng,
                            id: academy.id,
                            type: academy.type,
                            color: this.colors.get(academy.type),
                            popup: {
                                title: academy.name,
                                thumbnail: academy.thumbnail.name
                                    ? `url(${environment.images}${academy.thumbnail.name})`
                                    : this.IMAGE_DEFAULT,
                                location: academy.location,
                                id: academy.id,
                                lat: academy.lat,
                                lng: academy.lng
                            }
                        });
                    });
                }
            });
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.selected && changes.selected.currentValue !== changes.selected.previousValue && !changes.selected.firstChange) {
            const index = this.markers.findIndex((marker: Marker) => marker.id === changes.selected.previousValue);
            if (index !== -1) {
                this.markers[index].color = this.colors.get(this.markers[index].type);
            }

            if (changes.selected.currentValue !== 0) {
                const select = this.markers.findIndex((marker: Marker) => marker.id === changes.selected.currentValue);
                if (select !== -1) {
                    this.markers[select].color = this.colors.get(SELECTED);
                }
            }
        }
    }

    public openAcademyPage(id: number) {
        this.router.navigate([ROUTES.ACADEMY], { queryParams: { id } });
    }

    public openPopup(popup: MarkerPopup): void {
        this.popup = {
            ...popup,
            open: true
        };
    }

    public closePopup(): void {
        this.popup = {
            ...MARKER_POPUP_DEFAULT
        };
    }
}
