import { Action } from '@ngrx/store';
import { ImageItem } from 'src/app/types/academy.types';

export const UPLOAD_SCHEDULE_IMAGE = '[AUTH] upload schedule image effect response';

export class UploadScheduleItemAction implements Action {
    readonly type = UPLOAD_SCHEDULE_IMAGE;

    constructor(public image: ImageItem) {}
}
