import { Amenity } from './amenity.types';
import { Skill } from './skill.types';
import { IMAGES } from './types';

export type ImageType = 'thumbnail' | 'schedule' | 'gallery';

export type WeekSchedule = {
    monday: WeekDay;
    tuesday: WeekDay;
    wednesday: WeekDay;
    thursday: WeekDay;
    friday: WeekDay;
    saturday: WeekDay;
    sunday: WeekDay;
};

export type DAY = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export type AcademyType = 'coach' | 'gym' | 'sports club' | 'something new';

export type GainerType = 'gainer';

export type PriceType = 'high' | 'medium' | 'low';

export type PriceTypeList = {
    [key: string]: PriceType;
};
export interface Academy {
    is_promoted: boolean;
    is_visible: boolean;
    id: number;
    rating: number;
    lat: number;
    lng: number;
    email: string;
    phone: string;
    name: string;
    description: string;
    short_description: string;
    location: string;
    type: AcademyType;
    price: Price;
    week_schedule: WeekSchedule;
    social_links: SocialLinks;
    thumbnail: ImageItem;
    amenities: Amenity[];
    skills: Skill[];
    gallery: ImageItem[];
    schedule: ImageItem[];
}
export interface SocialLinks {
    personal_website: string;
    tiktok: string;
    instagram: string;
    facebook: string;
    youtube: string;
    twitter: string;
    linkedin: string;
}

export interface ImageItem {
    id: number;
    name: string;
}
export interface WeekDay {
    start_time: string;
    end_time: string;
    start_time_valid: boolean;
    end_time_valid: boolean;
    day?: DAY;
}

export interface WeekDayValid {
    day?: DAY;
    start_time: boolean;
    end_time: boolean;
}
export interface Price {
    model: boolean;
    price?: number;
    min?: number;
    max?: number;
    title: string;
    type: PriceType;
}
export interface AcademyTypeList {
    [key: string]: AcademyType;
}
export interface GainerTypeList {
    [key: string]: GainerType;
}
export interface Filter {
    open?: boolean;
    page?: number;
    count?: number;
    lat: string;
    lng: string;
    name?: string;
    type: AcademyType;
    skills: Array<Skill>;
    amenities: Array<number>;
    price?: Array<string>;
}
export interface GalleryDialogData {
    current: string;
    images: Array<ImageItem>;
}
export interface Report {
    academy_id: number;
    id: number;
    text: string;
}
export interface ReportDialog {
    academy_id: number;
}
export interface Pagination {
    current_page: number;
    last_page: number;
    per_page: number;
    from: number;
    to: number;
    total: number;
}

export const PRICE_TYPES: PriceTypeList = {
    HIGH: 'high',
    MEDIUM: 'medium',
    LOW: 'low'
};

export const SOCIAL_LINKS: SocialLinks = {
    personal_website: '',
    tiktok: '',
    instagram: '',
    facebook: '',
    youtube: '',
    twitter: '',
    linkedin: ''
};

export const CURRENCY: string = '€';

export const IMAGE_ITEM_DEFAULT: ImageItem = {
    id: 0,
    name: IMAGES.DEFAULT
};

export const GALLERY_IMAGE_ITEM_DEFAULT: ImageItem = {
    id: 0,
    name: IMAGES.DEFAULT_IMAGE
};

export const IMAGE_TYPES = {
    THUMBNAIL: 'thumbnail',
    SCHEDULE: 'schedule',
    GALLERY: 'gallery',
    REVIEW: 'review',
    REPLY: 'reply'
};

export const WEEK_DAY_DEFAULT: WeekDay = {
    start_time: '08:00',
    end_time: '20:00',
    start_time_valid: true,
    end_time_valid: true
};

export const WEEK_SCHEDULE_DEFAULT: WeekSchedule = {
    monday: WEEK_DAY_DEFAULT,
    tuesday: WEEK_DAY_DEFAULT,
    wednesday: WEEK_DAY_DEFAULT,
    thursday: WEEK_DAY_DEFAULT,
    friday: WEEK_DAY_DEFAULT,
    saturday: WEEK_DAY_DEFAULT,
    sunday: WEEK_DAY_DEFAULT
};

export const WEEK_SCHEDULE_LIST: Array<WeekDay> = [
    {
        ...WEEK_DAY_DEFAULT,
        day: 'sunday'
    },
    {
        ...WEEK_DAY_DEFAULT,
        day: 'monday'
    },
    {
        ...WEEK_DAY_DEFAULT,
        day: 'tuesday'
    },
    {
        ...WEEK_DAY_DEFAULT,
        day: 'wednesday'
    },
    {
        ...WEEK_DAY_DEFAULT,
        day: 'thursday'
    },
    {
        ...WEEK_DAY_DEFAULT,
        day: 'friday'
    },
    {
        ...WEEK_DAY_DEFAULT,
        day: 'saturday'
    }
];

export const PRICE_DEFAULT: Price = {
    model: false,
    price: 0,
    min: 0,
    max: 25,
    title: CURRENCY,
    type: PRICE_TYPES.LOW
};

export const ACADEMY_TYPES: AcademyTypeList = {
    COACH: 'coach',
    GYM: 'gym',
    SPORTS_CLUB: 'sports club',
    SOMETHING_NEW: 'something new'
};

export const ACADEMY_DEFAULT: Academy = {
    is_promoted: false,
    is_visible: false,
    id: 0,
    rating: 0,
    lat: 0,
    lng: 0,
    name: '',
    location: '',
    description: '',
    short_description: '',
    email: '',
    phone: '',
    social_links: SOCIAL_LINKS,
    week_schedule: WEEK_SCHEDULE_DEFAULT,
    price: PRICE_DEFAULT,
    type: ACADEMY_TYPES.COACH,
    thumbnail: IMAGE_ITEM_DEFAULT,
    gallery: [IMAGE_ITEM_DEFAULT],
    schedule: [IMAGE_ITEM_DEFAULT],
    amenities: [],
    skills: []
};

export const GAINER_TYPES: GainerTypeList = {
    GAINER: 'gainer'
};

export const TYPES: Array<AcademyType> = [ACADEMY_TYPES.COACH, ACADEMY_TYPES.GYM, ACADEMY_TYPES.SPORTS_CLUB, ACADEMY_TYPES.SOMETHING_NEW];

export const REGISTER_TYPES: Array<AcademyType | GainerType> = [ACADEMY_TYPES.COACH, ACADEMY_TYPES.GYM, ACADEMY_TYPES.SPORTS_CLUB];

export const PRICE_FILTER: Array<Price> = [
    {
        model: false,
        min: 41,
        max: Infinity,
        title: CURRENCY + CURRENCY + CURRENCY,
        type: PRICE_TYPES.HIGH
    },
    {
        model: false,
        min: 26,
        max: 40,
        title: CURRENCY + CURRENCY,
        type: PRICE_TYPES.MEDIUM
    },
    {
        model: false,
        min: 0,
        max: 25,
        title: CURRENCY,
        type: PRICE_TYPES.LOW
    }
];

export const FILTER_DEFAULT: Filter = {
    open: false,
    count: 0,
    lat: '0',
    lng: '0',
    type: ACADEMY_TYPES.SOMETHING_NEW,
    skills: [],
    amenities: [],
    price: []
};

export const REPORT_DEFAULT: Report = {
    academy_id: 0,
    id: 0,
    text: ''
};

export const PAGINATION_DEFAULT: Pagination = {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    from: 0,
    to: 0,
    total: 0
};
