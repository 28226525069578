import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { IvyCarouselModule } from 'angular-responsive-carousel';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

@NgModule({
    declarations: [],
    imports: [MatDatepickerModule],
    exports: [
        MatSnackBarModule,
        MatButtonModule,
        MatSidenavModule,
        MatChipsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatInputModule,
        NgxMaterialTimepickerModule,
        ImageCropperModule,
        MatIconModule,
        FormsModule,
        MatSelectModule,
        FontAwesomeModule,
        NgxSkeletonLoaderModule,
        MatTabsModule,
        BrowserAnimationsModule,
        MatDialogModule,
        IvyCarouselModule,
        MatSliderModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        MatPasswordStrengthModule,
        MatToolbarModule,
        MatTooltipModule
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'fill' }
        }
    ]
})
export class MaterialModule {}
