<section class="reviews__container">
    <div class="reviews">
        <div>
            <span class="reviews__title" lang key="ACADEMY.REVIEWS"></span>
            <span *ngIf="(total$ | async) !== 0" class="reviews__title">&nbsp;({{ total$ | async }})</span>
            <div *ngIf="(total$ | async) !== 0">
                <i class="icon__star"></i>
                <span class="reviews__title">{{ academy.rating.toFixed(1) }}</span>
            </div>
        </div>
        <button *ngIf="authorized && role === ROLES.GAINER" class="reviews__button" mat-button (click)="leaveReview()">
            <span lang key="ACTIONS.LEAVE_REVIEW"></span>
        </button>
    </div>

    <div *ngIf="authorized">
        <section class="academy__skills" *ngIf="(skills$ | async).length !== 0 && (reviews$ | async).length !== 0">
            <mat-chip-list>
                <mat-chip
                    (click)="switchSkill(skill)"
                    [class.selected]="skill.model"
                    class="skill__ship"
                    *ngFor="let skill of skills$ | async"
                    [color]="'#F0F3F5'"
                >
                    <span class="skill__name">{{ skill.name }}</span>
                    <span class="skill__count">&nbsp;({{ skill.count }})</span>
                </mat-chip>
            </mat-chip-list>
        </section>

        <section *ngIf="(reviews$ | async).length !== 0" class="reviews__list">
            <div *ngFor="let review of reviews$ | async">
                <app-review [reply]="role === ROLES.ACADEMY && academy.id === ownAcademyID" [review]="review"></app-review>
            </div>
            <button
                *ngIf="pagination.current_page < pagination.last_page"
                class="reviews__list--show-more"
                (click)="loadMore(pagination.current_page + 1)"
            >
                <span lang key="ACTIONS.SHOW_MORE"></span>
            </button>
        </section>

        <section *ngIf="(reviews$ | async).length === 0" class="reviews__empty">
            <span class="reviews__empty--title" lang key="ACADEMY.NO_REVIEWS"></span>
            <span *ngIf="role === ROLES.GAINER" class="reviews__empty--sub-title" lang key="ACADEMY.REVIEW_BE_FIRST"></span>
        </section>
    </div>

    <section class="reviews__auth" *ngIf="!authorized">
        <span lang key="WARNING.REVIEW_AUTH"></span>
        <a
            (click)="open('REGISTER')"
            analytics
            [type]="ANALYTIC_TYPES.SIGN_UP_CLICKED"
            [label]="SIGN_UP_LABELS.ACADEMY_REVIEWS"
            lang
            key="ACTIONS.SIGN_IN"
        ></a>
        <span lang key="WARNING.REVIEW_AUTH_OR"></span>
        <a (click)="open('LOGIN')" lang key="ACTIONS.LOGIN"></a>
    </section>
</section>
