import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CheckEmailEffectAction } from 'src/app/store/actions/auth/check-email-effect.action';
import { SetEmailAction } from 'src/app/store/actions/auth/set-email.action';
import { SetOwnAcademyAction } from 'src/app/store/actions/auth/set-own-academy.action';
import { SetPassword } from 'src/app/store/actions/auth/set-password.actiion';
import { SetRoleAction } from 'src/app/store/actions/auth/set-role,action';
import { SetMenuAction } from 'src/app/store/actions/menu/set-menu,action';
import { getRole } from 'src/app/store/selectors/auth.selectors';
import { AcademyType, ACADEMY_DEFAULT, GainerType, REGISTER_TYPES, ACADEMY_TYPES, GAINER_TYPES } from 'src/app/types/academy.types';
import { AnalyticConfig, AnalyticTypes, ANALYTIC_TYPES, CREATE_ACCOUNT_CLICKED } from 'src/app/types/google-analytics.types';
import { Page, PAGES, Pages, REGEXP, Role, Roles, ROLES, ROUTES } from 'src/app/types/types';

@Component({
    selector: 'app-user-register',
    templateUrl: './user-register.component.html',
    styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {
    public showStrength: boolean = false;

    public hideConfirmPassword: boolean = true;

    public PAGES: Pages = { ...PAGES };

    public role: Role = ROLES.GAINER;

    public ROUTES: Record<string, string> = { ...ROUTES };

    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public CREATE_ACCOUNT_CLICKED: AnalyticConfig = { ...CREATE_ACCOUNT_CLICKED };

    public ROLES: Roles = { ...ROLES };

    public types: Array<AcademyType | GainerType> = [...REGISTER_TYPES];

    public registerForm: FormGroup = this.fb.group({});

    constructor(private fb: FormBuilder, private store: Store, private router: Router) {}

    public ngOnInit(): void {
        this.store.select(getRole).subscribe((role: Role) => {
            this.role = role;
        });

        if (this.router.url === ROUTES.ACADEMY_LANDING) {
            this.store.dispatch(new SetRoleAction(ROLES.ACADEMY));
        }

        if (this.router.url === ROUTES.GAINER_LANDING) {
            this.store.dispatch(new SetRoleAction(ROLES.GAINER));
        }

        this.store.select(getRole).subscribe((role: Role) => {
            if (role === ROLES.GAINER) {
                this.types = [GAINER_TYPES.GAINER];
            } else {
                this.types = [...REGISTER_TYPES];
            }

            this.registerForm = this.fb.group({
                email: ['', [Validators.required, Validators.pattern(REGEXP.EMAIL), Validators.pattern(REGEXP.LATIN)]],
                password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(REGEXP.LATIN)]),
                repeatPassword: new FormControl('', [Validators.required, this.mismatchValidator(), Validators.pattern(REGEXP.LATIN)]),
                type: new FormControl(this.types[0]),
                agreeTerms: new FormControl(false, [Validators.required])
            });
        });
    }

    private mismatchValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const mismatch = this.registerForm.value.password !== control.value;
            return mismatch ? { mismatch: { value: control.value } } : null;
        };
    }

    public changeType(type: string) {
        this.registerForm.controls.type.setValue(type);
    }

    public nextStep(): void {
        switch (this.registerForm.controls.type.value) {
            case ACADEMY_TYPES.COACH:
            case ACADEMY_TYPES.GYM:
            case ACADEMY_TYPES.SPORTS_CLUB: {
                this.store.dispatch(new SetRoleAction(ROLES.ACADEMY));
                this.store.dispatch(
                    new SetOwnAcademyAction({
                        ...ACADEMY_DEFAULT,
                        type: this.registerForm.controls.type.value
                    })
                );
                break;
            }

            case GAINER_TYPES.GAINER: {
                this.store.dispatch(new SetRoleAction(ROLES.GAINER));
                break;
            }
            default: {
                return;
            }
        }

        this.store.dispatch(new SetPassword(this.registerForm.controls.password.value));
        this.store.dispatch(new SetEmailAction(this.registerForm.controls.email.value));
        this.store.dispatch(new CheckEmailEffectAction(this.registerForm.controls.email.value));
    }

    public setMenuConfig(page: Page, open: boolean) {
        this.store.dispatch(
            new SetMenuAction({
                page,
                open
            })
        );
    }

    public changeRole(role: Role): void {
        this.store.dispatch(new SetRoleAction(role));
    }
}
