export interface Location {
    relevance: number;
    text: string;
    type: string;
    place_name: string;
    id: string;
    properties: LocationProperties;
    bbox: Array<number>;
    center: Array<number>;
    context: Array<LocationContent>;
    geometry: LocationGeometry;
    place_type: Array<String>;
}

export interface LocationProperties {
    wikidata: string;
    short_code: string;
}

export interface LocationGeometry {
    type: string;
    coordinates: Array<number>;
}

export interface LocationContent {
    id: number;
    wikidata: string;
    short_code: string;
    text: string;
}

export interface Coords {
    lat: number;
    lng: number;
}

export interface CurrentPositionError {
    code: number;
    message: string;
}

export const LOCATION_DEFAULT: Location = {
    relevance: 0,
    id: '',
    text: '',
    type: '',
    place_name: '',
    geometry: {
        type: '',
        coordinates: []
    },
    properties: {
        wikidata: '',
        short_code: ''
    },
    bbox: [],
    center: [0, 0],
    context: [],
    place_type: []
};
