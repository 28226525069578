<section class="register--container">
    <div class="register">
        <mat-tab-group [selectedIndex]="step$ | async" mat-stretch-tabs class="register__tab-group">
            <mat-tab>
                <app-user-register></app-user-register>
            </mat-tab>
            <div [ngSwitch]="role$ | async">
                <div *ngSwitchCase="'academy'">
                    <mat-tab>
                        <app-academy-profile [register]="true"></app-academy-profile>
                    </mat-tab>
                    <mat-tab>
                        <app-skills-register></app-skills-register>
                    </mat-tab>
                    <mat-tab>
                        <app-contact-info [register]="true"></app-contact-info>
                    </mat-tab>
                </div>
                <div *ngSwitchCase="'gainer'">
                    <mat-tab>
                        <app-gainer-info [register]="true"></app-gainer-info>
                    </mat-tab>
                </div>
                <div *ngSwitchDefault></div>
            </div>
        </mat-tab-group>
    </div>
</section>
