import { Action } from '@ngrx/store';
import { ImageItem } from 'src/app/types/academy.types';

export const UPLOAD_REPLY_IMAGE = '[REPLY] add reply image';

export class UploadReplyImage implements Action {
    readonly type = UPLOAD_REPLY_IMAGE;

    constructor(public image: ImageItem) {}
}
