<section class="academy">
    <img
        class="academy__image"
        analytics
        [type]="ANALYTIC_TYPES.ACADEMY_PROFILE_OPENED"
        [label]="academy.name"
        (click)="academyOpen()"
        [src]="IMAGE_PATH + academy.thumbnail.name"
    />

    <section class="academy__info">
        <section class="academy__rating">
            <i *ngIf="academy.rating !== 0" class="icon__star"></i>
            <span *ngIf="academy.rating > 0">{{ academy.rating.toFixed(1) }}</span>
            <span class="academy__rating--empty" *ngIf="academy.rating === 0" lang key="ACADEMY.NO_REVIEWS"></span>
            <span *ngIf="academy.type === TYPES.GYM" class="academy__price">{{ academy.price.title }}</span>
        </section>

        <section class="academy__name">
            <span
                [style.cursor]="'pointer'"
                analytics
                [type]="ANALYTIC_TYPES.ACADEMY_PROFILE_OPENED"
                [label]="academy.name"
                (click)="academyOpen()"
                >{{ academy.name }}</span
            >
            <div class="academy__amenities--container" *ngIf="academy.amenities.length">
                <div class="academy__amenity" *ngFor="let amenity of academy.amenities.slice(0, 3)">
                    <i class="academy__amenity--icon icon__amenity--{{ amenity.id }}"></i>
                </div>
            </div>
        </section>

        <div class="academy__short-description">
            <span>{{ academy.short_description }}</span>
        </div>

        <mat-chip-list #skills *ngIf="!all" class="academy__skills">
            <mat-chip
                [style.maxWidth]="collapsedSkills.length < academy.skills.length ? '100%' : 'calc(100% - 60px)'"
                *ngFor="let skill of collapsedSkills"
            >
                <span class="skill__name">{{ skill.name }}</span>
            </mat-chip>
            <mat-chip (click)="all = true" *ngIf="collapsedSkills.length < academy.skills.length">
                <span class="skill__name">+{{ academy.skills.length - collapsedSkills.length }}</span>
            </mat-chip>
        </mat-chip-list>
    </section>
</section>

<section *ngIf="all" class="academy__skills--all" click-outside (outsideClick)="all = false">
    <div>
        <mat-chip *ngFor="let skill of academy.skills">
            <span class="skill__name">{{ skill.name }}</span>
        </mat-chip>
    </div>
</section>
