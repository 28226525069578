import { Favorite, Subscriptions } from 'src/app/types/state.types';
import { Specialization, Skill } from 'src/app/types/skill.types';
import { Location } from 'src/app/types/locations.types';
import { Injectable } from '@angular/core';
import { Academy, ACADEMY_TYPES, Filter, Price, PRICE_FILTER, Report } from 'src/app/types/academy.types';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
    API,
    RestoreRequest,
    LoginRequest,
    RegisterRequest,
    RegisterGainerRequest,
    Gainer,
    ReviewsRequest,
    ChangePasswordRequest
} from 'src/app/types/api.types';
import { Amenity } from 'src/app/types/amenity.types';

import { Document } from 'src/app/types/document.types';

import { Reply, Review, Reviews } from 'src/app/types/reviews.types';
import { Feedback } from 'src/app/types/types';
import { ImageItem, IMAGE_ITEM_DEFAULT } from '../../types/academy.types';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) {}

    /* AUTH */

    public academyApprove(academy: Academy): Observable<boolean> {
        const gallery: Array<number> = [];
        const schedule: Array<number> = [];
        const skills: Array<number> = [];
        const amenities: Array<number> = [];

        academy.gallery.forEach((image: ImageItem) => {
            if (image.id !== 0) {
                gallery.push(image.id);
            }
        });

        academy.skills.forEach((skill: Skill) => {
            if (skill.id !== 0) {
                skills.push(skill.id);
            }
        });

        academy.amenities.forEach((amenity: Amenity) => {
            if (amenity.id !== 0) {
                amenities.push(amenity.id);
            }
        });

        academy.schedule.forEach((image: ImageItem) => {
            if (image.id !== 0) {
                schedule.push(image.id);
            }
        });

        return this.http
            .post(environment.apiUrl.concat(API.POST.ACADEMY_APPROVE), {
                id: academy.id,
                gallery,
                skills,
                amenities,
                schedule,
                thumbnail_id: academy.thumbnail.id,
                price: academy.price.price,
                contact_email: academy.email,
                personal_website: academy.social_links.personal_website,
                tiktok: academy.social_links.tiktok,
                instagram: academy.social_links.instagram,
                facebook: academy.social_links.facebook,
                youtube: academy.social_links.youtube,
                twitter: academy.social_links.twitter,
                linkedin: academy.social_links.linkedin,
                description: academy.description,
                short_description: academy.short_description,
                type: academy.type,
                name: academy.name,
                location: academy.location,
                lat: academy.lat,
                lng: academy.lng,
                phone: academy.phone,
                week_schedule: academy.week_schedule
            })
            .pipe(map((response: any) => response.success));
    }

    public checkEmail(email: string): Observable<boolean> {
        return this.http.post(environment.apiUrl.concat(API.GET.CHECK_EMAIL), { email }).pipe(map((response: any) => response.success));
    }

    public academyRegistration(registration: RegisterRequest): Observable<boolean> {
        const gallery: Array<number> = [];
        const schedule: Array<number> = [];
        const skills: Array<number> = [];
        const amenities: Array<number> = [];

        registration.academy.gallery.forEach((image: ImageItem) => {
            if (image.id !== 0) {
                gallery.push(image.id);
            }
        });

        registration.academy.skills.forEach((skill: Skill) => {
            if (skill.id !== 0) {
                skills.push(skill.id);
            }
        });

        registration.academy.amenities.forEach((amenity: Amenity) => {
            if (amenity.id !== 0) {
                amenities.push(amenity.id);
            }
        });

        registration.academy.schedule.forEach((image: ImageItem) => {
            if (image.id !== 0) {
                schedule.push(image.id);
            }
        });

        return this.http
            .post(environment.apiUrl + API.POST.REGISTER, {
                gallery,
                skills,
                amenities,
                schedule,
                description: registration.academy.description,
                short_description: registration.academy.short_description,
                type: registration.academy.type,
                name: registration.academy.name,
                location: registration.academy.location,
                lat: registration.academy.lat,
                lng: registration.academy.lng,
                phone: registration.academy.phone,
                week_schedule: registration.academy.week_schedule,
                thumbnail_id: registration.academy.thumbnail.id,
                price: registration.academy.price.price,
                email: registration.user.email,
                contact_email: registration.academy.email,
                password: registration.user.password,
                confirm_password: registration.user.password,
                personal_website: registration.academy.social_links.personal_website,
                tiktok: registration.academy.social_links.tiktok,
                instagram: registration.academy.social_links.instagram,
                facebook: registration.academy.social_links.facebook,
                youtube: registration.academy.social_links.youtube,
                twitter: registration.academy.social_links.twitter,
                linkedin: registration.academy.social_links.linkedin
            })
            .pipe(map((res: any) => res.success));
    }

    public login(login: LoginRequest): Observable<any> {
        return this.http
            .post(environment.apiUrl + API.POST.LOGIN, {
                ...login
            })
            .pipe(map((res: any) => res.data));
    }

    public logout(): Observable<any> {
        return this.http.post(environment.apiUrl + API.POST.LOGOUT, {});
    }

    public restore(email: string): Observable<any> {
        return this.http.post(environment.apiUrl.concat(API.POST.RESTORE), {
            email
        });
    }

    public restoreConfirm(restore: RestoreRequest): Observable<any> {
        return this.http.post(environment.apiUrl.concat(API.POST.RESTORE_CONFIRM), {
            token: restore.token,
            password: restore.password,
            confirm_password: restore.confirmPassword
        });
    }

    /* Gainer */

    public gainerRegistration(registration: RegisterGainerRequest): Observable<boolean> {
        return this.http
            .post(environment.apiUrl + API.POST.REGISTER_GAINER, {
                ...registration.gainer,
                email: registration.user.email,
                password: registration.user.password,
                confirm_password: registration.user.password
            })
            .pipe(map((res: any) => res.success));
    }

    public getGainerInfo(): Observable<Gainer> {
        return this.http.get(environment.apiUrl + API.GET.GAINER).pipe(map((res: any) => res.data.profile));
    }

    public updateGainerInfo(gainer: Gainer): Observable<Gainer> {
        return this.http.patch(environment.apiUrl + API.PATCH.GAINER, { ...gainer }).pipe(
            map((res: any) => {
                return res.data.profile;
            })
        );
    }

    /* PROFILE */

    public getOwnAcademy(): Observable<Academy> {
        return this.http.get(environment.apiUrl + API.GET.ACADEMY).pipe(
            map((res: any) => {
                return {
                    is_promoted: res.data.academy.is_promoted,
                    is_visible: res.data.academy.is_visible,
                    id: res.data.academy.id,
                    rating: res.data.academy.rating,
                    lat: res.data.academy.lat,
                    lng: res.data.academy.lng,
                    email: res.data.academy.email,
                    phone: res.data.academy.phone,
                    name: res.data.academy.name,
                    description: res.data.academy.description,
                    short_description: res.data.academy.short_description,
                    location: res.data.academy.location,
                    type: res.data.academy.type,
                    social_links: {
                        facebook: res.data.academy.facebook ? res.data.academy.facebook : '',
                        instagram: res.data.academy.instagram ? res.data.academy.instagram : '',
                        linkedin: res.data.academy.linkedIn ? res.data.academy.linkedIn : '',
                        tiktok: res.data.academy.tiktok ? res.data.academy.tiktok : '',
                        twitter: res.data.academy.twitter ? res.data.academy.twitter : '',
                        personal_website: res.data.academy.personal_website ? res.data.academy.personal_website : '',
                        youtube: res.data.academy.youtube ? res.data.academy.youtube : ''
                    },
                    thumbnail: {
                        ...res.data.academy.thumbnail
                    },
                    amenities: res.data.academy.amenities,
                    skills: res.data.academy.skills,
                    price: this.initAcademyPrice(res.data.academy.price),
                    week_schedule: res.data.academy.week_schedule,
                    schedule: res.data.academy.schedule,
                    gallery: res.data.academy.gallery
                };
            })
        );
    }

    public updateOwnAcademy(academy: Academy): Observable<Academy> {
        const gallery: Array<number> = [];
        const schedule: Array<number> = [];
        const skills: Array<number> = [];
        const amenities: Array<number> = [];

        academy.gallery.forEach((image: ImageItem) => {
            if (image.id !== 0) {
                gallery.push(image.id);
            }
        });

        academy.schedule.forEach((image: ImageItem) => {
            if (image.id !== 0) {
                schedule.push(image.id);
            }
        });

        academy.skills.forEach((skill: Skill) => {
            if (skill.id !== 0) {
                skills.push(skill.id);
            }
        });

        academy.amenities.forEach((amenity: Amenity) => {
            if (amenity.id) {
                amenities.push(amenity.id);
            }
        });

        return this.http
            .patch(environment.apiUrl + API.PATCH.ACADEMY, {
                id: academy.id,
                gallery,
                skills,
                amenities,
                schedule,
                thumbnail_id: academy.thumbnail.id,
                price: academy.price.price,
                contact_email: academy.email,
                personal_website: academy.social_links.personal_website,
                tiktok: academy.social_links.tiktok,
                instagram: academy.social_links.instagram,
                facebook: academy.social_links.facebook,
                youtube: academy.social_links.youtube,
                twitter: academy.social_links.twitter,
                linkedin: academy.social_links.linkedin,
                description: academy.description,
                short_description: academy.short_description,
                type: academy.type,
                name: academy.name,
                location: academy.location,
                lat: academy.lat,
                lng: academy.lng,
                phone: academy.phone,
                week_schedule: academy.week_schedule
            })
            .pipe(map((response: any) => response.data.academy));
    }

    public updateAcademySettings(settings: Subscriptions): Observable<Subscriptions> {
        return this.http.patch(environment.apiUrl.concat(API.PATCH.ACADEMY_SETTINGS), settings).pipe(map((res: any) => res.data.settings));
    }

    public getAcademySettings(): Observable<Subscriptions> {
        return this.http.get(environment.apiUrl.concat(API.PATCH.ACADEMY_SETTINGS)).pipe(map((res: any) => res.data.settings));
    }

    public changeCurrentPassword(change: ChangePasswordRequest): Observable<boolean> {
        return this.http.post(environment.apiUrl.concat(API.POST.CHANGE_PASSWORD), change).pipe(map((res: any) => res.success));
    }

    /* ACADEMIES */

    public getAllApprovedAcademies(filter: Filter): Observable<any> {
        let params = new HttpParams();

        params = params.append('lat', filter.lat);
        params = params.append('lng', filter.lng);

        if (!!filter.type && filter.type !== ACADEMY_TYPES.SOMETHING_NEW) {
            params = params.append('type', filter.type);
        }

        if (!!filter.page && filter.page !== 0) {
            params = params.append('page', filter.page.toString());
        }

        if (filter.name) {
            params = params.append('name', filter.name);
        }

        if (!!filter.skills && !!filter.skills.length) {
            filter.skills.forEach((skill) => {
                params = params.append('skills[]', skill.id.toString());
            });
        }

        if (!!filter.amenities && !!filter.amenities.length) {
            filter.amenities.forEach((amenity) => {
                params = params.append('amenities[]', amenity.toString());
            });
        }

        if ((filter.type === ACADEMY_TYPES.GYM || filter.type === ACADEMY_TYPES.SOMETHING_NEW) && !!filter.price && !!filter.price.length) {
            filter.price.forEach((price) => {
                params = params.append('price_range[]', price);
            });
        }

        return this.http
            .get(environment.apiUrl + API.GET.ACADEMIES, {
                params
            })
            .pipe(
                map((res: any) => {
                    const academies: Array<Academy> = res.data.academies.data.map((_, index: number) => {
                        return {
                            is_promoted: res.data.academies.data[index].is_promoted,
                            is_visible: res.data.academies.data[index].is_visible,
                            id: res.data.academies.data[index].id,
                            rating: res.data.academies.data[index].rating,
                            lat: res.data.academies.data[index].lat,
                            lng: res.data.academies.data[index].lng,
                            email: res.data.academies.data[index].email,
                            phone: res.data.academies.data[index].phone,
                            name: res.data.academies.data[index].name,
                            description: res.data.academies.data[index].description,
                            short_description: res.data.academies.data[index].short_description,
                            location: res.data.academies.data[index].location,
                            type: res.data.academies.data[index].type,
                            social_links: {
                                facebook: res.data.academies.data[index].facebook ? res.data.academies.data[index].facebook : '',
                                instagram: res.data.academies.data[index].instagram ? res.data.academies.data[index].instagram : '',
                                linkedin: res.data.academies.data[index].linkedIn ? res.data.academies.data[index].linkedIn : '',
                                tiktok: res.data.academies.data[index].tiktok ? res.data.academies.data[index].tiktok : '',
                                twitter: res.data.academies.data[index].twitter ? res.data.academies.data[index].twitter : '',
                                personal_website: res.data.academies.data[index].personal_website
                                    ? res.data.academies.data[index].personal_website
                                    : '',
                                youtube: res.data.academies.data[index].youtube ? res.data.academies.data[index].youtube : ''
                            },
                            thumbnail: {
                                ...res.data.academies.data[index].thumbnail
                            },
                            amenities: res.data.academies.data[index].amenities,
                            skills: res.data.academies.data[index].skills,
                            price: this.initAcademyPrice(res.data.academies.data[index].price),
                            week_schedule: res.data.academies.data[index].week_schedule,
                            gallery: res.data.academies.data[index].gallery,
                            schedule: res.data.academies.data[index].schedule
                        };
                    });
                    const pagination = {
                        current_page: res.data.academies.current_page,
                        last_page: res.data.academies.last_page,
                        per_page: res.data.academies.per_page,
                        from: res.data.academies.from,
                        to: res.data.academies.to,
                        total: res.data.academies.total
                    };
                    return {
                        academies,
                        pagination
                    };
                })
            );
    }

    public getAcademy(ID: number): Observable<Academy> {
        return this.http.get(environment.apiUrl.concat(API.GET.ACADEMIES, '/', ID.toString())).pipe(
            map((response: any) => ({
                is_promoted: response.data.academy.is_promoted,
                is_visible: response.data.academy.is_visible,
                id: response.data.academy.id,
                rating: response.data.academy.rating,
                lat: response.data.academy.lat,
                lng: response.data.academy.lng,
                email: response.data.academy.email,
                phone: response.data.academy.phone,
                name: response.data.academy.name,
                description: response.data.academy.description,
                short_description: response.data.academy.short_description,
                location: response.data.academy.location,
                type: response.data.academy.type,
                social_links: {
                    facebook: response.data.academy.facebook ? response.data.academy.facebook : '',
                    instagram: response.data.academy.instagram ? response.data.academy.instagram : '',
                    linkedin: response.data.academy.linkedIn ? response.data.academy.linkedIn : '',
                    tiktok: response.data.academy.tiktok ? response.data.academy.tiktok : '',
                    twitter: response.data.academy.twitter ? response.data.academy.twitter : '',
                    personal_website: response.data.academy.personal_website ? response.data.academy.personal_website : '',
                    youtube: response.data.academy.youtube ? response.data.academy.youtube : ''
                },
                thumbnail: {
                    ...response.data.academy.thumbnail
                },
                amenities: response.data.academy.amenities,
                skills: response.data.academy.skills,
                price: this.initAcademyPrice(response.data.academy.price),
                week_schedule: response.data.academy.week_schedule,
                schedule: response.data.academy.schedule,
                gallery: response.data.academy.gallery
            }))
        );
    }

    /* AMENITIES */

    public getAmenities(): Observable<Array<Amenity>> {
        return this.http.get(environment.apiUrl.concat(API.GET.AMENITIES)).pipe(map((response: any) => response.data.amenities));
    }

    /* DOCUMENTS */

    public getDocuments(slug: string): Observable<Document> {
        return this.http.get(environment.apiUrl.concat(API.GET.DOCUMENTS, '/', slug)).pipe(map((response: any) => response.data.document));
    }

    /* IMAGE */

    public uploadImage(image: File, type: string): Observable<ImageItem> {
        const headers = new HttpHeaders();
        const body = new FormData();

        headers.append('Content-Type', 'multipart/form-data');
        body.append('image', image);
        body.append('type', type);

        return this.http
            .post(environment.apiUrl + API.POST.UPLOAD, body, {
                headers
            })
            .pipe(
                map((res: any) => {
                    if (res.success) {
                        return {
                            id: res.data.image.id,
                            name: res.data.image.name
                        };
                    }
                    return {
                        ...IMAGE_ITEM_DEFAULT
                    };
                })
            );
    }

    /* MAP */

    public getLocations(text: string): Observable<Array<Location>> {
        let params = new HttpParams();

        params = params.append('location', text);
        params = params.append('access_token', environment.mapBox.token);

        return this.http
            .get(environment.apiUrl + API.GET.LOCATIONS, {
                params
            })
            .pipe(
                map((res: any) => {
                    return res.data.locations;
                })
            );
    }

    public getLocationsByNavigation(lat: number, lng: number): Observable<Array<Location>> {
        let params = new HttpParams();

        params = params.append('lat', lat.toString());
        params = params.append('lng', lng.toString());

        params = params.append('access_token', environment.mapBox.token);

        return this.http
            .get(environment.apiUrl + API.GET.LOCATION_BY_NAVIGATION, {
                params
            })
            .pipe(map((response: any) => response.data.location));
    }

    /* SPECIALIZATIONS */

    public getSpecializations(): Observable<Array<Specialization>> {
        return this.http.get(environment.apiUrl + API.GET.SPECIALIZATIONS).pipe(map((response: any) => response.data));
    }

    /* Reviews */

    public getReviews(request: ReviewsRequest): Observable<Reviews> {
        let params = new HttpParams();

        params = params.append('page', request.page.toString());

        if (!!request.skills && !!request.skills.length) {
            request.skills.forEach((skill) => {
                params = params.append('skills[]', skill.toString());
            });
        }

        return this.http
            .get(environment.apiUrl + API.GET.REVIEWS.replace('id', request.id.toString()), {
                params
            })
            .pipe(map((response: any) => response.data));
    }

    public sendReply(reply: Reply): Observable<Reply> {
        const images: Array<number> = reply.images.map((image: ImageItem) => image.id);
        return this.http
            .post(environment.apiUrl + API.POST.REPLY, {
                text: reply.text,
                review_id: reply.review_id,
                images
            })
            .pipe(map((response: any) => response.data.reply));
    }

    public sendReport(report: Report): Observable<Report> {
        return this.http
            .post(environment.apiUrl + API.POST.REPORT, {
                text: report.text,
                academy_id: report.academy_id
            })
            .pipe(map((response: any) => response.data.report));
    }

    public sendFeedback(feedback: Feedback): Observable<boolean> {
        return this.http
            .post(environment.apiUrl + API.POST.FEEDBACK, {
                text: feedback.text
            })
            .pipe(map((response: any) => response.success));
    }

    public sendReview(review: Review): Observable<Review> {
        const images: Array<number> = review.images.map((image: ImageItem) => image.id);
        const skills: Array<number> = review.skills.map((skill: Skill) => skill.id);

        switch (review.academyType) {
            case ACADEMY_TYPES.COACH: {
                return this.http
                    .post(environment.apiUrl + API.POST.REVIEW, {
                        value_for_money_rating: review.value_for_money_rating,
                        overall_impression_rating: review.overall_impression_rating,
                        willingness_to_refer_rating: review.willingness_to_refer_rating,
                        academy_type: review.academyType,
                        academy_id: review.academy_id,
                        text: review.text,
                        images,
                        skills
                    })
                    .pipe(map((response: any) => response.data));
            }
            case ACADEMY_TYPES.GYM: {
                return this.http
                    .post(environment.apiUrl + API.POST.REVIEW, {
                        equipment_rating: review.equipment_rating,
                        academy_type: review.academyType,
                        willingness_to_refer_rating: review.willingness_to_refer_rating,
                        overall_impression_rating: review.overall_impression_rating,
                        academy_id: review.academy_id,
                        images,
                        text: review.text,
                        skills
                    })
                    .pipe(map((response: any) => response.data));
            }
            case ACADEMY_TYPES.SPORTS_CLUB: {
                return this.http
                    .post(environment.apiUrl + API.POST.REVIEW, {
                        overall_impression_rating: review.overall_impression_rating,
                        academy_type: review.academyType,
                        academy_id: review.academy_id,
                        images,
                        text: review.text,
                        skills
                    })
                    .pipe(map((response: any) => response.data));
            }
            default: {
                return this.http
                    .post(environment.apiUrl + API.POST.REVIEW, {
                        ...review,
                        images,
                        skills
                    })
                    .pipe(map((response: any) => response.data));
            }
        }
    }

    /* FAVORITES */

    public getFavorites(): Observable<Array<Favorite>> {
        return this.http.get(environment.apiUrl.concat(API.GET.FAVORITES)).pipe(
            map((res: any) => {
                const favorites: Array<Favorite> = res.data.favorites.data.map((favorite, index: number) => {
                    return {
                        ...favorite,
                        academy: {
                            is_promoted: res.data.favorites.data[index].academy.is_promoted,
                            is_visible: res.data.favorites.data[index].academy.is_visible,
                            id: res.data.favorites.data[index].academy.id,
                            rating: res.data.favorites.data[index].academy.rating,
                            lat: res.data.favorites.data[index].academy.lat,
                            lng: res.data.favorites.data[index].academy.lng,
                            email: res.data.favorites.data[index].academy.email,
                            phone: res.data.favorites.data[index].academy.phone,
                            name: res.data.favorites.data[index].academy.name,
                            description: res.data.favorites.data[index].academy.description,
                            short_description: res.data.favorites.data[index].academy.short_description,
                            location: res.data.favorites.data[index].academy.location,
                            type: res.data.favorites.data[index].academy.type,
                            social_links: {
                                facebook: res.data.favorites.data[index].academy.facebook
                                    ? res.data.favorites.data[index].academy.facebook
                                    : '',
                                instagram: res.data.favorites.data[index].academy.instagram
                                    ? res.data.favorites.data[index].academy.instagram
                                    : '',
                                linkedin: res.data.favorites.data[index].academy.linkedIn
                                    ? res.data.favorites.data[index].academy.linkedIn
                                    : '',
                                tiktok: res.data.favorites.data[index].academy.tiktok ? res.data.favorites.data[index].academy.tiktok : '',
                                twitter: res.data.favorites.data[index].academy.twitter
                                    ? res.data.favorites.data[index].academy.twitter
                                    : '',
                                personal_website: res.data.favorites.data[index].academy.personal_website
                                    ? res.data.favorites.data[index].academy.personal_website
                                    : '',
                                youtube: res.data.favorites.data[index].academy.youtube
                                    ? res.data.favorites.data[index].academy.youtube
                                    : ''
                            },
                            thumbnail: {
                                ...res.data.favorites.data[index].academy.thumbnail
                            },
                            amenities: res.data.favorites.data[index].academy.amenities,
                            skills: res.data.favorites.data[index].academy.skills,
                            price: this.initAcademyPrice(res.data.favorites.data[index].academy.price),
                            week_schedule: res.data.favorites.data[index].academy.week_schedule,
                            gallery: res.data.favorites.data[index].academy.gallery,
                            schedule: res.data.favorites.data[index].academy.schedule
                        }
                    };
                });
                return favorites;
            })
        );
    }

    public addToFavorites(id: number): Observable<any> {
        return this.http.post(environment.apiUrl + API.POST.FAVORITES, {
            academy_id: id
        });
    }

    public deleteFromFavorites(id: number): Observable<any> {
        return this.http.delete(environment.apiUrl + API.DELETE.FAVORITES.concat('/', id.toString()));
    }

    private initAcademyPrice(value: number): Price {
        switch (true) {
            case value < 26: {
                return {
                    ...PRICE_FILTER[2],
                    price: value
                };
            }
            case value < 41: {
                return {
                    ...PRICE_FILTER[1],
                    price: value
                };
            }
            case value > 40: {
                return {
                    ...PRICE_FILTER[0],
                    price: value
                };
            }
            default: {
                return {
                    ...PRICE_FILTER[0],
                    price: 0
                };
            }
        }
    }
}
