<section class="skills">
    <mat-accordion>
        <mat-expansion-panel [expanded]="expanded">
            <mat-expansion-panel-header *ngIf="header">
                <mat-panel-title>
                    <h4>
                        <span lang key="SEARCH.SPECIALIZATIONS"></span>
                    </h4>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="skills__type">
                <mat-accordion *ngFor="let specialization of skills; let index = index">
                    <mat-expansion-panel [expanded]="index === 0 || hasSelected(specialization.skills)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ specialization.name }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngFor="let skill of specialization.skills">
                            <mat-checkbox [(ngModel)]="skill.model" (click)="switchSkill(skill)" class="filter__checkbox">{{
                                skill.name
                            }}</mat-checkbox>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
