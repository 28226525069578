<section class="feedback">
    <div mat-dialog-title class="modal__title">
        <div>
            <span lang key="FEEDBACK.TITLE"></span>
            <span class="modal__sub-title" lang key="FEEDBACK.SUB_TITLE"></span>
        </div>
    </div>

    <div mat-dialog-title class="modal__content">
        <mat-form-field>
            <mat-label>
                <span lang key="REVIEW.ADD_MESSAGE"></span>
            </mat-label>
            <textarea matInput type="text" sanitize [(ngModel)]="feedback.text"></textarea>
        </mat-form-field>
    </div>

    <div mat-dialog-actions class="modal__actions">
        <div class="modal__actions--left"></div>
        <div class="modal__actions--right">
            <button (click)="close()" mat-button>
                <span lang key="ACTIONS.CANCEL"></span>
            </button>
            <button [disabled]="!feedback.text.trim()" class="reviews__button--send" mat-button (click)="send()">
                <span lang key="ACTIONS.SEND"></span>
            </button>
        </div>
    </div>
</section>
