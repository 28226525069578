<div class="map">
    <mgl-map
        class="map"
        [style]="'mapbox://styles/mapbox/light-v9'"
        [zoom]="static ? [14] : [8]"
        [center]="[lng, lat]"
        [interactive]="!static"
    >
        <div *ngFor="let m of markers">
            <mgl-marker #marker [lngLat]="[m.lng, m.lat]">
                <i class="icon__marker--{{ m.color }}" (click)="openPopup(m.popup)"></i>
            </mgl-marker>
            <mgl-popup [marker]="marker">
                <section class="marker__popup">
                    <div class="marker__popup--thumbnail" [style.background]="popup.thumbnail"></div>
                    <div>
                        <h2 (click)="openAcademyPage(popup.id)">{{ popup.title }}</h2>
                        <h3>{{ popup.location }}</h3>
                    </div>
                </section>
            </mgl-popup>
        </div>

        <mgl-marker *ngIf="marker" [lngLat]="[lng, lat]">
            <i class="icon__marker--{{ marker }}"></i>
        </mgl-marker>

        <mgl-control *ngIf="controls" mglNavigation></mgl-control>
        <mgl-control
            *ngIf="controls"
            mglGeolocate
            [fitBoundsOptions]="fitBoundsOptions"
            [positionOptions]="positionOptions"
            [showUserLocation]="true"
            [trackUserLocation]="true"
        ></mgl-control>
    </mgl-map>
</div>
