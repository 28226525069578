import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api-service.service';
import { CookieService } from 'ngx-cookie-service';
import { COOKIES, IMAGES, PAGES, ROLES, ROUTES } from 'src/app/types/types';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/templates/modals/modal/modal.component';
import { Gainer, GAINER_DEFAULT, LoginResponse } from 'src/app/types/api.types';
import { Store } from '@ngrx/store';
import { ACADEMY_DEFAULT } from 'src/app/types/academy.types';
import { Router } from '@angular/router';
import { Favorite } from 'src/app/types/state.types';
import { SetMenuAction } from '../actions/menu/set-menu,action';
import { LoginEffectAction, LOGIN_EFFECT } from '../actions/auth/login-effect.action';
import { SetStepAction } from '../actions/auth/set-step.action';
import { SetEmailAction } from '../actions/auth/set-email.action';
import { SetRoleAction } from '../actions/auth/set-role,action';
import { GetOwnAcademyEffectAction } from '../actions/auth/get-own-academy-effect.action';
import { GetGainerInfoEffect, GET_GAINER_INFO_EFFECT } from '../actions/auth/get-gainer-info-effect.action';
import { SetGainerInfo } from '../actions/auth/set-gainer-info.action';
import { UPDATE_GAINER_INFO_EFFECT, UpdateGainerInfoEffect } from '../actions/auth/update-gainer-info-effect.action';
import { CHECK_EMAIL_EFFECT_ACTION, CheckEmailEffectAction } from '../actions/auth/check-email-effect.action';
import { FORGOT_EFFECT, ForgotEffectAction } from '../actions/auth/forgot-effect.action';
import { ForgotResponseAction } from '../actions/auth/forgot-response,action';
import { REGISTRATION_EFFECT, RegisterEffectAction } from '../actions/auth/register-effect-action';
import { GAINER_REGISTRATION_EFFECT, RegisterGainerEffectAction } from '../actions/auth/register-gainer-effect.action';
import { ChangePasswordEffectAction, CHANGE_PASSWORD_EFFECT } from '../actions/auth/change-password-effect.action';
import { SetAccessTokenAction } from '../actions/auth/set-access-token.action';
import { LOGOUT } from '../actions/auth/logout-effect.action';
import { SetOwnAcademyAction } from '../actions/auth/set-own-academy.action';
import { SetIsApprovedAction } from '../actions/auth/set-is-approved.action';
import { ApproveAcademyEffectAction, APPROVE_ACADEMY_EFFECT_ACTION } from '../actions/auth/approve-academy-effect.action';
import { RESTORE_EFFECT, RestoreEffectAction } from '../actions/auth/restore-effect.action';
import { RestoreResponseAction } from '../actions/auth/restore-response.action';
import { GetFavoriteAcademies, GET_FAVORITES_EFFECT } from '../actions/auth/get-favorite-academies-effect-action';
import { SetFavoriteAcademies } from '../actions/auth/set-vaforite-academies.action';
import { ADD_TO_FAVORITES_EFFECT, AddToFavoriteAcademies } from '../actions/auth/add-to-favorites-academies-effect.action';
import { DeleteFromFavoriteAcademies, DELETE_FROM_FAVORITES_EFFECT } from '../actions/auth/delete-from-favorites-academies-effect.action';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private api: ApiService,
        private cookie: CookieService,
        private store: Store,
        private dialog: MatDialog,
        private router: Router
    ) {}

    public login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOGIN_EFFECT),
            mergeMap((action: LoginEffectAction) =>
                this.api.login(action.request).pipe(
                    map((res: LoginResponse) => {
                        switch (res.role) {
                            case ROLES.ACADEMY: {
                                if (!res.is_approved) {
                                    if (res.token) {
                                        this.dialog
                                            .open(ModalComponent, {
                                                panelClass: 'modal__error',
                                                data: {
                                                    message: 'ERRORS.NOT_APPROVED',
                                                    confirm: 'OK',
                                                    image: IMAGES.ERROR
                                                }
                                            })
                                            .afterClosed()
                                            .subscribe(() => {
                                                this.store.dispatch(new SetStepAction(1));
                                                this.store.dispatch(
                                                    new SetMenuAction({
                                                        page: PAGES.REGISTER,
                                                        open: true
                                                    })
                                                );
                                            });
                                    } else {
                                        this.dialog.open(ModalComponent, {
                                            panelClass: 'modal__error',
                                            data: {
                                                message: 'ERRORS.DECLINED',
                                                confirm: 'OK',
                                                image: IMAGES.ERROR
                                            }
                                        });

                                        return new SetMenuAction({
                                            page: '',
                                            open: false
                                        });
                                    }
                                }

                                this.store.dispatch(new SetIsApprovedAction(res.is_approved));
                                this.store.dispatch(new SetRoleAction(ROLES.ACADEMY));
                                this.store.dispatch(new SetAccessTokenAction(res.token));
                                this.store.dispatch(new GetOwnAcademyEffectAction());
                                break;
                            }
                            case ROLES.GAINER: {
                                this.store.dispatch(new SetRoleAction(ROLES.GAINER));
                                this.store.dispatch(new SetIsApprovedAction(true));
                                this.store.dispatch(new SetAccessTokenAction(res.token));
                                this.store.dispatch(new GetGainerInfoEffect());
                                break;
                            }
                            default: {
                                break;
                            }
                        }

                        this.cookie.delete(COOKIES.TOKEN);
                        this.cookie.set(COOKIES.TOKEN, res.token);

                        this.cookie.delete(COOKIES.EMAIL);
                        this.cookie.set(COOKIES.EMAIL, action.request.email);

                        this.store.dispatch(new SetEmailAction(action.request.email));

                        return new SetMenuAction({
                            page: '',
                            open: false
                        });
                    })
                )
            )
        )
    );

    public getGainerInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_GAINER_INFO_EFFECT),
            mergeMap(() =>
                this.api.getGainerInfo().pipe(
                    map((res: Gainer) => {
                        this.cookie.delete(COOKIES.GAINER);
                        this.cookie.set(COOKIES.GAINER, JSON.stringify(res));
                        return new SetGainerInfo(res);
                    })
                )
            )
        )
    );

    public updateGainerInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_GAINER_INFO_EFFECT),
            debounceTime(500),
            mergeMap((action: UpdateGainerInfoEffect) =>
                this.api.updateGainerInfo(action.gainer).pipe(
                    map((res: Gainer) => {
                        this.cookie.delete(COOKIES.GAINER);
                        this.cookie.set(COOKIES.GAINER, JSON.stringify(res));
                        return new SetGainerInfo(res);
                    })
                )
            )
        )
    );

    public checkEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CHECK_EMAIL_EFFECT_ACTION),
            mergeMap((action: CheckEmailEffectAction) => this.api.checkEmail(action.email).pipe(map(() => new SetStepAction(1))))
        )
    );

    public forgot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FORGOT_EFFECT),
            mergeMap((action: ForgotEffectAction) =>
                this.api.restore(action.email).pipe(
                    map(() => {
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__success',
                            data: {
                                message: 'SUCCESS.RESTORE',
                                confirm: 'OK',
                                image: IMAGES.SUCCESS
                            }
                        });

                        this.store.dispatch(
                            new SetMenuAction({
                                page: '',
                                open: false
                            })
                        );

                        return new ForgotResponseAction();
                    })
                )
            )
        )
    );

    public registerAcademy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_EFFECT),
            mergeMap((action: RegisterEffectAction) =>
                this.api.academyRegistration(action.request).pipe(
                    map(() => {
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__success',
                            data: {
                                message: 'SUCCESS.REGISTER',
                                confirm: 'OK',
                                image: IMAGES.SUCCESS
                            }
                        });

                        this.store.dispatch(new SetStepAction(0));

                        return new SetMenuAction({
                            page: '',
                            open: false
                        });
                    })
                )
            )
        )
    );

    public registerGainer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GAINER_REGISTRATION_EFFECT),
            mergeMap((action: RegisterGainerEffectAction) =>
                this.api.gainerRegistration(action.request).pipe(
                    map(() => {
                        this.store.dispatch(new SetStepAction(0));
                        return new SetMenuAction({
                            page: '',
                            open: false
                        });
                    })
                )
            )
        )
    );

    public changePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CHANGE_PASSWORD_EFFECT),
            mergeMap((action: ChangePasswordEffectAction) =>
                this.api.changeCurrentPassword(action.request).pipe(
                    map(() => {
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__success',
                            data: {
                                message: 'SUCCESS.CHANGE_PASSWORD',
                                confirm: 'OK',
                                image: IMAGES.SUCCESS
                            }
                        });
                        return new SetMenuAction({
                            page: '',
                            open: false
                        });
                    })
                )
            )
        )
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOGOUT),
            mergeMap(() =>
                this.api.logout().pipe(
                    map(() => {
                        this.cookie.delete(COOKIES.TOKEN);
                        this.cookie.delete(COOKIES.EMAIL);
                        this.cookie.delete(COOKIES.ACADEMY);
                        this.cookie.delete(COOKIES.GAINER);

                        this.store.dispatch(new SetAccessTokenAction(''));
                        this.store.dispatch(new SetEmailAction(''));
                        this.store.dispatch(new SetOwnAcademyAction(ACADEMY_DEFAULT));
                        this.store.dispatch(new SetGainerInfo(GAINER_DEFAULT));
                        this.store.dispatch(new SetStepAction(0));
                        this.router.navigate([ROUTES.GAINER_LANDING]);
                        return new SetIsApprovedAction(false);
                    })
                )
            )
        )
    );

    public approve$ = createEffect(() =>
        this.actions$.pipe(
            ofType(APPROVE_ACADEMY_EFFECT_ACTION),
            mergeMap((action: ApproveAcademyEffectAction) => {
                return this.api.academyApprove(action.academy).pipe(
                    map(() => {
                        this.cookie.delete(COOKIES.TOKEN);
                        this.cookie.delete(COOKIES.EMAIL);
                        this.cookie.delete(COOKIES.ACADEMY);
                        this.store.dispatch(new SetStepAction(0));
                        this.store.dispatch(
                            new SetMenuAction({
                                page: '',
                                open: false
                            })
                        );

                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__success',
                            data: {
                                message: 'SUCCESS.APPROVE',
                                confirm: 'OK',
                                image: IMAGES.SUCCESS
                            }
                        });
                        return new SetAccessTokenAction('');
                    })
                );
            })
        )
    );

    public restore$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RESTORE_EFFECT),
            mergeMap((action: RestoreEffectAction) =>
                this.api.restoreConfirm(action.request).pipe(
                    map(() => {
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__success',
                            data: {
                                message: 'SUCCESS.RESTORE_REQUEST',
                                confirm: 'OK',
                                image: IMAGES.SUCCESS
                            }
                        });

                        this.store.dispatch(
                            new SetMenuAction({
                                page: '',
                                open: false
                            })
                        );

                        return new RestoreResponseAction();
                    })
                )
            )
        )
    );

    public getFavorites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_FAVORITES_EFFECT),
            mergeMap(() =>
                this.api.getFavorites().pipe(
                    map((favorites: Array<Favorite>) => {
                        return new SetFavoriteAcademies(favorites);
                    })
                )
            )
        )
    );

    public addToFavorites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ADD_TO_FAVORITES_EFFECT),
            debounceTime(500),
            mergeMap((action: AddToFavoriteAcademies) =>
                this.api.addToFavorites(action.id).pipe(
                    map(() => {
                        return new GetFavoriteAcademies();
                    })
                )
            )
        )
    );

    public removeFromFavorites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DELETE_FROM_FAVORITES_EFFECT),
            debounceTime(500),
            mergeMap((action: DeleteFromFavoriteAcademies) =>
                this.api.deleteFromFavorites(action.id).pipe(
                    map(() => {
                        return new GetFavoriteAcademies();
                    })
                )
            )
        )
    );
}
