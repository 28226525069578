<section class="filter__container">
    <section class="filter__actions">
        <div class="filter__location">
            <h4>
                {{ location.text }}
            </h4>
        </div>

        <mat-form-field>
            <mat-label>
                <span lang key="SEARCH.SEARCH"></span>
            </mat-label>
            <input class="filter__search" type="text" sanitize [(ngModel)]="name" matInput />
            <span matPrefix>
                <mat-icon class="icon__search" (click)="filter(true)">{{ ICONS.SEARCH }}</mat-icon>
            </span>
        </mat-form-field>

        <section class="filter__buttons">
            <button mat-button class="filter__button--clear" (click)="clear()">
                <span lang key="ACTIONS.CLEAR"></span>
            </button>
            <button mat-button class="filter__button" (click)="filter(true)">
                <span lang key="SEARCH.FILTER"></span>
            </button>
        </section>
    </section>
    <section class="scroll filter__params">
        <div [ngSwitch]="type">
            <section *ngSwitchCase="TYPES.COACH">
                <app-skills
                    [expanded]="true"
                    [clean]="cleanSkills"
                    [filter]="type"
                    [header]="true"
                    (selected)="selectedSkills($event)"
                ></app-skills>
            </section>
            <section *ngSwitchCase="TYPES.GYM">
                <mat-accordion class="filter-sports filter__price">
                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h4 class="margin__bottom--0 margin__top--5">
                                    <span lang key="SEARCH.PRICE_RANGE"></span>
                                </h4>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="filter-sports__dropdown" *ngFor="let price of filterPrice">
                            <mat-checkbox [(ngModel)]="price.model" (ngModelChange)="switchPrice(price)" class="filter__checkbox">{{
                                price.title
                            }}</mat-checkbox>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <app-skills [clean]="cleanSkills" [filter]="type" [header]="true" (selected)="selectedSkills($event)"></app-skills>

                <mat-accordion class="filter__amenities">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h4>
                                    <span lang key="ACADEMY.AMENITIES"></span>
                                </h4>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="filter__amenities--dropdown" *ngFor="let amenity of amenities">
                            <div [ngSwitch]="amenity.type">
                                <div *ngSwitchCase="TYPES.GYM">
                                    <mat-checkbox
                                        [(ngModel)]="amenity.model"
                                        (ngModelChange)="switchAmenity(amenity)"
                                        class="filter__checkbox"
                                    >
                                        <div class="amenity">
                                            <i class=" academy__amenity--icon icon__amenity--{{ amenity.id }}"></i>
                                            <span>{{ amenity.name }}</span>
                                        </div>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </section>

            <section *ngSwitchCase="TYPES.SPORTS_CLUB">
                <app-skills [clean]="cleanSkills" [filter]="type" [header]="true" (selected)="selectedSkills($event)"></app-skills>

                <mat-accordion class="filter__amenities">
                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h4>
                                    <span lang key="ACADEMY.AMENITIES"></span>
                                </h4>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="filter__amenities--dropdown" *ngFor="let amenity of amenities">
                            <div [ngSwitch]="amenity.type">
                                <div *ngSwitchCase="TYPES.SPORTS_CLUB">
                                    <mat-checkbox
                                        [(ngModel)]="amenity.model"
                                        (ngModelChange)="switchAmenity(amenity)"
                                        class="filter__checkbox"
                                    >
                                        <div class="amenity">
                                            <i class=" academy__amenity--icon icon__amenity--{{ amenity.id }}"></i>
                                            <span>{{ amenity.name }}</span>
                                        </div>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </section>

            <section *ngSwitchCase="TYPES.SOMETHING_NEW">
                <mat-accordion class="filter-sports filter__price">
                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h4 class="margin__bottom--0 margin__top--5">
                                    <span lang key="SEARCH.PRICE_RANGE"></span>
                                </h4>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="filter-sports__dropdown" *ngFor="let price of filterPrice">
                            <mat-checkbox [(ngModel)]="price.model" (ngModelChange)="switchPrice(price)" class="filter__checkbox">
                                {{ price.title }}
                            </mat-checkbox>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <app-skills [clean]="cleanSkills" [filter]="type" [header]="true" (selected)="selectedSkills($event)"></app-skills>

                <mat-accordion class="filter__amenities">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h4>
                                    <span lang key="ACADEMY.AMENITIES"></span>
                                </h4>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="filter__amenities--dropdown" *ngFor="let amenity of amenities">
                            <div [ngSwitch]="amenity.type">
                                <div *ngSwitchCase="TYPES.SPORTS_CLUB">
                                    <mat-checkbox
                                        [(ngModel)]="amenity.model"
                                        (ngModelChange)="switchAmenity(amenity)"
                                        class="filter__checkbox"
                                    >
                                        <div class="amenity">
                                            <i class=" academy__amenity--icon icon__amenity--{{ amenity.id }}"></i>
                                            <span>{{ amenity.name }}</span>
                                        </div>
                                    </mat-checkbox>
                                </div>
                                <div *ngSwitchCase="TYPES.GYM">
                                    <mat-checkbox
                                        [(ngModel)]="amenity.model"
                                        (ngModelChange)="switchAmenity(amenity)"
                                        class="filter__checkbox"
                                    >
                                        <div class="amenity">
                                            <i class=" academy__amenity--icon icon__amenity--{{ amenity.id }}"></i>
                                            <span>{{ amenity.name }}</span>
                                        </div>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </section>
        </div>
    </section>
</section>
