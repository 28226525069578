import { Action } from '@ngrx/store';
import { Pagination } from 'src/app/types/academy.types';

export const SET_ACADEMIES_PAGINATION = '[ACADEMY] set pagination info';

export class SetAcademiesPaginationAction implements Action {
    readonly type = SET_ACADEMIES_PAGINATION;

    constructor(public pagination: Pagination) {}
}
