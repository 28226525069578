<section class="account">
    <section class="account__settings">
        <h1><span lang key="MENU.ACCOUNT_SETTINGS"></span></h1>

        <mat-form-field>
            <mat-label>
                <span lang key="MENU.YOUR_EMAIL"></span>
                <i class="icon__success"></i>
            </mat-label>
            <input disabled [value]="email" matInput />
        </mat-form-field>

        <mat-form-field>
            <mat-label>
                <span lang key="MENU.YOUR_PASSWORD"></span>
                <i class="icon__success"></i>
            </mat-label>
            <input disabled value="********" matInput />
        </mat-form-field>

        <button mat-raised-button class="account__button" (click)="changePassword()">
            <span lang key="ACTIONS.CHANGE_PASSWORD"></span>
        </button>

        <section [ngSwitch]="role" class="account__subscriptions">
            <div class="account__subscriptions--academy" *ngSwitchCase="ROLES.ACADEMY">
                <h1><span lang key="MENU.SUBSCRIPTIONS"></span></h1>
                <div class="account__subscription">
                    <div class="account__subscription--text">
                        <span class="account__subscription--title" lang key="MENU.NEW_REVIEWS"></span>
                        <span class="account__subscription--subtitle" lang key="MENU.REVIEW_EMAIL"></span>
                    </div>
                    <mat-slide-toggle
                        (change)="changeSubscription($event, 'is_new_review_added_subscribe')"
                        class="checkbox"
                        [(ngModel)]="subscriptions.is_new_review_added_subscribe"
                    ></mat-slide-toggle>
                </div>
                <div class="account__subscription">
                    <div class="account__subscription--text">
                        <span class="account__subscription--title" lang key="MENU.PROFILE_UPDATES"></span>
                        <span class="account__subscription--subtitle" lang key="MENU.ADMIN_EDIT"></span>
                    </div>
                    <mat-slide-toggle
                        (change)="changeSubscription($event, 'is_profile_update_subscribe')"
                        class="checkbox"
                        [(ngModel)]="subscriptions.is_profile_update_subscribe"
                    ></mat-slide-toggle>
                </div>

                <div class="account__subscription">
                    <div class="account__subscription--text">
                        <span class="account__subscription--title" lang key="MENU.WEEKLY_DEIGEST"></span>
                        <span class="account__subscription--subtitle" lang key="MENU.PERFORMANCE"></span>
                    </div>
                    <mat-slide-toggle
                        (change)="changeSubscription($event, 'is_get_digest_subscribe')"
                        class="checkbox"
                        [(ngModel)]="subscriptions.is_get_digest_subscribe"
                    ></mat-slide-toggle>
                </div>
            </div>
        </section>
    </section>
    <app-footer></app-footer>
</section>
