import { Action } from '@ngrx/store';
import { Academy } from 'src/app/types/academy.types';

export const SET_ACADEMIES = '[ACADEMIES] set academy list';

export class SetAcademiesAction implements Action {
    readonly type = SET_ACADEMIES;

    constructor(public academies: Array<Academy>) {}
}
