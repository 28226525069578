import { Action } from '@ngrx/store';
import { Academy } from 'src/app/types/academy.types';

export const APPEND_ACADEMIES = '[ACADEMIES] append academies';

export class AppendAcademiesAction implements Action {
    readonly type = APPEND_ACADEMIES;

    constructor(public academies: Array<Academy>) {}
}
