import { Action } from '@ngrx/store';
import { Amenity } from 'src/app/types/amenity.types';

export const SET_OWN_AMENITIES = '[AUTH] set own amenities';

export class SetOwnAmenitiesAction implements Action {
    readonly type = SET_OWN_AMENITIES;

    constructor(public amenities: Array<Amenity>) {}
}
