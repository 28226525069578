import { Action } from '@ngrx/store';
import { ChangePasswordRequest } from 'src/app/types/api.types';

export const CHANGE_PASSWORD_EFFECT = '[ACCOUNT SETTINGS] change password effect';

export class ChangePasswordEffectAction implements Action {
    readonly type = CHANGE_PASSWORD_EFFECT;

    constructor(public request: ChangePasswordRequest) {}
}
