<mat-sidenav-container class="menu__container" [hasBackdrop]="true">
    <mat-sidenav class="menu" [page]="menu.page" [(opened)]="menu.open" position="end" click-outside (outsideClick)="close(true)">
        <mat-icon (click)="close(false)" class="menu__close">{{ ICONS.CLOSE }}</mat-icon>
        <div [ngSwitch]="menu.page" class="menu__content">
            <div *ngSwitchCase="PAGES.PROFILE">
                <app-menu></app-menu>
            </div>

            <div *ngSwitchCase="PAGES.LOGIN">
                <app-login></app-login>
            </div>

            <div *ngSwitchCase="PAGES.REGISTER">
                <app-register></app-register>
            </div>

            <div *ngSwitchCase="PAGES.FORGOT">
                <app-forgot></app-forgot>
            </div>

            <div *ngSwitchCase="PAGES.RESTORE">
                <app-restore></app-restore>
            </div>

            <div *ngSwitchCase="PAGES.PASSWORD">
                <app-password></app-password>
            </div>

            <div *ngSwitchDefault></div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>
