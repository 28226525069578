import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/core/services/device.service';
import { IMAGES, REGEXP } from 'src/app/types/types';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
    public isGainer: boolean = true;

    public isMobile: boolean = false;

    public IMAGES: Record<string, string> = { ...IMAGES };

    constructor(private router: Router, private device: DeviceService) {
        this.isMobile = this.device.isMobile();
        this.isGainer = new RegExp(REGEXP.GAINER_LANDING_ROUTE).test(this.router.url);
    }

    public ngOnInit(): void {
        this.router.events.subscribe(() => {
            this.isGainer = new RegExp(REGEXP.GAINER_LANDING_ROUTE).test(this.router.url);
        });
    }
}
