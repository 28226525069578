import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Page, Role, ROLES, Roles, ROUTES } from 'src/app/types/types';
import { Academy, ACADEMY_DEFAULT } from 'src/app/types/academy.types';
import { Gainer } from 'src/app/types/api.types';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackComponent } from 'src/app/templates/modals/feedback/feedback.component';
import { SetMenuAction } from 'src/app/store/actions/menu/set-menu,action';
import { LogOutEffect } from 'src/app/store/actions/auth/logout-effect.action';
import { EN_CONFIG, LangConfig } from 'src/app/types/lang.types';
import { getGainerInfo, getOwnAcademy, getRole, isAuthorized } from '../../store/selectors/auth.selectors';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    public EN_CONFIG: LangConfig = { ...EN_CONFIG };

    public authorized$: Observable<boolean>;

    public role: Role = ROLES.GAINER;

    public name: string = '';

    public confirmLogout: boolean = false;

    public academy: Academy = { ...ACADEMY_DEFAULT };

    public ROLES: Roles = { ...ROLES };

    public ROUTES: Record<string, string> = { ...ROUTES };

    constructor(private store: Store, private router: Router, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.authorized$ = this.store.select(isAuthorized);

        this.store.select(getRole).subscribe((role: Role) => {
            this.role = role;
            switch (role) {
                case ROLES.ACADEMY: {
                    this.store
                        .select(getOwnAcademy)
                        .subscribe((academy: Academy) => {
                            this.academy = academy;
                        })
                        .unsubscribe();
                    break;
                }
                case ROLES.GAINER: {
                    this.store.select(getGainerInfo).subscribe((gainer: Gainer) => {
                        this.name = gainer.name;
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }

    public logout(): void {
        this.confirmLogout = false;
        this.store.dispatch(new LogOutEffect());
        this.store.dispatch(
            new SetMenuAction({
                page: '',
                open: false
            })
        );
    }

    public feedback(): void {
        this.dialog.open(FeedbackComponent, {
            panelClass: 'feedback-modal',
            disableClose: true
        });
    }

    public open(route: string, params?: Record<string, unknown>): void {
        this.store.dispatch(
            new SetMenuAction({
                page: '',
                open: false
            })
        );

        this.router.navigate([`/${route}`], { queryParams: { ...params } });
    }

    public page(page: Page): void {
        this.store.dispatch(
            new SetMenuAction({
                page,
                open: true
            })
        );
    }
}
