import { Action } from '@ngrx/store';
import { Document } from 'src/app/types/document.types';

export const SET_DOCUMENT = '[DOCUMENTS] set document by slug';

export class SetDocumentAction implements Action {
    readonly type = SET_DOCUMENT;

    constructor(public document: Document) {}
}
