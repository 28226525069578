<section class="register--container">
    <div class="register">
        <h1>
            <div [ngSwitch]="role">
                <div *ngSwitchCase="ROLES.GAINER"><span lang key="AUTH.REGISTER_GAINER_TITLE"></span></div>
                <div *ngSwitchCase="ROLES.ACADEMY"><span lang key="AUTH.REGISTER_ACADEMY_TITLE"></span></div>
            </div>
        </h1>

        <div [ngSwitch]="role">
            <div *ngSwitchCase="ROLES.GAINER">
                <section class="register__form--change-role">
                    <span lang key="AUTH.JOIN_ACADEMY"></span>
                    <button (click)="changeRole(ROLES.ACADEMY)">
                        <span lang key="ACTIONS.REGISTER_HERE"></span>
                    </button>
                </section>
            </div>
            <div *ngSwitchCase="ROLES.ACADEMY">
                <section class="register__form--change-role">
                    <span lang key="AUTH.JOIN_GAINER"></span>
                    <button (click)="changeRole(ROLES.GAINER)">
                        <span lang key="ACTIONS.REGISTER_HERE"></span>
                    </button>
                </section>
            </div>
        </div>

        <h2>
            <div [ngSwitch]="role">
                <div *ngSwitchCase="ROLES.GAINER"><span lang key="AUTH.REGISTER_GAINER_SUB_TITLE"></span></div>
                <div *ngSwitchCase="ROLES.ACADEMY"><span lang key="AUTH.REGISTER_ACADEMY_SUB_TITLE"></span></div>
            </div>
        </h2>

        <form class="register__form" [formGroup]="registerForm">
            <mat-form-field *ngIf="role === ROLES.ACADEMY">
                <mat-chip-list formControlName="type" class="register__types">
                    <mat-chip [class.selected]="t === registerForm.controls.type.value" (click)="changeType(t)" *ngFor="let t of types">
                        {{ t }}
                    </mat-chip>
                </mat-chip-list>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="AUTH.EMAIL"></span>
                    <i *ngIf="!registerForm.controls.email.invalid" class="icon__success"></i>
                </mat-label>
                <input
                    sanitize
                    [class.error]="registerForm.controls.email.invalid && registerForm.controls.email.touched"
                    type="email"
                    matInput
                    formControlName="email"
                />
                <mat-error *ngIf="registerForm.controls.email.invalid">
                    <span lang key="ERRORS.EMAIL"></span>
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>
                    <span lang key="AUTH.PASSWORD"></span>
                    <i *ngIf="!registerForm.controls.password.invalid" class="icon__success"></i>
                </mat-label>
                <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
                <input
                    (input)="
                        registerForm.controls.repeatPassword.setValue(registerForm.controls.repeatPassword.value.trim());
                        showStrength = true
                    "
                    [class.error]="registerForm.controls.password.invalid && registerForm.controls.password.touched"
                    matInput
                    #password
                    formControlName="password"
                    [type]="toggle.type"
                />
                <mat-hint align="end" aria-live="polite"> {{ password.value.length }} / {{ passwordComponent.max }} </mat-hint>
            </mat-form-field>

            <mat-password-strength #passwordComponent [password]="password.value"> </mat-password-strength>

            <mat-password-strength-info *ngIf="showStrength" [passwordComponent]="passwordComponent" customCharsCriteriaMsg="">
            </mat-password-strength-info>

            <mat-form-field>
                <mat-label>
                    <span lang key="AUTH.PASSWORD_REPEAT"></span>
                    <i *ngIf="!registerForm.controls.repeatPassword.invalid" class="icon__success"></i>
                </mat-label>
                <input
                    sanitize
                    [class.error]="registerForm.controls.repeatPassword.invalid && registerForm.controls.repeatPassword.touched"
                    [type]="hideConfirmPassword ? 'password' : 'text'"
                    matInput
                    formControlName="repeatPassword"
                />
                <button
                    mat-icon-button
                    matSuffix
                    (click)="hideConfirmPassword = !hideConfirmPassword"
                    [attr.aria-pressed]="hideConfirmPassword"
                >
                    <mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="registerForm.controls.repeatPassword.invalid">
                    <span class="register__confirm-password--error" lang key="ERRORS.REPEAT_PASSWORD"></span>
                </mat-error>
            </mat-form-field>

            <mat-checkbox required formControlName="agreeTerms" class="register__terms">
                <span class="register__terms--title" lang key="AUTH.TERMS"></span>
                <a
                    target="_blank"
                    rel="noreferer noopener"
                    class="register__terms--button"
                    [routerLink]="[ROUTES.DOCUMENTS]"
                    [queryParams]="{ slug: 'terms-of-use', page: PAGES.REGISTER, role: role }"
                >
                    <span lang key="ACTIONS.TERMS_OF_USE"></span>
                </a>
            </mat-checkbox>

            <button
                [disabled]="
                    registerForm.controls.password.invalid ||
                    registerForm.controls.repeatPassword.invalid ||
                    registerForm.controls.email.invalid ||
                    registerForm.controls.agreeTerms.invalid
                "
                analytics
                [type]="ANALYTIC_TYPES.CREATE_ACCOUNT_CLICKED"
                [label]="
                    CREATE_ACCOUNT_CLICKED.label(
                        role === ROLES.ACADEMY ? registerForm.controls.type.value : ROLES.GAINER,
                        registerForm.controls.email.value
                    )
                "
                mat-raised-button
                class="register__button"
                (click)="nextStep()"
            >
                <span lang key="ACTIONS.CREATE_ACCOUNT"></span>
            </button>

            <section class="register__form--login">
                <span lang key="AUTH.HAVE_ACCOUNT"></span>
                <button (click)="setMenuConfig('LOGIN', true)">
                    <span lang key="ACTIONS.LOGIN_HERE"></span>
                </button>
            </section>
        </form>
    </div>
</section>
