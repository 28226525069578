import { Action } from '@ngrx/store';
import { ReviewsRequest } from 'src/app/types/api.types';

export const LOAD_MORE_ACADEMY_REVIEWS_EFFECT = '[REVIEWS] load more effect';

export class LoadMoreAcademyReviewsEffect implements Action {
    readonly type = LOAD_MORE_ACADEMY_REVIEWS_EFFECT;

    constructor(public request: ReviewsRequest) {}
}
