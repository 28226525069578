import { EN_CONFIG } from 'src/app/types/lang.types';
import { REGEXP } from 'src/app/types/types';

export type AnalyticType =
    | 'search_performed'
    | 'academy_type_selected'
    | 'filter_selected'
    | 'academy_profile_opened'
    | 'personal_website_clicked'
    | 'instagram_clicked'
    | 'sign_up_clicked'
    | 'create_account_clicked'
    | 'save_profile_clicked'
    | 'default';

export type SignUpLabel =
    | 'gainer_landing_header'
    | 'academy_landing_header'
    | 'schedule_popup'
    | 'academy_reviews'
    | 'search_page'
    | 'academy_profile_header';

export interface AnalyticTypes {
    [key: string]: AnalyticType;
}

export interface SignUpLabels {
    [key: string]: SignUpLabel;
}

export interface AnalyticConfig {
    action: string;
    category: AnalyticType;
    label?: (...args: string[]) => string;
}

export const SIGN_UP_LABELS: SignUpLabels = {
    GAINER_LANDING_HEADER: 'gainer_landing_header',
    ACADEMY_LANDING_HEADER: 'academy_landing_header',
    SCHEDULE_POPUP: 'schedule_popup',
    ACADEMY_REVIEWS: 'academy_reviews',
    SEARCH_PAGE: 'search_page',
    ACADEMY_PROFILE_HEADER: 'academy_profile_header'
};

export const ANALYTIC_TYPES: AnalyticTypes = {
    SEARCH_PERFORMED: 'search_performed',
    ACADEMY_TYPE_SELECTED: 'academy_type_selected',
    FILTER_SELECTED: 'filter_selected',
    ACADEMY_PROFILE_OPENED: 'academy_profile_opened',
    PERSONAL_WEBSITE_CLICKED: 'personal_website_clicked',
    INSTAGRAM_CLICKED: 'instagram_clicked',
    SIGN_UP_CLICKED: 'sign_up_clicked',
    CREATE_ACCOUNT_CLICKED: 'create_account_clicked',
    SAVE_PROFILE_CLICKED: 'save_profile_clicked'
};

export const SEARCH_PERFORMED: AnalyticConfig = {
    action: EN_CONFIG.ANALYTICS.SEARCH_PERFORMED,
    category: ANALYTIC_TYPES.SEARCH_PERFORMED
};

export const ACADEMY_TYPE_SELECTED: AnalyticConfig = {
    action: EN_CONFIG.ANALYTICS.ACADEMY_TYPE_SELECTED,
    category: ANALYTIC_TYPES.ACADEMY_TYPE_SELECTED
};

export const FILTER_SELECTED: AnalyticConfig = {
    action: EN_CONFIG.ANALYTICS.FILTER_SELECTED,
    category: ANALYTIC_TYPES.FILTER_SELECTED
};

export const ACADEMY_PROFILE_OPENED: AnalyticConfig = {
    action: EN_CONFIG.ANALYTICS.ACADEMY_PROFILE_OPENED,
    category: ANALYTIC_TYPES.ACADEMY_PROFILE_OPENED
};

export const PERSONAL_WEBSITE_CLICKED: AnalyticConfig = {
    action: EN_CONFIG.ANALYTICS.PERSONAL_WEBSITE_CLICKED,
    category: ANALYTIC_TYPES.PERSONAL_WEBSITE_CLICKED
};

export const INSTAGRAM_CLICKED: AnalyticConfig = {
    action: EN_CONFIG.ANALYTICS.INSTAGRAM_CLICKED,
    category: ANALYTIC_TYPES.INSTAGRAM_CLICKED
};

export const SIGN_UP_CLICKED: AnalyticConfig = {
    action: EN_CONFIG.ANALYTICS.SIGN_UP,
    category: ANALYTIC_TYPES.SIGN_UP_CLICKED,
    label: (url) => {
        if (new RegExp(REGEXP.GAINER_LANDING_ROUTE).test(url)) {
            return SIGN_UP_LABELS.GAINER_LANDING_HEADER;
        }

        if (new RegExp(REGEXP.ACADEMY_LANDING_ROUTE).test(url)) {
            return SIGN_UP_LABELS.ACADEMY_LANDING_HEADER;
        }

        if (new RegExp(REGEXP.SEARCH_ROUTE).test(url)) {
            return SIGN_UP_LABELS.SEARCH_PAGE;
        }

        if (new RegExp(REGEXP.ACADEMY_PROFILE_ROUTE).test(url)) {
            return SIGN_UP_LABELS.ACADEMY_PROFILE_HEADER;
        }

        return '';
    }
};

export const CREATE_ACCOUNT_CLICKED: AnalyticConfig = {
    action: EN_CONFIG.ANALYTICS.CREATE_ACCOUNT_CLICKED,
    category: ANALYTIC_TYPES.CREATE_ACCOUNT_CLICKED,
    label: (role, email) => `role: ${role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()}, email: ${email}`
};

export const SAVE_PROFILE_CLICKED: AnalyticConfig = {
    action: EN_CONFIG.ANALYTICS.SAVE_PROFILE_CLICKED,
    category: ANALYTIC_TYPES.SAVE_PROFILE_CLICKED,
    label: (role, email) => `role: ${role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()}, email: ${email}`
};

export const ANALYTIC_CONFIG_DEFAULT: AnalyticConfig = {
    action: EN_CONFIG.ANALYTICS.ANALYTIC_CONFIG_DEFAULT,
    category: 'default'
};

export const ANALYTICS: Map<AnalyticType, AnalyticConfig> = new Map([
    [ANALYTIC_TYPES.SEARCH_PERFORMED, SEARCH_PERFORMED],
    [ANALYTIC_TYPES.ACADEMY_TYPE_SELECTED, ACADEMY_TYPE_SELECTED],
    [ANALYTIC_TYPES.FILTER_SELECTED, FILTER_SELECTED],
    [ANALYTIC_TYPES.ACADEMY_PROFILE_OPENED, ACADEMY_PROFILE_OPENED],
    [ANALYTIC_TYPES.PERSONAL_WEBSITE_CLICKED, PERSONAL_WEBSITE_CLICKED],
    [ANALYTIC_TYPES.INSTAGRAM_CLICKED, INSTAGRAM_CLICKED],
    [ANALYTIC_TYPES.SIGN_UP_CLICKED, SIGN_UP_CLICKED],
    [ANALYTIC_TYPES.CREATE_ACCOUNT_CLICKED, CREATE_ACCOUNT_CLICKED],
    [ANALYTIC_TYPES.SAVE_PROFILE_CLICKED, SAVE_PROFILE_CLICKED]
]);
