import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { State } from 'src/app/types/state.types';

import { locationReducer } from 'src/app/store/reducers/location.reducer';
import { authReducer } from 'src/app/store/reducers/auth.reducer';
import { MenuReducer } from 'src/app/store/reducers/menu.reducer';
import { academyReducer } from './reducers/academy.reducer';
import { amenityReducer } from './reducers/amenity.reducer';
import { documentReducer } from './reducers/documents.reducer';
import { skillsReducer } from './reducers/skills.reducer';
import { reviewsReducer } from './reducers/reviews.reducer';

export const reducers: ActionReducerMap<State> = {
    auth: authReducer,
    location: locationReducer,
    menu: MenuReducer,
    academy: academyReducer,
    amenity: amenityReducer,
    document: documentReducer,
    skills: skillsReducer,
    reviews: reviewsReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
