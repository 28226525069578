import { Action } from '@ngrx/store';
import { Feedback } from 'src/app/types/types';

export const SEND_FEEDBACK_EFFECT = '[FEEDBACK] create effect';

export class SendFeedbackEffect implements Action {
    readonly type = SEND_FEEDBACK_EFFECT;

    constructor(public feedback: Feedback) {}
}
