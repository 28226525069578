import { Action } from '@ngrx/store';
import { RegisterGainerRequest } from 'src/app/types/api.types';

export const GAINER_REGISTRATION_EFFECT = '[REGISTER] gainer effect';

export class RegisterGainerEffectAction implements Action {
    readonly type = GAINER_REGISTRATION_EFFECT;

    constructor(public request: RegisterGainerRequest) {}
}
