import { Action } from '@ngrx/store';
import { Favorite } from 'src/app/types/state.types';

export const SET_FAVORITES = '[GAINER PROFILE] set favorite academies list [effect response]';

export class SetFavoriteAcademies implements Action {
    readonly type = SET_FAVORITES;

    constructor(public favorites: Array<Favorite>) {}
}
