<section class="forgot--container">
    <div class="forgot">
        <h1>
            <span lang key="AUTH.FORGOT"></span>
        </h1>
        <h2>
            <span lang key="AUTH.RESTORE_PASSWORD"></span>
        </h2>
        <form class="forgot__form" [formGroup]="forgotForm">
            <mat-form-field>
                <mat-label><span lang key="AUTH.EMAIL"></span></mat-label>
                <input
                    [class.error]="forgotForm.controls.email.invalid && forgotForm.touched"
                    type="email"
                    sanitize
                    matInput
                    formControlName="email"
                />
                <mat-error *ngIf="forgotForm.controls.email.invalid">
                    <span lang key="ERRORS.EMAIL"></span>
                </mat-error>
            </mat-form-field>
            <button (click)="forgot()" [disabled]="forgotForm.controls.email.invalid" mat-raised-button class="forgot__button">
                <span lang key="ACTIONS.RESTORE"></span>
            </button>
        </form>
    </div>
</section>
