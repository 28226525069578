import { Action } from '@ngrx/store';
import { Academy } from 'src/app/types/academy.types';

export const SET_OWN_ACADEMY = '[AUTH] set own academy';

export class SetOwnAcademyAction implements Action {
    readonly type = SET_OWN_ACADEMY;

    constructor(public academy: Academy) {}
}
