import { AcademyState, SELECTORS } from 'src/app/types/state.types';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
    getTypeState,
    getAcademiesState,
    getSelectedAcademyState,
    getFilterCountState,
    searchState,
    getFilterOpenState,
    getAcademyAmenitiesState,
    getAcademiesPaginationState,
    getFilterState,
    hasSocialLinksState
} from '../reducers/academy.reducer';

export const getAcademyState = createFeatureSelector<AcademyState>(SELECTORS.ACADEMY);

export const getType = createSelector(getAcademyState, getTypeState);

export const getAcademies = createSelector(getAcademyState, getAcademiesState);

export const getAcademy = createSelector(getAcademyState, getSelectedAcademyState);

export const getAcademyAmenities = createSelector(getAcademyState, getAcademyAmenitiesState);

export const getFilterCount = createSelector(getAcademyState, getFilterCountState);

export const getFilterOpenCount = createSelector(getAcademyState, getFilterOpenState);

export const searchEvent = createSelector(getAcademyState, searchState);

export const getAcademiesPagination = createSelector(getAcademyState, getAcademiesPaginationState);

export const getAcademiesFilter = createSelector(getAcademyState, getFilterState);

export const hasSocialLinks = createSelector(getAcademyState, hasSocialLinksState);
