import { getRole, isAuthorized } from 'src/app/store/selectors/auth.selectors';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { MenuConfig, PAGES, Page, REGEXP, ICONS, Role, ROLES, Roles, Pages, ROUTES } from 'src/app/types/types';
import { Store } from '@ngrx/store';
import { getMenu } from 'src/app/store/selectors/menu.selectors';
import { MatDialog } from '@angular/material/dialog';
import { SetMenuAction } from 'src/app/store/actions/menu/set-menu,action';
import { AnalyticConfig, SIGN_UP_CLICKED, AnalyticTypes, ANALYTIC_TYPES } from 'src/app/types/google-analytics.types';
import { FeedbackComponent } from '../modals/feedback/feedback.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public isHome: boolean = false;

    public isSearch: boolean = false;

    public isRestore: boolean = false;

    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public SIGN_UP_CLICKED: AnalyticConfig = { ...SIGN_UP_CLICKED };

    public ICONS: Record<string, string> = { ...ICONS };

    public ROLES: Roles = { ...ROLES };

    public PAGES: Pages = { ...PAGES };

    public role: Role = ROLES.GAINER;

    public isAuthorized$: Observable<boolean>;

    constructor(public router: Router, private store: Store, private dialog: MatDialog) {
        this.isAuthorized$ = this.store.select(isAuthorized);
        this.isHome = new RegExp(REGEXP.HOME_ROUTE).test(this.router.url);
        this.isSearch = new RegExp(REGEXP.SEARCH_ROUTE).test(this.router.url);
    }

    public ngOnInit(): void {
        this.store.select(getRole).subscribe((role: Role) => {
            this.role = role;
        });
        this.router.events.subscribe(() => {
            this.isHome = new RegExp(REGEXP.HOME_ROUTE).test(this.router.url);
            this.isSearch = new RegExp(REGEXP.SEARCH_ROUTE).test(this.router.url);

            this.isRestore = new RegExp(REGEXP.RESTORE_ROUTE).test(this.router.url);
            if (this.isRestore) {
                this.store.dispatch(
                    new SetMenuAction({
                        page: PAGES.RESTORE,
                        open: true
                    })
                );
                this.isHome = true;
            } else {
                this.store
                    .select(getMenu)
                    .subscribe((bar: MenuConfig) => {
                        if (bar.page === PAGES.RESTORE) {
                            this.store.dispatch(
                                new SetMenuAction({
                                    page: '',
                                    open: false
                                })
                            );
                        }
                    })
                    .unsubscribe();
            }
        });
    }

    public home(): void {
        this.router.navigate([ROUTES.GAINER_LANDING]);
        this.store.dispatch(
            new SetMenuAction({
                page: '',
                open: false
            })
        );
    }

    public favorites(): void {
        this.router.navigate([ROUTES.FAVORITES]);
    }

    public feedback(): void {
        this.dialog.open(FeedbackComponent, {
            panelClass: 'feedback-modal',
            disableClose: true
        });
    }

    public openMenuPage(page: Page): void {
        this.store.dispatch(
            new SetMenuAction({
                page,
                open: true
            })
        );
    }
}
