import { Component, Input } from '@angular/core';
import { WEEK_SCHEDULE_DEFAULT, WeekSchedule } from 'src/app/types/academy.types';

@Component({
    selector: 'app-schedule-week',
    templateUrl: './schedule-week.component.html',
    styleUrls: ['./schedule-week.component.scss']
})
export class ScheduleWeekComponent {
    @Input() schedule: WeekSchedule = WEEK_SCHEDULE_DEFAULT;
}
