<section class="skeleton" *ngIf="(academy$ | async).id === 0">
    <section class="academy__go-back">
        <mat-icon (click)="goBack()">{{ ICONS.BACK }}</mat-icon>
        <ngx-skeleton-loader count="1" animation="progress" appearance="circle" class="skeleton-button"></ngx-skeleton-loader>
    </section>

    <section class="academy__gallery">
        <ngx-skeleton-loader count="1" animation="progress" appearance="circle" class="skeleton-gallery--view"></ngx-skeleton-loader>
        <div class="academy__gallery--images">
            <ngx-skeleton-loader
                count="2"
                animation="progress"
                appearance="circle"
                class="skeleton-gallery--top-level"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
                count="2"
                animation="progress"
                appearance="circle"
                class="skeleton-gallery--bottom-level"
            ></ngx-skeleton-loader>
        </div>
    </section>

    <section class="academy__name">
        <ngx-skeleton-loader count="1" animation="progress" appearance="circle" class="skeleton-name"></ngx-skeleton-loader>
    </section>

    <section class="academy">
        <section class="academy__info">
            <ngx-skeleton-loader count="8" animation="progress" appearance="circle" class="skeleton-skills"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="circle" class="skeleton-description"></ngx-skeleton-loader>

            <ngx-skeleton-loader count="1" animation="progress" appearance="circle" class="skeleton-amenities--title"></ngx-skeleton-loader>

            <section class="academy__amenities">
                <ngx-skeleton-loader count="4" animation="progress" appearance="circle" class="skeleton-amenities"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="4" animation="progress" appearance="circle" class="skeleton-amenities"></ngx-skeleton-loader>
            </section>
        </section>

        <section class="academy__location">
            <ngx-skeleton-loader count="1" animation="progress" appearance="circle" class="skeleton-location"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="circle" class="skeleton-location--title"></ngx-skeleton-loader>
        </section>
    </section>
</section>

<section class="academy__container" *ngIf="(academy$ | async).id !== 0">
    <section class="academy__go-back">
        <mat-icon class="academy__go-back--icon" (click)="goBack()">{{ ICONS.BACK }}</mat-icon>
        <button *ngIf="role === ROLES.GAINER && authorized" (click)="addToFavorites()">
            <mat-icon [class.favorite]="favorite">{{ favorite ? 'favorite' : 'favorite_border' }}</mat-icon>
        </button>
    </section>

    <section class="academy__gallery">
        <img
            [class.academy__gallery--single]="(academy$ | async).gallery.length === 1"
            class="academy__gallery--view"
            (click)="openGallery(item.id)"
            src="{{ !!item.id ? IMAGE_PATH + item.name : item.name }}"
            *ngFor="let item of (academy$ | async).gallery.slice(0, 1)"
        />
        <div *ngIf="(academy$ | async).gallery.length > 1" class="academy__gallery--images">
            <img
                class="academy__gallery--image"
                [style.display]="item.id === 0 ? 'none' : 'flex'"
                (click)="openGallery(item.id)"
                src="{{ IMAGE_PATH + item.name }}"
                *ngFor="let item of (academy$ | async).gallery.slice(1, 5)"
            />
        </div>
    </section>

    <section class="academy__name">
        <span class="academy__name--text">{{ (academy$ | async).name }}</span>
        <span *ngIf="(academy$ | async).type === TYPES.GYM" class="academy__name--price">{{ (academy$ | async).price.title }}</span>
    </section>

    <section class="academy">
        <section class="academy__info">
            <section class="academy__skills" *ngIf="(academy$ | async).skills.length">
                <mat-chip-list>
                    <mat-chip class="skill__ship" *ngFor="let skill of (academy$ | async).skills" [color]="'#F0F3F5'">
                        <span class="skill__name">{{ skill.name }}</span>
                    </mat-chip>
                </mat-chip-list>
            </section>

            <section class="academy__description">
                {{ (academy$ | async).description }}
            </section>

            <section class="academy__amenities" *ngIf="(academy$ | async).type !== TYPES.COACH">
                <span class="academy__amenities--title" lang key="ACADEMY.AMENITIES"></span>
                <div class="academy__amenities--container" *ngIf="amenities.length">
                    <div class="academy__amenity" *ngFor="let amenity of amenities">
                        <i class="academy__amenity--icon icon__amenity--{{ amenity.id }}"></i>
                        <div class="academy__amenity--title">{{ amenity.name }}</div>
                    </div>
                </div>
                <div *ngIf="!(academy$ | async).amenities.length" class="academy__amenities--empty">
                    <span lang key="ACADEMY.NO_AMENITIES"></span>
                </div>
            </section>

            <button
                *ngIf="(academy$ | async).amenities.length > 6 && (academy$ | async).type !== TYPES.COACH"
                class="academy__amenities--all"
                mat-button
                (click)="toggleAmenities()"
            >
                <div [ngSwitch]="amenitiesShowAll">
                    <div *ngSwitchCase="true"><span lang key="ACTIONS.SHOW_ALL"></span></div>
                    <div *ngSwitchCase="false"><span lang key="ACTIONS.SHOW_LESS"></span></div>
                </div>
            </button>
        </section>
        <div class="academy__location">
            <app-map
                class="academy__location--map"
                [lat]="(academy$ | async).lat"
                [lng]="(academy$ | async).lng"
                [marker]="colors.get((academy$ | async).type)"
                [static]="true"
            ></app-map>
            <div class="academy__location--name">
                <span>{{ (academy$ | async).location }}</span>
            </div>
        </div>
    </section>

    <section class="reverse-mobile academy">
        <section class="academy__reviews">
            <app-reviews></app-reviews>
        </section>
        <section class="academy__schedule">
            <span class="academy__schedule--title" lang key="ACADEMY.WeekSchedule"></span>
            <app-schedule-week [schedule]="(academy$ | async).week_schedule"></app-schedule-week>

            <section class="academy__actions">
                <button class="academy__button--contact-info" mat-button (click)="showContactInfo()">
                    <span lang key="ACTIONS.SHOW_CONTACT_INFO"></span>
                </button>
                <button class="academy__button--schedule" mat-button (click)="ShowSchedule()">
                    <span lang key="ACTIONS.SHOW_SCHEDULE"></span>
                </button>
            </section>

            <section *ngIf="hasSocialLinks$ | async" class="academy__social-links">
                <app-social-links></app-social-links>
            </section>

            <button *ngIf="authorized" class="academy__button--report" (click)="openReport()">
                <div>
                    <i class="icon__report"></i>
                    <span lang key="ACTIONS.REPORT"></span>
                </div>
            </button>
        </section>
    </section>

    <app-footer class="academy__footer"></app-footer>
</section>
