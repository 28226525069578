import { Academy, ACADEMY_DEFAULT, ACADEMY_TYPES, Pagination, PAGINATION_DEFAULT } from 'src/app/types/academy.types';
import { MenuConfig, MENU_CONFIG_DEFAULT, Role, ROLES } from 'src/app/types/types';
import { Filter, FILTER_DEFAULT, AcademyType } from './academy.types';

import { Location, LOCATION_DEFAULT } from './locations.types';
import { Amenity } from './amenity.types';
import { Document } from './document.types';
import { Skills } from './skill.types';
import { Gainer, GAINER_DEFAULT } from './api.types';
import { Reviews } from './reviews.types';

export interface State {
    auth: AuthState;
    location: LocationState;
    menu: MenuState;
    academy: AcademyState;
    amenity: AmenityState;
    document: Document;
    skills: Skills;
    reviews: Reviews;
}

export interface AmenityState {
    amenities: Array<Amenity>;
}

export interface AcademyState {
    search: boolean;
    type: AcademyType;
    filter: Filter;
    academies: Array<Academy>;
    academy: Academy;
    pagination: Pagination;
}

export interface MenuState {
    menu: MenuConfig;
}

export interface AuthState {
    approved: boolean;
    step: number;
    accessToken: string;
    email: string;
    password?: string;
    role: Role;
    academy: Academy;
    gainer: Gainer;
    landing: Role;
    subscriptions: Subscriptions;
    favorites: Array<Favorite>;
}

export interface Favorite {
    academy_id: number;
    id: number;
    user_id: number;
    academy: Academy;
}

export interface Subscriptions {
    is_profile_update_subscribe: boolean;
    is_new_review_added_subscribe: boolean;
    is_get_digest_subscribe: boolean;
}

export interface LocationState {
    location: Location;
    locations: Array<Location>;
}

export const SUBSCRIPTIONS: Subscriptions = {
    is_profile_update_subscribe: false,
    is_new_review_added_subscribe: false,
    is_get_digest_subscribe: false
};

export const ACADEMY_STATE_DEFAULT: AcademyState = {
    search: false,
    type: ACADEMY_TYPES.SOMETHING_NEW,
    academy: ACADEMY_DEFAULT,
    filter: FILTER_DEFAULT,
    pagination: PAGINATION_DEFAULT,
    academies: []
};

export const AUTH_STATE_DEFAULT: AuthState = {
    approved: false,
    step: 0,
    accessToken: '',
    email: '',
    role: ROLES.GAINER,
    academy: ACADEMY_DEFAULT,
    gainer: GAINER_DEFAULT,
    landing: ROLES.GAINER,
    subscriptions: SUBSCRIPTIONS,
    favorites: []
};

export const AMENITY_STATE_DEFAULT: AmenityState = {
    amenities: []
};

export const MENU_STATE_DEFAULT: MenuState = {
    menu: {
        ...MENU_CONFIG_DEFAULT
    }
};

export const LOCATION_STATE_DEFAULT: LocationState = {
    location: { ...LOCATION_DEFAULT },
    locations: []
};

export const SELECTORS = {
    AUTH: 'auth',
    LOCATION: 'location',
    MENU: 'menu',
    ACADEMY: 'academy',
    AMENITY: 'amenity',
    DOCUMENT: 'document',
    SKILLS: 'skills',
    REVIEWS: 'reviews'
};
