import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { getAcademy } from 'src/app/store/selectors/academy.selectors';
import { Academy, AcademyTypeList, ACADEMY_TYPES } from 'src/app/types/academy.types';
import { Review } from 'src/app/types/reviews.types';
import { environment } from 'src/environments/environment';
import { GalleryComponent } from '../../modals/gallery/gallery.component';
import { ReplyComponent } from '../../modals/reply/reply.component';

@Component({
    selector: 'app-review',
    templateUrl: './review.component.html',
    styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
    @Input() review: Review;

    @Input() reply: boolean;

    public IMAGE_PATH: string = environment.images;

    public date: string = '';

    public TYPES: AcademyTypeList = { ...ACADEMY_TYPES };

    public academy$: Observable<Academy>;

    constructor(private store: Store, private dialog: MatDialog) {}

    public ngOnInit(): void {
        try {
            this.date = moment(this.review.created_at).format('MMMM YY');
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('parse review created time error: ', error);
        }
        this.academy$ = this.store.select(getAcademy);
    }

    public show(current: string): void {
        this.dialog.open(GalleryComponent, {
            panelClass: 'galley-modal',
            data: {
                current,
                images: this.review.images
            }
        });
    }

    public showReplyImages(current: string): void {
        this.dialog.open(GalleryComponent, {
            panelClass: 'galley-modal',
            data: {
                current,
                images: this.review.reply.images
            }
        });
    }

    public replyOpen(): void {
        this.dialog.open(ReplyComponent, {
            panelClass: 'reply-modal',
            data: {
                review: this.review
            }
        });
    }
}
