/* eslint-disable no-restricted-syntax */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ClearReplyImages } from 'src/app/store/actions/reviews/clear-reply-images.action';
import { RemoveReplyImage } from 'src/app/store/actions/reviews/remove-reply-image.action';
import { SendReplyEffect } from 'src/app/store/actions/reviews/send-reply-effect.action';
import { UploadReplyImageEffect } from 'src/app/store/actions/reviews/upload-reply-image-effect.action';
import { getReplyImages } from 'src/app/store/selectors/reviews.selectors';
import { AcademyTypeList, ACADEMY_TYPES, ImageItem } from 'src/app/types/academy.types';
import { Reply, ReplyDialog, REPLY_DEFAULT } from 'src/app/types/reviews.types';
import { ICONS, IMAGES } from 'src/app/types/types';
import { environment } from 'src/environments/environment';
import { GalleryComponent } from '../gallery/gallery.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-reply',
    templateUrl: './reply.component.html',
    styleUrls: ['./reply.component.scss']
})
export class ReplyComponent implements OnInit {
    public IMAGE_PATH: string = environment.images;

    public TYPES: AcademyTypeList = { ...ACADEMY_TYPES };

    public ICONS = { ...ICONS };

    public reply: Reply = { ...REPLY_DEFAULT };

    constructor(private store: Store, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: ReplyDialog) {}

    public ngOnInit(): void {
        this.reply.review_id = this.data.review.id;

        this.store.select(getReplyImages).subscribe((images: ImageItem[]) => {
            this.reply.images = images;
        });
    }

    public send(): void {
        this.store.dispatch(new SendReplyEffect(this.reply, this.data.review.academy_id));
        this.store.dispatch(new ClearReplyImages());
        this.reply = { ...REPLY_DEFAULT };
        this.dialog.closeAll();
    }

    public removeImage(index: number): void {
        this.store.dispatch(new RemoveReplyImage(index));
    }

    public uploadImage(event): void {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files.length + this.reply.images.length <= 3) {
                for (const i of event.target.files) {
                    if (i.size < 2000000) {
                        const imagesReader = new FileReader();
                        imagesReader.onload = (e: any) => {
                            this.dataUrlToFile(e.target.result, i.name).then((res) => {
                                this.store.dispatch(new UploadReplyImageEffect(res));
                            });
                        };
                        imagesReader.readAsDataURL(i);
                    } else {
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__error',
                            data: {
                                message: 'ERRORS.IMAGE_SIZE',
                                confirm: 'OK',
                                image: IMAGES.ERROR
                            }
                        });
                    }
                }
            } else {
                this.dialog.open(ModalComponent, {
                    panelClass: 'modal__warning',
                    data: {
                        message: 'WARNING.REVIEW_IMAGE_LIMIT',
                        confirm: 'TRY_AGAIN',
                        image: IMAGES.ERROR
                    }
                });
            }
        }
    }

    private async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }

    public show(current: string): void {
        this.dialog.open(GalleryComponent, {
            panelClass: 'galley-modal',
            data: {
                current,
                images: this.reply.images
            }
        });
    }

    public close(): void {
        this.dialog
            .open(ModalComponent, {
                panelClass: 'modal__error',
                data: {
                    message: 'MENU.SURE',
                    confirm: 'YES',
                    continue: 'CANCEL'
                }
            })
            .afterClosed()
            .subscribe((status: string) => {
                if (status === 'confirm') {
                    this.store.dispatch(new ClearReplyImages());
                    this.dialog.closeAll();
                }
            });
    }
}
