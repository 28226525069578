import { Component, OnInit } from '@angular/core';
import { ICONS, MenuConfig, MENU_CONFIG_DEFAULT, PAGES } from 'src/app/types/types';

import { Store } from '@ngrx/store';
import { getMenu } from 'src/app/store/selectors/menu.selectors';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/templates/modals/modal/modal.component';
import { SetLocationsAction } from 'src/app/store/actions/location/set-locations.action';
import { SetMenuAction } from '../store/actions/menu/set-menu,action';
import { SetStepAction } from '../store/actions/auth/set-step.action';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
    public ICONS = ICONS;

    public menu: MenuConfig = MENU_CONFIG_DEFAULT;

    public PAGES = { ...PAGES };

    constructor(private store: Store, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.store.select(getMenu).subscribe((menu: MenuConfig) => {
            this.menu = {
                ...menu
            };
        });
    }

    public close(outside?: boolean): void {
        if (this.menu.open) {
            switch (this.menu.page) {
                case PAGES.RESTORE:
                    if (!outside) {
                        this.dialog
                            .open(ModalComponent, {
                                panelClass: 'modal__confirm',
                                data: {
                                    message: 'MENU.SURE',
                                    confirm: 'STAY',
                                    continue: 'LEAVE'
                                }
                            })
                            .afterClosed()
                            .subscribe((result) => {
                                if (result === 'continue') {
                                    this.store.dispatch(
                                        new SetMenuAction({
                                            page: '',
                                            open: false
                                        })
                                    );
                                }
                            });
                    }
                    break;
                case PAGES.REGISTER:
                    if (!outside) {
                        this.dialog
                            .open(ModalComponent, {
                                panelClass: 'modal__confirm',
                                data: {
                                    message: 'AUTH.CLOSE',
                                    confirm: 'STAY',
                                    continue: 'LEAVE'
                                }
                            })
                            .afterClosed()
                            .subscribe((result) => {
                                if (result === 'continue') {
                                    this.store.dispatch(new SetStepAction(0));
                                    this.store.dispatch(new SetLocationsAction([]));

                                    this.store.dispatch(
                                        new SetMenuAction({
                                            page: '',
                                            open: false
                                        })
                                    );
                                }
                            });
                    }
                    break;
                case PAGES.PASSWORD:
                    if (!outside) {
                        this.dialog
                            .open(ModalComponent, {
                                panelClass: 'modal__confirm',
                                data: {
                                    message: 'MENU.SURE',
                                    confirm: 'STAY',
                                    continue: 'LEAVE'
                                }
                            })
                            .afterClosed()
                            .subscribe((result) => {
                                if (result === 'continue') {
                                    this.store.dispatch(
                                        new SetMenuAction({
                                            page: '',
                                            open: false
                                        })
                                    );
                                }
                            });
                    }
                    break;
                default:
                    this.store.dispatch(
                        new SetMenuAction({
                            page: '',
                            open: false
                        })
                    );
                    break;
            }
        }
    }
}
