import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { getOwnAcademy } from 'src/app/store/selectors/auth.selectors';
import { getSkillsState } from 'src/app/store/selectors/skills.selectors';
import { Academy, AcademyType, ACADEMY_TYPES } from 'src/app/types/academy.types';
import { Skill, Specialization, Skills } from 'src/app/types/skill.types';

@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit, OnChanges {
    @Input() expanded: boolean;

    @Input() clean: boolean;

    @Input() header: boolean;

    @Input() filter: AcademyType;

    @Output() selected: EventEmitter<Array<Skill>> = new EventEmitter<Array<Skill>>();

    public TYPES = { ...ACADEMY_TYPES };

    public type: AcademyType;

    public skills: Array<Specialization> = [];

    private selectedSkills: Array<Skill> = [];

    constructor(private store: Store) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.clean && changes.clean.currentValue !== changes.clean.previousValue) {
            this.selectedSkills = [];
            if (this.skills.length) {
                this.skills = new Array(
                    ...this.skills.map((specialization: Specialization) => ({
                        ...specialization,
                        skills: new Array(
                            ...specialization.skills.map((skill: Skill) => ({
                                ...skill,
                                model: false
                            }))
                        )
                    }))
                );
            }
        }

        if (!!changes.filter && changes.filter.currentValue && changes.filter.currentValue !== changes.filter.previousValue) {
            this.type = changes.filter.currentValue;
            this.selectedSkills = [];
            this.store.select(getSkillsState).subscribe((skills: Skills) => {
                this.skills = new Array(
                    ...skills[this.type].map((specialization: Specialization) => ({
                        ...specialization,
                        skills: new Array(
                            ...specialization.skills.map((skill: Skill) => ({
                                ...skill,
                                model: false
                            }))
                        )
                    }))
                );
            });
        }
    }

    public ngOnInit(): void {
        if (this.filter) {
            this.type = this.filter;
            this.store.select(getSkillsState).subscribe((skills: Skills) => {
                this.skills = new Array(
                    ...skills[this.type]
                        .map((specialization: Specialization) => ({
                            ...specialization,
                            skills: new Array(
                                ...specialization.skills.map((skill: Skill) => ({
                                    ...skill,
                                    model: false
                                }))
                            )
                        }))
                        .sort((a: Specialization) => {
                            if (a.type === 'coach') {
                                return -1;
                            }

                            return 0;
                        })
                );
            });
        } else {
            this.store.select(getOwnAcademy).subscribe((academy: Academy) => {
                this.type = academy.type;
                this.selectedSkills = [];
                academy.skills.forEach((s) => this.selectedSkills.push(s));
                this.store.select(getSkillsState).subscribe((skills: Skills) => {
                    this.skills = new Array(
                        ...skills[this.type].map((specialization: Specialization) => ({
                            ...specialization,
                            skills: new Array(
                                ...specialization.skills.map((skill: Skill) => ({
                                    ...skill,
                                    model: academy.skills.findIndex((s) => s.id === skill.id) !== -1
                                }))
                            )
                        }))
                    );

                    this.selected.emit(this.selectedSkills);
                });
            });
        }
    }

    public switchSkill(skill: Skill): void {
        if (!skill.model) {
            this.selectedSkills.push(skill);
        } else {
            const index = this.selectedSkills.findIndex((s) => s.id === skill.id);
            if (index !== -1) this.selectedSkills.splice(index, 1);
        }

        this.selected.emit(this.selectedSkills);
    }

    public hasSelected(skills): boolean {
        return skills.findIndex((skill: Skill) => skill.model === true) !== -1;
    }
}
