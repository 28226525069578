/* eslint-disable no-param-reassign */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WeekDay } from 'src/app/types/academy.types';
import { Store } from '@ngrx/store';
import { getOwnAcademy } from 'src/app/store/selectors/auth.selectors';
import { SetScheduleAction } from 'src/app/store/actions/auth/set-schedule.action';
import { REGEXP } from 'src/app/types/types';
import { Academy, DAY, WeekSchedule, WEEK_SCHEDULE_DEFAULT, WEEK_SCHEDULE_LIST } from '../../types/academy.types';

@Component({
    selector: 'app-schedule-week-edit',
    templateUrl: './schedule-week-edit.component.html',
    styleUrls: ['./schedule-week-edit.component.scss']
})
export class ScheduleWeekEditComponent implements OnInit {
    @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

    public weekList: Array<WeekDay> = new Array(...WEEK_SCHEDULE_LIST);

    private week: WeekSchedule = { ...WEEK_SCHEDULE_DEFAULT };

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.store.select(getOwnAcademy).subscribe((academy: Academy) => {
            this.week = { ...academy.week_schedule };

            this.weekList.forEach((day: WeekDay) => {
                day.start_time = this.week[day.day].start_time;
                day.end_time = this.week[day.day].end_time;
            });
        });
    }

    public setStartTime(time: string, day: DAY): void {
        try {
            this.week = {
                ...this.week,
                [day]: {
                    ...this.week[day],
                    start_time: time
                }
            };

            this.store.dispatch(new SetScheduleAction(this.week));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('error set week schedule', error);
        } finally {
            this.validate(day);
        }
    }

    public setEndTime(time: string, day: DAY): void {
        try {
            this.week = {
                ...this.week,
                [day]: {
                    ...this.week[day],
                    end_time: time
                }
            };

            this.store.dispatch(new SetScheduleAction(this.week));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('error set week schedule', error);
        } finally {
            this.validate(day);
        }
    }

    public validate(day: DAY): void {
        const index = this.weekList.findIndex((weekDay: WeekDay) => weekDay.day === day);

        if (index !== -1) {
            this.weekList[index].start_time_valid = REGEXP.SCHEDULE_TIME.test(this.week[day].start_time);
            this.weekList[index].end_time_valid = REGEXP.SCHEDULE_TIME.test(this.week[day].end_time);

            const [, startHour, startMinute] = this.week[day].start_time.match(REGEXP.SCHEDULE_TIME);
            const [, endHour, endMinute] = this.week[day].end_time.match(REGEXP.SCHEDULE_TIME);

            if (
                Number.isInteger(Number(startHour)) &&
                Number.isInteger(Number(startMinute)) &&
                Number.isInteger(Number(endHour)) &&
                Number.isInteger(Number(endMinute))
            ) {
                if (Number(startHour) > Number(endHour)) {
                    this.weekList[index].start_time_valid = false;
                    this.weekList[index].end_time_valid = false;
                } else if (Number(startHour) === Number(endHour)) {
                    if (Number(startMinute) >= Number(endMinute)) {
                        this.weekList[index].start_time_valid = false;
                        this.weekList[index].end_time_valid = false;
                    } else {
                        this.weekList[index].start_time_valid = true;
                        this.weekList[index].end_time_valid = true;
                    }
                } else {
                    this.weekList[index].start_time_valid = true;
                    this.weekList[index].end_time_valid = true;
                }
            } else {
                this.weekList[index].start_time_valid = false;
                this.weekList[index].end_time_valid = false;
            }
        }

        this.valid.emit(!this.weekList.some((weekDay: WeekDay) => !weekDay.end_time_valid || !weekDay.start_time_valid));
    }

    public onStartTimeChange(event, day: DAY): void {
        this.setStartTime(event.target.value, day);
    }

    public onEndTimeChange(event, day: DAY): void {
        this.setEndTime(event.target.value, day);
    }
}
