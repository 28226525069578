<section class="register--container">
    <div class="register">
        <h1>
            <mat-icon class="register--back" (click)="goBack()">{{ ICONS.BACK }}</mat-icon>

            <div [ngSwitch]="(academy$ | async).type">
                <div *ngSwitchCase="TYPES.COACH">
                    <span lang key="AUTH.COACH_TITLE"></span>
                </div>
                <div *ngSwitchCase="TYPES.GYM">
                    <span lang key="AUTH.GYM_TITLE"></span>
                </div>
                <div *ngSwitchCase="TYPES.SPORTS_CLUB">
                    <span lang key="AUTH.SPORTS_CLUB_TITLE"></span>
                </div>
            </div>
        </h1>

        <h2><span lang key="AUTH.REGISTER_SKILLS_TITLE"></span></h2>

        <app-skills [expanded]="true" [clean]="true" (selected)="selected($event)"></app-skills>

        <button [disabled]="skills.length === 0" mat-raised-button class="register__button" (click)="nextStep()">
            <span lang key="ACTIONS.CONTINUE"></span>
        </button>
    </div>
</section>
