<section class="footer" [class.home-page]="isHome">
    <section class="footer__left--container">
        <div class="footer__left">
            <i (click)="goTo(ROUTES.GAINER_LANDING)" class="icon__logo"></i>
            <span class="light" lang key="MAIN.COPYRIGHT"></span>
        </div>
    </section>
    <section class="footer__right--container">
        <i (click)="goTo(ROUTES.GAINER_LANDING)" class="mobile icon__logo"></i>
        <section class="footer__right">
            <div class="footer__right-row">
                <span class="title" lang key="MAIN.LANDINGS"></span>
                <button (click)="goTo(ROUTES.GAINER_LANDING)">
                    <div><span lang key="MAIN.GAINER"></span></div>
                </button>
                <button (click)="goTo(ROUTES.ACADEMY_LANDING)">
                    <div><span lang key="MAIN.ACADEMY"></span></div>
                </button>
            </div>
            <div class="footer__right-row">
                <span class="title" lang key="MAIN.DOCUMENTS"></span>

                <button (click)="goToDocuments('website-privacy-policy')">
                    <span lang key="ACTIONS.PRIVACY"></span>
                </button>
                <button (click)="goToDocuments('terms-of-use')">
                    <span lang key="ACTIONS.TERMS"></span>
                </button>
                <button class="mobile" (click)="goToDocuments('terms-of-use')">
                    <span lang key="ACTIONS.COOKIES"></span>
                </button>
            </div>
            <div class="footer__right--cookies">
                <button (click)="goToDocuments('cookie-policy')">
                    <span lang key="ACTIONS.COOKIES"></span>
                </button>
            </div>
            <div class="footer__right--contacts">
                <span class="title" lang key="ACTIONS.CONTACTS"></span>

                <span class="light" lang key="MAIN.NUMBER"></span>
                <span class="light" lang key="MAIN.LOCATION"></span>
            </div>
        </section>
        <span class="mobile light title copyright" lang key="MAIN.COPYRIGHT"></span>
    </section>
</section>
