import { Action } from '@ngrx/store';
import { MenuConfig } from 'src/app/types/types';

export const SET_RIGHT_BAR = '[RIGHT_BAR] set';
export const CLOSE_REGISTER = '[MENU] register close';

export class SetMenuAction implements Action {
    readonly type = SET_RIGHT_BAR;

    constructor(public bar: MenuConfig) {}
}
