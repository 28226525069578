import { Action } from '@ngrx/store';
import { Location } from 'src/app/types/locations.types';

export const SET_LOCATION = '[Location] set location';

export class SetLocationAction implements Action {
    readonly type = SET_LOCATION;

    constructor(public location: Location) {}
}
