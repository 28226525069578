<div mat-dialog-content class="modal__content">
    <mat-tab-group [selectedIndex]="step" mat-stretch-tabs class="search__tab-group">
        <mat-tab>
            <div class="search__label">
                <span lang key="MAIN.FIND"></span>
            </div>
            <input
                (focus)="focus = true"
                [(ngModel)]="search"
                class="search__input"
                placeholder="Search by city, address, or postcode"
                type="text"
                sanitize
                (input)="getLocation()"
            />
            <section class="search__results" *ngIf="!!locations.length || focus">
                <div class="search__results--scroll">
                    <div class="search__results--item" *ngFor="let location of locations">
                        <span (click)="selectLocation(location)">{{ location.place_name }}</span>
                    </div>
                    <i class="underline" *ngIf="!!locations.length"></i>
                    <div class="results__by-user" (click)="findLocationByUser()">
                        <mat-icon class="icon__search">{{ ICONS.SEARCH }}</mat-icon>
                        <span lang key="HOME.SEARCH_NEAR"></span>
                    </div>
                </div>
            </section>
        </mat-tab>

        <mat-tab class="search__tab">
            <span class="search__label--type" lang key="MAIN.LOOKING_FOR"></span>
            <div class="search__type--container">
                <button
                    analytics
                    [type]="ANALYTIC_TYPES.ACADEMY_TYPE_SELECTED"
                    [label]="t"
                    [class.selected]="t === type"
                    class="search__type"
                    *ngFor="let t of types"
                    (click)="switchType(t)"
                >
                    <span class="search__type--option">{{ t }}</span>
                </button>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div mat-dialog-actions class="modal__actions">
    <div [ngSwitch]="step">
        <div *ngSwitchCase="0">
            <button [disabled]="!location" class="modal__button" (click)="continue()" mat-button>
                <span lang key="ACTIONS.CONTINUE"></span>
            </button>
        </div>
        <div *ngSwitchCase="1">
            <button
                class="modal__button"
                analytics
                [type]="ANALYTIC_TYPES.SEARCH_PERFORMED"
                [label]="location.place_name"
                (click)="close()"
                mat-button
            >
                <span lang key="ACTIONS.CONTINUE"></span>
            </button>
        </div>
    </div>
</div>
