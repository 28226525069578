<div mat-dialog-content class="modal__content">
    <img *ngIf="data.image" src="{{ data.image }}" />
    <span lang key="{{ data.message }}"></span>
</div>

<div mat-dialog-actions class="modal__actions">
    <button *ngIf="data.continue" class="modal__button--continue" (click)="close('continue')" mat-button>
        <span lang [key]="'ACTIONS.' + data.continue"></span>
    </button>
    <button class="modal__button" mat-button cdkFocusInitial (click)="close('confirm')">
        <span lang [key]="'ACTIONS.' + data.confirm"></span>
    </button>
</div>
