import { AcademyType } from 'src/app/types/academy.types';

export type Skills = {
    [key in AcademyType]: Array<Specialization>;
};
export interface Skill {
    model?: boolean;
    count?: number;
    id: number;
    specialization_id?: number;
    name: string;
}
export interface Specialization {
    id: number;
    name: string;
    type: AcademyType;
    skills: Skill[];
}

export const SKILLS_DEFAULT: Skills = {
    coach: [],
    gym: [],
    'sports club': [],
    'something new': []
};
