import { AcademyType, ACADEMY_TYPES } from './academy.types';

export interface Marker {
    lat: number;
    lng: number;
    id: number;
    color: string;
    type: AcademyType;
    popup?: MarkerPopup;
}

export interface MarkerPopup {
    open?: boolean;
    id: number;
    lat: number;
    lng: number;
    thumbnail: string;
    title: string;
    location: string;
}

export const MARKER_POPUP_DEFAULT: MarkerPopup = {
    open: false,
    id: 0,
    lat: 0,
    lng: 0,
    thumbnail: '',
    title: '',
    location: ''
};

export const RED: string = 'red';
export const YELLOW: string = 'yellow';
export const BLUE: string = 'blue';
export const PURPLE: string = 'purple';

export const SELECTED: string = 'selected';

export const COLORS: Map<string, string> = new Map([
    [ACADEMY_TYPES.COACH, RED],
    [ACADEMY_TYPES.GYM, BLUE],
    [ACADEMY_TYPES.SPORTS_CLUB, YELLOW],
    [SELECTED, PURPLE]
]);
