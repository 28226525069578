import { ICONS, PAGES, Role, ROLES } from 'src/app/types/types';
import { Favorite, LocationState } from 'src/app/types/state.types';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Academy, ACADEMY_DEFAULT, ACADEMY_TYPES, ImageItem } from 'src/app/types/academy.types';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getAcademy, hasSocialLinks } from 'src/app/store/selectors/academy.selectors';
import { Amenity } from 'src/app/types/amenity.types';
import { DeviceService } from 'src/app/core/services/device.service';
import { COLORS } from 'src/app/types/map.types';
import { MatDialog } from '@angular/material/dialog';
import { ContactInfoModalComponent } from 'src/app/templates/modals/contact-info/contact-info.component';
import { environment } from 'src/environments/environment';
import { GalleryComponent } from 'src/app/templates/modals/gallery/gallery.component';
import { getRole, isAuthorized, getFavorites } from 'src/app/store/selectors/auth.selectors';
import { ModalComponent } from 'src/app/templates/modals/modal/modal.component';
import { Location } from '@angular/common';
import { ReportComponent } from 'src/app/templates/modals/report/report.component';
import { GetAcademyEffectAction } from 'src/app/store/actions/academy/get-academy-effect.action';
import { SetAcademyAction } from 'src/app/store/actions/academy/set-academy.action';
import { SetMenuAction } from 'src/app/store/actions/menu/set-menu,action';
import { GetFavoriteAcademies } from 'src/app/store/actions/auth/get-favorite-academies-effect-action';
import { DeleteFromFavoriteAcademies } from 'src/app/store/actions/auth/delete-from-favorites-academies-effect.action';
import { AddToFavoriteAcademies } from 'src/app/store/actions/auth/add-to-favorites-academies-effect.action';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { SIGN_UP_CLICKED, SIGN_UP_LABELS } from 'src/app/types/google-analytics.types';

@Component({
    selector: 'app-academy',
    templateUrl: './academy.component.html',
    styleUrls: ['./academy.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AcademyComponent implements OnInit {
    public ICONS = ICONS;

    public TYPES = ACADEMY_TYPES;

    public ROLES = ROLES;

    public IMAGE_PATH: string = environment.images;

    public role: Role;

    public favorite: boolean = true;

    public academy$: Observable<Academy>;

    public colors: Map<string, string> = new Map(COLORS);

    public amenities: Array<Amenity> = [];

    public amenitiesShowAll: boolean = true;

    public authorized: boolean;

    public hasSocialLinks$: Observable<boolean>;

    private ID: number = 0;

    private favoriteID: number = 0;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private dialog: MatDialog,
        private store: Store<LocationState>,
        private device: DeviceService,
        private analytics: AnalyticService
    ) {}

    public ngOnInit(): void {
        this.academy$ = this.store.select(getAcademy);
        this.hasSocialLinks$ = this.store.select(hasSocialLinks);

        this.store.select(isAuthorized).subscribe((status: boolean) => {
            this.authorized = status;
        });

        this.route.queryParams.subscribe((params: Params) => {
            if (!!params.id && Number(params.id)) {
                this.ID = params.id;
                this.academy$
                    .subscribe((academy: Academy) => {
                        if (academy.id !== params.id) {
                            this.store.dispatch(new GetAcademyEffectAction(params.id));
                        }
                    })
                    .unsubscribe();

                this.store.select(getRole).subscribe((role: Role) => {
                    this.role = role;
                    if (role === ROLES.GAINER && this.authorized) {
                        this.store.dispatch(new GetFavoriteAcademies());

                        this.store.select(getFavorites).subscribe((favorites: Array<Favorite>) => {
                            this.favorite = favorites.findIndex((f: Favorite) => f.academy_id === Number(params.id)) !== -1;

                            if (this.favorite) {
                                this.favoriteID = favorites.find((f: Favorite) => f.academy_id === Number(params.id)).id;
                            }
                        });
                    }
                });

                const amenitiesSelector = this.store.select(getAcademy).subscribe((academy: Academy) => {
                    if (academy.amenities.length !== 0) {
                        this.amenities = new Array(...academy.amenities);
                        if (this.device.isMobile() && this.amenities.length > 6) {
                            this.amenitiesShowAll = false;
                            this.toggleAmenities();
                        }

                        amenitiesSelector.unsubscribe();
                    }
                });
            } else {
                this.goBack();
            }
        });
    }

    public toggleAmenities() {
        if (this.amenitiesShowAll) {
            this.store
                .select(getAcademy)
                .subscribe((academy: Academy) => {
                    this.amenities = new Array(...academy.amenities);
                })
                .unsubscribe();
            this.amenitiesShowAll = !this.amenitiesShowAll;
            return;
        }

        this.amenities = this.amenities.slice(0, 6);
        this.amenitiesShowAll = !this.amenitiesShowAll;
    }

    public goBack(): void {
        this.store.dispatch(new SetAcademyAction(ACADEMY_DEFAULT));
        this.location.back();
    }

    public addToFavorites(): void {
        if (this.favorite) {
            this.store.dispatch(new DeleteFromFavoriteAcademies(this.favoriteID));
        } else {
            this.store.dispatch(new AddToFavoriteAcademies(this.ID));
        }
        this.favorite = !this.favorite;
    }

    public ShowSchedule(): void {
        if (this.authorized) {
            this.academy$
                .subscribe((academy: Academy) => {
                    if (academy.schedule.length) {
                        this.dialog.open(GalleryComponent, {
                            panelClass: 'galley-modal',
                            data: {
                                current: this.IMAGE_PATH + academy.schedule[0].name,
                                images: academy.schedule
                            }
                        });
                    } else {
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__warning',
                            data: {
                                message: 'WARNING.SCHEDULE_EMPTY',
                                confirm: 'OK'
                            }
                        });
                    }
                })
                .unsubscribe();
        } else {
            this.dialog
                .open(ModalComponent, {
                    panelClass: 'modal__warning',
                    data: {
                        message: 'WARNING.SCHEDULE_OPEN',
                        confirm: 'LOGIN',
                        continue: 'SIGN_IN'
                    }
                })
                .afterClosed()
                .subscribe((status: string) => {
                    switch (status) {
                        case 'confirm':
                            this.store.dispatch(
                                new SetMenuAction({
                                    open: true,
                                    page: PAGES.LOGIN
                                })
                            );
                            break;

                        case 'continue':
                            this.analytics.event(SIGN_UP_CLICKED, SIGN_UP_LABELS.SCHEDULE_POPUP);
                            this.store.dispatch(
                                new SetMenuAction({
                                    open: true,
                                    page: PAGES.REGISTER
                                })
                            );
                            break;
                        default: {
                            break;
                        }
                    }
                });
        }
    }

    public showContactInfo(): void {
        this.dialog.open(ContactInfoModalComponent);
    }

    public openReport(): void {
        this.dialog.open(ReportComponent, {
            panelClass: 'report-modal',
            disableClose: true,
            data: {
                academy_id: this.ID
            }
        });
    }

    public openGallery(id: number): void {
        this.academy$
            .subscribe((academy: Academy) => {
                this.dialog.open(GalleryComponent, {
                    panelClass: 'galley-modal',
                    data: {
                        current: this.IMAGE_PATH + academy.gallery.find((image: ImageItem) => image.id === id).name,
                        images: academy.gallery
                    }
                });
            })
            .unsubscribe();
    }
}
