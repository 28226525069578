<section class="settings--scroll">
    <div class="settings--container">
        <h1>
            <span lang key="MENU.PROFILE_SETTINGS"></span>
        </h1>
        <div [ngSwitch]="role$ | async" class="settings">
            <div class="settings__gainer" *ngSwitchCase="ROLES.GAINER">
                <section class="settings__gainer--container">
                    <section>
                        <app-gainer-info (valid)="gainerValid = $event"></app-gainer-info>
                        <button [disabled]="!gainerValid" class="save__button" (click)="saveGainer()" mat-raised-button>
                            <span lang key="ACTIONS.SAVE"></span>
                        </button>
                    </section>
                </section>
                <div class="settings__footer">
                    <app-footer></app-footer>
                </div>
            </div>
            <div class="settings__academy" *ngSwitchCase="ROLES.ACADEMY">
                <section class="settings__academy--container">
                    <section>
                        <app-academy-profile (valid)="academyProfileValid = $event"></app-academy-profile>
                        <app-contact-info (valid)="contactInfoValid = $event"></app-contact-info>
                        <app-skills [header]="true" [expanded]="true" [clean]="true" (selected)="selected($event)"></app-skills>
                        <button
                            [disabled]="
                                !academyProfileValid ||
                                !contactInfoValid ||
                                !scheduleValid ||
                                academy.thumbnail.id === 0 ||
                                academy.gallery.length === 0 ||
                                !hasChanges ||
                                academy.skills.length === 0
                            "
                            class="save__button"
                            (click)="saveAcademy()"
                            mat-raised-button
                        >
                            <span lang key="ACTIONS.SAVE"></span>
                        </button>

                        <div class="settings__footer">
                            <app-footer></app-footer>
                        </div>
                    </section>
                    <section>
                        <app-upload-image [thumbnail]="true" [gallery]="true" [schedule]="true"></app-upload-image>
                        <app-schedule-week-edit (valid)="setScheduleValid($event)"></app-schedule-week-edit>
                    </section>
                </section>
            </div>
        </div>
    </div>
</section>
