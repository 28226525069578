import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SendFeedbackEffect } from 'src/app/store/actions/academy/send-feedback.action';
import { AcademyTypeList, ACADEMY_TYPES } from 'src/app/types/academy.types';
import { Feedback, FEEDBACK_DEFAULT, ICONS } from 'src/app/types/types';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {
    public IMAGE_PATH: string = environment.images;

    public TYPES: AcademyTypeList = { ...ACADEMY_TYPES };

    public ICONS = { ...ICONS };

    public feedback: Feedback = { ...FEEDBACK_DEFAULT };

    constructor(private store: Store, private dialog: MatDialog) {}

    public send(): void {
        this.store.dispatch(new SendFeedbackEffect(this.feedback));
        this.feedback = { ...FEEDBACK_DEFAULT };
        this.dialog.closeAll();
    }

    public close(): void {
        this.dialog
            .open(ModalComponent, {
                panelClass: 'modal__error',
                data: {
                    message: 'MENU.SURE',
                    confirm: 'YES',
                    continue: 'CANCEL'
                }
            })
            .afterClosed()
            .subscribe((status: string) => {
                if (status === 'confirm') {
                    this.dialog.closeAll();
                }
            });
    }
}
