import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardComponent } from 'src/app/templates/card/card.component';
import { FooterComponent } from 'src/app/templates/footer/footer.component';
import { FilterComponent } from 'src/app/templates/filter/filter.component';
import { MapComponent } from 'src/app/templates/map/map.component';
import { HeaderComponent } from 'src/app/templates/header/header.component';
import { ScheduleWeekEditComponent } from 'src/app/templates/schedule-week-edit/schedule-week-edit.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from 'src/environments/environment';
import { ModalComponent } from 'src/app/templates/modals/modal/modal.component';
import { CropModalComponent } from 'src/app/templates/modals/crop-modal/crop-modal.component';
import { ContactInfoModalComponent } from 'src/app/templates/modals/contact-info/contact-info.component';
import { ReviewComponent } from './reviews/review/review.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { ScheduleWeekComponent } from './schedule-week/schedule-week.component';
import { SocialLinksComponent } from './social-links/social-links.component';

import { MaterialModule } from '../material.module';

import { CoreModule } from '../core/core.module';

import { SkillsRefisterComponent } from './skills-register/skills-register.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import { AcademyMenuComponent } from './academy-profile/academy-profile.component';
import { GalleryComponent } from './modals/gallery/gallery.component';
import { SkillsComponent } from './skills/skills.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { GainerInfoComponent } from './gainer-info/gainer-info.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { AmenitiesComponent } from './amenities/amenities.component';
import { LeaveReviewComponent } from './modals/leave-review/leave-review.component';
import { SearchModalComponent } from './modals/search/search.component';
import { ReplyComponent } from './modals/reply/reply.component';
import { ReportComponent } from './modals/report/report.component';
import { FeedbackComponent } from './modals/feedback/feedback.component';
import { AppRoutingModule } from '../app-routing.module';
import { SearchLocationComponent } from './search-location/search-location.component';

@NgModule({
    declarations: [
        CardComponent,
        FooterComponent,
        FilterComponent,
        MapComponent,
        HeaderComponent,
        ReviewComponent,
        ReviewsComponent,
        ScheduleWeekComponent,
        SocialLinksComponent,
        ScheduleWeekEditComponent,
        ModalComponent,
        CropModalComponent,
        ContactInfoModalComponent,
        GalleryComponent,
        SkillsComponent,
        ContactInfoComponent,
        GainerInfoComponent,
        SkillsRefisterComponent,
        UserRegisterComponent,
        AcademyMenuComponent,
        UploadImageComponent,
        AmenitiesComponent,
        LeaveReviewComponent,
        SearchModalComponent,
        ReplyComponent,
        ReportComponent,
        FeedbackComponent,
        SearchLocationComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        AppRoutingModule,
        CoreModule,
        NgxMapboxGLModule.withConfig({
            accessToken: environment.mapBox.accessToken
        })
    ],
    exports: [
        MapComponent,
        CardComponent,
        FooterComponent,
        FilterComponent,
        HeaderComponent,
        ReviewComponent,
        ReviewsComponent,
        ScheduleWeekComponent,
        SocialLinksComponent,
        ScheduleWeekEditComponent,
        GalleryComponent,
        ContactInfoModalComponent,
        SkillsComponent,
        ContactInfoComponent,
        GainerInfoComponent,
        SkillsRefisterComponent,
        UserRegisterComponent,
        AcademyMenuComponent,
        UploadImageComponent,
        SearchModalComponent,
        SearchLocationComponent
    ],
    providers: []
})
export class TemplatesModule {}
