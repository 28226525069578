import { Skills, SKILLS_DEFAULT } from 'src/app/types/skill.types';
import { SkillsActions } from '../actions/skills.actions';
import { SET_SKILLS } from '../actions/skills/set-skills.action';

const initialState: Skills = {
    ...SKILLS_DEFAULT
};

export const skillsReducer = (state = initialState, action: SkillsActions) => {
    switch (action.type) {
        case SET_SKILLS:
            return {
                ...state,
                ...action.skills
            };
        default:
            return state;
    }
};
