import { Action } from '@ngrx/store';
import { Report } from 'src/app/types/academy.types';

export const SEND_REPORT_EFFECT = '[REPORT] create effect';

export class SendReportEffect implements Action {
    readonly type = SEND_REPORT_EFFECT;

    constructor(public report: Report) {}
}
