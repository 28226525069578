<div class="background__image" [style.background]="IMAGES.HOME_BACKGROUND"></div>
<div class="main__scroll">
    <div class="main__container">
        <main class="main">
            <h1 class="main__heading">
                <div [ngSwitch]="isGainer">
                    <div *ngSwitchCase="true"><span lang key="HOME.TITLE_GAINER"></span></div>
                    <div *ngSwitchCase="false"><span lang key="HOME.TITLE_ACADEMY"></span></div>
                </div>
            </h1>
            <div class="main__text" [ngSwitch]="isGainer">
                <div *ngSwitchCase="true"><span lang key="HOME.DESCRIPTION_GAINER"></span></div>
                <div *ngSwitchCase="false"><span lang key="HOME.DESCRIPTION_ACADEMY"></span></div>
            </div>

            <search-location *ngIf="isGainer" [select]="!isMobile" [button]="true"></search-location>
        </main>

        <app-footer class="footer"></app-footer>
    </div>
</div>
