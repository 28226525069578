import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getOwnAcademy } from 'src/app/store/selectors/auth.selectors';
import { AcademyTypeList, Academy, ACADEMY_TYPES } from 'src/app/types/academy.types';
import { ICONS, IMAGES } from 'src/app/types/types';
import { Skill } from 'src/app/types/skill.types';
import { SetOwnAcademyAction } from 'src/app/store/actions/auth/set-own-academy.action';
import { SetStepAction } from 'src/app/store/actions/auth/set-step.action';

@Component({
    selector: 'app-skills-register',
    templateUrl: './skills-register.component.html',
    styleUrls: ['./skills-register.component.scss']
})
export class SkillsRefisterComponent implements OnInit {
    public IMAGE_DEFAULT: string = IMAGES.DEFAULT;

    public TYPES: AcademyTypeList = ACADEMY_TYPES;

    public ICONS = ICONS;

    public academy: Academy;

    public skills: Array<Skill> = [];

    public academy$: Observable<Academy>;

    constructor(private fb: FormBuilder, private store: Store, private router: Router, private dialog: MatDialog) {}

    public ngOnInit(): void {
        this.academy$ = this.store.select(getOwnAcademy);

        this.academy$.subscribe((academy: Academy) => {
            this.academy = academy;
        });
    }

    public nextStep(): void {
        this.store.dispatch(
            new SetOwnAcademyAction({
                ...this.academy,
                skills: this.skills
            })
        );

        this.store.dispatch(new SetStepAction(3));
    }

    public goBack(): void {
        this.store.dispatch(new SetStepAction(1));
    }

    public selected(skills: Array<Skill>): void {
        this.skills = new Array(...skills);
    }
}
