import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DeviceService } from 'src/app/core/services/device.service';

import {
    getAcademies,
    getType,
    getFilterCount,
    getFilterOpenCount,
    getAcademiesPagination,
    getAcademiesFilter
} from 'src/app/store/selectors/academy.selectors';
import { getCurrentLocation } from 'src/app/store/selectors/location.selectors';

import { Location } from 'src/app/types/locations.types';
import { LocationState } from 'src/app/types/state.types';
import { TYPES, Academy, Filter, Pagination, PAGINATION_DEFAULT } from 'src/app/types/academy.types';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getMenu } from 'src/app/store/selectors/menu.selectors';
import { ToggleFilterAction } from 'src/app/store/actions/academy/toggle-filter.action';
import { SetTypeAction } from 'src/app/store/actions/academy/set-type.action';
import { LoadMoreAcademiesEffect } from 'src/app/store/actions/academy/load-more-academies.action';
import { GetLocationByCoordsEffect } from 'src/app/store/actions/location/get-location-by-coords-effect.action';
import { AnalyticTypes, ANALYTIC_TYPES } from 'src/app/types/google-analytics.types';
import { AcademyType } from '../../types/academy.types';
import { ICONS, MenuConfig, ROUTES } from '../../types/types';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public TYPES: Array<AcademyType> = new Array(...TYPES);

    public ICONS = ICONS;

    public filterOpen$: Observable<boolean>;

    public location$: Observable<Location>;

    public academies$: Observable<Array<Academy>>;

    public filterCount$: Observable<number>;

    public type$: Observable<string>;

    private menu: MenuConfig;

    public selected: number = 0;

    public pagination: Pagination = { ...PAGINATION_DEFAULT };

    constructor(public device: DeviceService, private store: Store<LocationState>, private router: Router, private route: ActivatedRoute) {}

    public ngOnInit(): void {
        if (!this.device.isMobile() && !this.device.isTablet()) {
            this.store.dispatch(new ToggleFilterAction());
        }

        this.store.select(getMenu).subscribe((menu: MenuConfig) => {
            this.menu = menu;
        });

        this.store.select(getAcademiesPagination).subscribe((pagination: Pagination) => {
            this.pagination = pagination;
        });

        this.route.queryParams.subscribe((params: Params) => {
            if (!!params.lat && !!Number(params.lat) && !!params.lng && !!Number(params.lng)) {
                const locationSelector = this.store.select(getCurrentLocation).subscribe((location: Location) => {
                    if (!location.id) {
                        this.store.dispatch(
                            new GetLocationByCoordsEffect({
                                lat: Number(params.lng),
                                lng: Number(params.lat)
                            })
                        );

                        locationSelector.unsubscribe();
                    }
                });
            }
        });

        this.filterOpen$ = this.store.select(getFilterOpenCount);
        this.location$ = this.store.select(getCurrentLocation);
        this.type$ = this.store.select(getType);
        this.filterCount$ = this.store.select(getFilterCount);
        this.academies$ = this.store.select(getAcademies);
    }

    public switchType(type: AcademyType): void {
        this.store.dispatch(new SetTypeAction(type));
    }

    public toggleFilter(): void {
        this.store.dispatch(new ToggleFilterAction());
    }

    public academyOpen(id: number): void {
        this.router.navigate([ROUTES.ACADEMY], { queryParams: { id } });
    }

    public setIndex(): number {
        return this.menu.open ? 0 : 2;
    }

    public selectAcademyMap(ID: number): void {
        this.selected = ID;
    }

    public loadMore(page: number): void {
        this.store
            .select(getAcademiesFilter)
            .subscribe((f: Filter) => {
                this.store.dispatch(
                    new LoadMoreAcademiesEffect({
                        ...f,
                        page
                    })
                );
            })
            .unsubscribe();
    }
}
