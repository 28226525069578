import { Action } from '@ngrx/store';
import { Subscriptions } from 'src/app/types/state.types';

export const UPDATE_ACADEMY_SUBSCRIPTIONS = '[ACCOUNT SETTINGS] save academy subscriptions';

export class UpdateSubscriptionsEffect implements Action {
    readonly type = UPDATE_ACADEMY_SUBSCRIPTIONS;

    constructor(public subscriptions: Subscriptions) {}
}
