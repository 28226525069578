import { Action } from '@ngrx/store';
import { Academy } from 'src/app/types/academy.types';

export const APPROVE_ACADEMY_EFFECT_ACTION = '[AUTH] approve academy effect action';

export class ApproveAcademyEffectAction implements Action {
    readonly type = APPROVE_ACADEMY_EFFECT_ACTION;

    constructor(public academy: Academy) {}
}
