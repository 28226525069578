import { Action } from '@ngrx/store';
import { RegisterRequest } from 'src/app/types/api.types';

export const REGISTRATION_EFFECT = '[AUTH] register new user effect';

export class RegisterEffectAction implements Action {
    readonly type = REGISTRATION_EFFECT;

    constructor(public request: RegisterRequest) {}
}
