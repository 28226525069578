import { Action } from '@ngrx/store';
import { Filter } from 'src/app/types/academy.types';

export const SET_ACADEMIES_FILTER = '[SEARCH] get academies by filter config';

export class SetFilterAction implements Action {
    readonly type = SET_ACADEMIES_FILTER;

    constructor(public filter: Filter) {}
}
