<section class="document__container" *ngIf="(document$ | async).id">
    <div class="document">
        <div class="document__actions">
            <mat-icon (click)="goBack()">{{ ICONS.BACK }}</mat-icon>
        </div>
        <span class="document__title">{{ (document$ | async).title }}</span>

        <div class="document__content" [innerHTML]="(document$ | async).content"></div>
    </div>
    <app-footer class="document__footer"></app-footer>
</section>

<section class="skeleton" *ngIf="!(document$ | async).id">
    <div class="document__actions">
        <mat-icon (click)="goBack()">{{ ICONS.BACK }}</mat-icon>
    </div>
    <ngx-skeleton-loader count="1" animation="progress" class="document__title skeleton__title"></ngx-skeleton-loader>
    <div class="skeleton__content">
        <ngx-skeleton-loader count="3" animation="progress" class="skeleton__paragraph"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="3" animation="progress" class="skeleton__paragraph"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="3" animation="progress" class="skeleton__paragraph"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="3" animation="progress" class="skeleton__paragraph"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="3" animation="progress" class="skeleton__paragraph"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="3" animation="progress" class="skeleton__paragraph"></ngx-skeleton-loader>
    </div>
</section>
