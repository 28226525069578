import { Action } from '@ngrx/store';
import { Review } from 'src/app/types/reviews.types';

export const SEND_REVIEW_EFFECT = '[REVIEW] create review';

export class SendReviewEffect implements Action {
    readonly type = SEND_REVIEW_EFFECT;

    constructor(public review: Review) {}
}
