import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api-service.service';
import { ModalComponent } from 'src/app/templates/modals/modal/modal.component';
import { Academy, ACADEMY_DEFAULT, ImageItem, IMAGE_TYPES, Report } from 'src/app/types/academy.types';
import { Subscriptions } from 'src/app/types/state.types';
import { COOKIES, IMAGES, ROLES } from 'src/app/types/types';

import { AppendAcademiesAction } from '../actions/academy/append-academies.action';
import { FilterAcademyEffectAction, FILTER_ACADEMIES } from '../actions/academy/filter-academy-effect.action';
import { GET_ACADEMY_EFFECT, GetAcademyEffectAction } from '../actions/academy/get-academy-effect.action';
import { LOAD_MORE_ACADEMIES_EFFECT, LoadMoreAcademiesEffect } from '../actions/academy/load-more-academies.action';
import { SEND_FEEDBACK_EFFECT, SendFeedbackEffect } from '../actions/academy/send-feedback.action';
import { SEND_REPORT_EFFECT, SendReportEffect } from '../actions/academy/send-report.action';
import { SetAcademiesPaginationAction } from '../actions/academy/set-academies-pagination.action';
import { SetAcademiesAction } from '../actions/academy/set-academies.action';
import { SetAcademyAction } from '../actions/academy/set-academy.action';
import { SetFilterAction } from '../actions/academy/set-filter.action';
import { GET_ACADEMY_SUBSCRIPTIONS } from '../actions/auth/get-academy-subscriptions-effect.action';
import { GET_OWN_ACADEMY } from '../actions/auth/get-own-academy-effect.action';
import { SetAcademySubscriptions } from '../actions/auth/set-academy-subscriptions.action';
import { SetOwnAcademyAction } from '../actions/auth/set-own-academy.action';
import { SetRoleAction } from '../actions/auth/set-role,action';
import { SetThumbnailAction } from '../actions/auth/set-trumbnail.action';
import { UPDATE_ACADEMY_EFFECT_ACTION, UpdateAcademyEffectAction } from '../actions/auth/update-academy-effect.action';
import { UPDATE_ACADEMY_SUBSCRIPTIONS, UpdateSubscriptionsEffect } from '../actions/auth/update-subscriptions-effect.action';
import { UPLOAD_GALLERY_IMAGE_EFFECT, UploadGalleryEffectAction } from '../actions/auth/upload-gallery-effect.action';
import { UploadGalleryAction } from '../actions/auth/upload-gallery.action';
import { UPLOAD_SCHEDULE_IMAGE_EFFECT, UploadScheduleImageEffectAction } from '../actions/auth/upload-schedule-image-effect.action';
import { UploadScheduleItemAction } from '../actions/auth/upload-schedule.action';
import { UploadThumbnailAction, UPLOAD_THUMBNAIL_IMAGE_EFFECT } from '../actions/auth/upload-thumbnail.action';

@Injectable()
export class AcademyEffects {
    constructor(
        private actions$: Actions,
        private api: ApiService,
        private cookie: CookieService,
        private dialog: MatDialog,
        private store: Store
    ) {}

    public getAcademyEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_ACADEMY_EFFECT),
            mergeMap((action: GetAcademyEffectAction) =>
                this.api
                    .getAcademy(action.id)
                    .pipe(
                        map((academy: Academy) =>
                            academy.is_visible ? new SetAcademyAction(academy) : new SetAcademyAction(ACADEMY_DEFAULT)
                        )
                    )
            )
        )
    );

    public filterAcademiesEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FILTER_ACADEMIES),
            debounceTime(500),
            mergeMap((action: FilterAcademyEffectAction) =>
                this.api.getAllApprovedAcademies(action.filter).pipe(
                    map((data) => {
                        this.store.dispatch(new SetFilterAction(action.filter));
                        this.store.dispatch(new SetAcademiesPaginationAction(data.pagination));
                        return new SetAcademiesAction(data.academies);
                    })
                )
            )
        )
    );

    public loadMoreAcademiesEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOAD_MORE_ACADEMIES_EFFECT),
            debounceTime(500),
            mergeMap((action: LoadMoreAcademiesEffect) =>
                this.api.getAllApprovedAcademies(action.filter).pipe(
                    map((data) => {
                        this.store.dispatch(new SetFilterAction(action.filter));
                        this.store.dispatch(new SetAcademiesPaginationAction(data.pagination));
                        return new AppendAcademiesAction(data.academies);
                    })
                )
            )
        )
    );

    public getOwnAcademy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_OWN_ACADEMY),
            mergeMap(() =>
                this.api.getOwnAcademy().pipe(
                    map((academy: Academy) => {
                        this.cookie.delete(COOKIES.ACADEMY);
                        this.cookie.set(COOKIES.ACADEMY, JSON.stringify(academy));

                        return new SetOwnAcademyAction(academy);
                    })
                )
            )
        )
    );

    public updateAcademy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_ACADEMY_EFFECT_ACTION),
            debounceTime(500),
            mergeMap((action: UpdateAcademyEffectAction) =>
                this.api.updateOwnAcademy(action.academy).pipe(
                    map(() => {
                        this.cookie.delete(COOKIES.ACADEMY);
                        this.cookie.set(COOKIES.ACADEMY, JSON.stringify(action.academy));
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__success',
                            data: {
                                message: 'SUCCESS.UPDATE',
                                confirm: 'OK',
                                image: IMAGES.SUCCESS
                            }
                        });
                        return new SetRoleAction(ROLES.ACADEMY);
                    })
                )
            )
        )
    );

    public uploadThumbnail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPLOAD_THUMBNAIL_IMAGE_EFFECT),
            mergeMap((action: UploadThumbnailAction) =>
                this.api.uploadImage(action.image, IMAGE_TYPES.THUMBNAIL).pipe(map((res: ImageItem) => new SetThumbnailAction(res)))
            )
        )
    );

    public uploadSchedule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPLOAD_SCHEDULE_IMAGE_EFFECT),
            mergeMap((action: UploadScheduleImageEffectAction) =>
                this.api.uploadImage(action.image, IMAGE_TYPES.SCHEDULE).pipe(
                    map(
                        (res: ImageItem) =>
                            new UploadScheduleItemAction({
                                ...res,
                                name: res.name
                            })
                    )
                )
            )
        )
    );

    public getSubscriptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_ACADEMY_SUBSCRIPTIONS),
            mergeMap(() =>
                this.api.getAcademySettings().pipe(
                    map((res: Subscriptions) => {
                        return new SetAcademySubscriptions(res);
                    })
                )
            )
        )
    );

    public saveSubscriptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_ACADEMY_SUBSCRIPTIONS),
            debounceTime(500),
            mergeMap((action: UpdateSubscriptionsEffect) =>
                this.api.updateAcademySettings(action.subscriptions).pipe(
                    map((res: Subscriptions) => {
                        return new SetAcademySubscriptions(res);
                    })
                )
            )
        )
    );

    public uploadGallery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPLOAD_GALLERY_IMAGE_EFFECT),
            mergeMap((action: UploadGalleryEffectAction) =>
                this.api.uploadImage(action.image, IMAGE_TYPES.GALLERY).pipe(
                    map(
                        (res: ImageItem) =>
                            new UploadGalleryAction({
                                ...res,
                                name: res.name
                            })
                    )
                )
            )
        )
    );

    public sendReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SEND_REPORT_EFFECT),
            debounceTime(500),
            mergeMap((action: SendReportEffect) =>
                this.api.sendReport(action.report).pipe(
                    map((res: Report) => {
                        if (res.id !== 0) {
                            this.dialog.open(ModalComponent, {
                                panelClass: 'modal__success',
                                data: {
                                    message: 'SUCCESS.REPORT_SEND',
                                    confirm: 'OK',
                                    image: IMAGES.SUCCESS
                                }
                            });
                        }
                        return new SetRoleAction(ROLES.ACADEMY);
                    })
                )
            )
        )
    );

    public sendFeedback$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SEND_FEEDBACK_EFFECT),
            debounceTime(500),
            mergeMap((action: SendFeedbackEffect) =>
                this.api.sendFeedback(action.feedback).pipe(
                    map((res: boolean) => {
                        if (res) {
                            this.dialog.open(ModalComponent, {
                                panelClass: 'modal__success',
                                data: {
                                    message: 'SUCCESS.FEEDBACK_SEND',
                                    confirm: 'OK',
                                    image: IMAGES.SUCCESS
                                }
                            });
                        }
                        return new SetRoleAction(ROLES.ACADEMY);
                    })
                )
            )
        )
    );
}
