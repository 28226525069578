import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileSettingsComponent } from 'src/app/pages/profile-settings/profile-settings.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SearchComponent } from './pages/search/search.component';
import { HomeComponent } from './pages/home/home.component';
import { AcademyComponent } from './pages/academy/academy.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { FavoritesComponent } from './pages/favorites/favorites.component';

const routes: Routes = [
    {
        path: 'gainer-landing',
        component: HomeComponent
    },
    {
        path: 'academy-landing',
        component: HomeComponent
    },
    {
        path: 'search',
        component: SearchComponent
    },
    {
        path: 'academy',
        component: AcademyComponent
    },
    {
        path: 'auth/restore/confirm',
        component: HomeComponent
    },
    {
        path: 'documents',
        component: DocumentsComponent
    },
    {
        path: 'profile-settings',
        component: ProfileSettingsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'account-settings',
        component: AccountSettingsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'favorites',
        component: FavoritesComponent,
        canActivate: [AuthGuard]
    },
    { path: '**', redirectTo: '/gainer-landing' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
