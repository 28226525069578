<section class="menu--container">
    <section *ngIf="authorized$ | async" class="menu">
        <span class="menu__title" lang key="MENU.MENU"></span>
        <div [ngSwitch]="role">
            <div *ngSwitchCase="ROLES.ACADEMY">
                <button
                    mat-button
                    class="menu__avatar"
                    [style.cursor.pointer]="academy.is_visible"
                    (click)="academy.is_visible && open(ROUTES.ACADEMY, { id: academy.id })"
                >
                    <div [matTooltip]="EN_CONFIG.MENU.OPEN_ACADEMY_PAGE" matTooltipPosition="right" matTooltipClass="menu__avatar--tooltip">
                        <i class="icon__user"></i>
                        <span>{{ academy.name }}</span>
                    </div>
                </button>
            </div>
            <div *ngSwitchCase="ROLES.GAINER">
                <div class="menu__avatar">
                    <i class="icon__user"></i>
                    <span>{{ name }}</span>
                </div>
            </div>
        </div>

        <button mat-button class="menu__button" (click)="open('account-settings')">
            <span lang key="MENU.ACCOUNT_SETTINGS"></span>
        </button>
        <button mat-button class="menu__button" (click)="open('profile-settings')">
            <span lang key="MENU.PROFILE_SETTINGS"></span>
        </button>
        <button *ngIf="role === ROLES.GAINER" mat-button class="menu__button" (click)="open('favorites')">
            <span lang key="MENU.FAVORITES"></span>
        </button>

        <button *ngIf="!confirmLogout" mat-button class="menu__button--logout" (click)="confirmLogout = true">
            <i class="icon__logout"></i>
            <span lang key="ACTIONS.LOGOUT"></span>
        </button>

        <section *ngIf="confirmLogout" class="menu__logout">
            <span lang key="AUTH.CONFIRM_LOGOUT"></span>
            <div class="menu__logout--actions">
                <button mat-button class="menu__button--logout-cancel" (click)="confirmLogout = false">
                    <span lang key="ACTIONS.CANCEL"></span>
                </button>
                <button mat-button class="menu__button--logout-confirm" (click)="logout()">
                    <span lang key="ACTIONS.YES"></span>
                </button>
            </div>
        </section>
    </section>

    <section *ngIf="(authorized$ | async) === false" class="menu">
        <button mat-button class="menu__button" (click)="page('LOGIN')">
            <span lang key="ACTIONS.LOGIN"></span>
        </button>

        <button mat-button class="menu__button--register" (click)="page('REGISTER')">
            <span lang key="MENU.REGISTER"></span>
        </button>
    </section>
</section>
