/* eslint-disable no-restricted-syntax */
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CropModalComponent } from 'src/app/templates/modals/crop-modal/crop-modal.component';
import { getOwnAcademy } from 'src/app/store/selectors/auth.selectors';
import { Academy, IMAGE_TYPES } from 'src/app/types/academy.types';
import { FILE_SIZE_MAX, IMAGES } from 'src/app/types/types';
import { environment } from 'src/environments/environment';
import { UploadScheduleImageEffectAction } from 'src/app/store/actions/auth/upload-schedule-image-effect.action';
import { UploadThumbnailAction } from 'src/app/store/actions/auth/upload-thumbnail.action';
import { UploadGalleryEffectAction } from 'src/app/store/actions/auth/upload-gallery-effect.action';
import { RemoveGalleryItemAction } from 'src/app/store/actions/auth/remove-gallery-item.action';
import { SetThumbnailAction } from 'src/app/store/actions/auth/set-trumbnail.action';
import { RemoveScheduleItemAction } from 'src/app/store/actions/auth/remove-schedule-item.action';
import { ICONS } from '../../types/types';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
    selector: 'app-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
    @Input() gallery: boolean = false;

    @Input() thumbnail: boolean = false;

    @Input() schedule: boolean = false;

    public IMAGE_PATH: string = environment.images;

    public IMAGE_DEFAULT: string = IMAGES.DEFAULT;

    public ICONS: Record<string, string> = { ...ICONS };

    public academy$: Observable<Academy>;

    public thumbnailFile: File | null;

    constructor(private store: Store, private dialog: MatDialog) {}

    public ngOnInit(): void {
        this.academy$ = this.store.select(getOwnAcademy);
    }

    public uploadImage(event, type: string) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            switch (type) {
                case IMAGE_TYPES.SCHEDULE: {
                    const getCurrentSchedule = this.academy$.subscribe((academy: Academy) => {
                        if (event.target.files.length <= 10 - academy.schedule.length) {
                            for (const i of event.target.files) {
                                if (i.size < FILE_SIZE_MAX) {
                                    const scheduleReader = new FileReader();
                                    scheduleReader.onload = (e: any) => {
                                        this.dataUrlToFile(e.target.result, i.name).then((res) => {
                                            this.store.dispatch(new UploadScheduleImageEffectAction(res));
                                        });
                                    };
                                    scheduleReader.readAsDataURL(i);
                                } else {
                                    if (!this.dialog.getDialogById('modal__error--upload')) {
                                        this.dialog.open(ModalComponent, {
                                            panelClass: 'modal__error',
                                            id: 'modal__error--upload',
                                            data: {
                                                message: 'ERRORS.IMAGE_SIZE',
                                                confirm: 'TRY_AGAIN',
                                                image: IMAGES.ERROR
                                            }
                                        });
                                    }

                                    break;
                                }
                            }

                            getCurrentSchedule.unsubscribe();
                        }
                    });
                    break;
                }
                case IMAGE_TYPES.THUMBNAIL: {
                    if (event.target.files[0].size > FILE_SIZE_MAX) {
                        this.dialog.open(ModalComponent, {
                            panelClass: 'modal__error',
                            data: {
                                message: 'ERRORS.IMAGE_SIZE',
                                confirm: 'TRY_AGAIN',
                                image: IMAGES.ERROR
                            }
                        });
                        this.thumbnailFile = null;
                        break;
                    }
                    reader.onload = () => {
                        this.dialog
                            .open(CropModalComponent, {
                                panelClass: 'modal__body-crop',
                                disableClose: true,
                                data: { src: event }
                            })
                            .afterClosed()
                            .subscribe((result) => {
                                if (result === 'cancel') {
                                    this.thumbnailFile = null;
                                    return;
                                }
                                this.dataUrlToFile(result, 'thumbnail.jpeg').then((res: File) => {
                                    if (res.size > FILE_SIZE_MAX) {
                                        if (!this.dialog.getDialogById('modal__error--upload')) {
                                            this.dialog.open(ModalComponent, {
                                                panelClass: 'modal__error',
                                                id: 'modal__error--upload',
                                                data: {
                                                    message: 'ERRORS.IMAGE_SIZE',
                                                    confirm: 'TRY_AGAIN',
                                                    image: IMAGES.ERROR
                                                }
                                            });
                                            this.thumbnailFile = null;
                                        }

                                        this.thumbnailFile = null;
                                    } else {
                                        this.thumbnailFile = null;
                                        this.store.dispatch(new UploadThumbnailAction(res));
                                    }
                                });
                            });
                    };
                    reader.readAsDataURL(event.target.files[0]);
                    break;
                }
                case IMAGE_TYPES.GALLERY: {
                    const getCurrentGallery = this.academy$.subscribe((academy: Academy) => {
                        if (event.target.files.length <= 10 - academy.gallery.length) {
                            for (const i of event.target.files) {
                                if (i.size < FILE_SIZE_MAX) {
                                    const galleryReader = new FileReader();
                                    galleryReader.onload = (e: any) => {
                                        this.dataUrlToFile(e.target.result, i.name).then((res) => {
                                            this.store.dispatch(new UploadGalleryEffectAction(res));
                                        });
                                    };
                                    galleryReader.readAsDataURL(i);
                                } else {
                                    if (!this.dialog.getDialogById('modal__error--upload')) {
                                        this.dialog.open(ModalComponent, {
                                            panelClass: 'modal__error',
                                            id: 'modal__error--upload',
                                            data: {
                                                message: 'ERRORS.IMAGE_SIZE',
                                                confirm: 'TRY_AGAIN',
                                                image: IMAGES.ERROR
                                            }
                                        });
                                    }
                                    break;
                                }
                            }

                            getCurrentGallery.unsubscribe();
                        }
                    });

                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    private async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png', endings: 'transparent' });
    }

    public removeImage(type: string, index?: number): void {
        switch (type) {
            case IMAGE_TYPES.THUMBNAIL: {
                this.store.dispatch(
                    new SetThumbnailAction({
                        name: this.IMAGE_DEFAULT,
                        id: 0
                    })
                );
                break;
            }
            case IMAGE_TYPES.GALLERY: {
                this.store.dispatch(new RemoveGalleryItemAction(index));
                break;
            }
            case IMAGE_TYPES.SCHEDULE: {
                this.store.dispatch(new RemoveScheduleItemAction(index));
                break;
            }
            default: {
                break;
            }
        }
    }
}
