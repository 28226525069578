import { Academy } from './academy.types';
import { Role, User } from './types';

export interface RestoreResponse {
    success: boolean;
    message: string;
    errors: {
        email: RestoreErrors;
    };
}
export interface RestoreErrors {
    email?: string;
}
export interface RestoreRequest {
    token: string;
    password: string;
    confirmPassword: string;
}
export interface LoginRequest {
    email: string;
    password: string;
}
export interface LoginResponse {
    is_approved?: boolean;
    role: Role;
    token: string;
}
export interface RegisterRequest {
    academy: Academy;
    user: User;
}
export interface RegisterGainerRequest {
    user: User;
    gainer: Gainer;
}
export interface Gainer {
    lat: number;
    lng: number;
    name: string;
    location: string;
    email?: string;
    year_of_birth: string;
}
export interface ChangePasswordRequest {
    current_password: string;
    password: string;
    confirm_password: string;
}

export interface ReviewsRequest {
    page: number;
    id: number;
    skills: Array<number>;
}

export const API = {
    GET: {
        AMENITIES: 'amenities',
        LOCATIONS: 'map/locations',
        ACADEMIES: 'academies',
        SPECIALIZATIONS: 'specializations',
        LOCATION_BY_NAVIGATION: 'map/coords',
        ACADEMY: 'profile/academy',
        DOCUMENTS: 'documents',
        CHECK_EMAIL: 'auth/check-email-exists',
        GAINER: 'profile',
        REVIEWS: 'academies/id/reviews',
        ACADEMY_SETTINGS: 'profile/academy/settings',
        FAVORITES: 'favorites'
    },
    POST: {
        ACADEMY_APPROVE: 'auth/academy/approve',
        RESTORE: 'auth/restore',
        RESTORE_CONFIRM: 'auth/restore/confirm',
        LOGOUT: 'auth/logout',
        LOGIN: 'auth/login',
        REGISTER: 'auth/academy/registration',
        REGISTER_GAINER: 'auth/registration',
        UPLOAD: 'images/upload',
        CHANGE_PASSWORD: 'profile/change-password',
        REVIEW: 'academies/reviews',
        FAVORITES: 'favorites',
        REPLY: 'reviews/replies',
        REPORT: 'reports',
        FEEDBACK: 'feedbacks'
    },
    PATCH: {
        ACADEMY: 'profile/academy',
        GAINER: 'profile',
        ACADEMY_SETTINGS: 'profile/academy/settings'
    },
    DELETE: {
        FAVORITES: 'favorites'
    }
};

export const GAINER_DEFAULT: Gainer = {
    lat: 0,
    lng: 0,
    name: '',
    location: '',
    email: '',
    year_of_birth: ''
};
