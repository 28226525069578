import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { EN_CONFIG, LangConfig } from 'src/app/types/lang.types';

@Directive({
    selector: '[lang]'
})
export class LangDirective implements OnInit {
    @Input() key: string;

    private lang: LangConfig = EN_CONFIG;

    path: Array<string>;

    constructor(private elementRef: ElementRef) {}

    public ngOnInit(): void {
        try {
            this.path = new Array(...this.key.split('.'));
            if (this.path.length === 2) {
                if (this.lang[this.path[0]] && this.lang[this.path[0]][this.path[1]]) {
                    this.elementRef.nativeElement.textContent = this.lang[this.path[0]][this.path[1]];
                }
            }
        } catch (error) {
            /* eslint-disable no-console */
            console.error('[lang] error: ', error);
        }
    }
}
