import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GalleryDialogData } from 'src/app/types/academy.types';
import { ICONS } from 'src/app/types/types';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {
    public zoom: number = 100;

    public IMAGE_PATH: string = environment.images;

    public ICONS = ICONS;

    constructor(@Inject(MAT_DIALOG_DATA) public gallery: GalleryDialogData, public dialogRef: MatDialogRef<ModalComponent>) {}

    public close(status: string): void {
        this.dialogRef.close(status);
    }

    public setCurrent(current: string): void {
        this.gallery.current = current;
    }

    public zoomIn(): void {
        if (this.zoom < 400) {
            this.zoom += 10;
        }
    }

    public zoomOut(): void {
        if (this.zoom > 20) {
            this.zoom -= 10;
        }
    }
}
