<section class="favorites">
    <section class="favorites__go-back">
        <mat-icon class="academy__go-back--icon" (click)="goBack()">{{ ICONS.BACK }}</mat-icon>
        <span class="favorites__title" lang key="MENU.FAVORITES_LIST"></span>
    </section>

    <section class="favorites__list">
        <div class="favorites__item" *ngFor="let favorite of favorites$ | async">
            <mat-icon (click)="remove(favorite.id)" class="favorites__icon">{{ ICONS.FAVORITE }}</mat-icon>
            <app-card class="favorite" [academy]="favorite.academy"></app-card>
        </div>
    </section>
    <app-footer></app-footer>
</section>
