import { Component, OnInit } from '@angular/core';
import { REGEXP } from 'src/app/types/types';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ForgotEffectAction } from 'src/app/store/actions/auth/forgot-effect.action';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
    public forgotForm: FormGroup;

    constructor(private fb: FormBuilder, private store: Store) {}

    public ngOnInit(): void {
        this.forgotForm = this.fb.group({
            email: new FormControl('', [Validators.required, Validators.pattern(REGEXP.EMAIL)])
        });
    }

    public forgot(): void {
        this.store.dispatch(new ForgotEffectAction(this.forgotForm.value.email));
    }
}
