import { Action } from '@ngrx/store';
import { Academy } from 'src/app/types/academy.types';

export const UPDATE_ACADEMY_EFFECT_ACTION = '[PROFILE SETTINGS] update academy effect';

export class UpdateAcademyEffectAction implements Action {
    readonly type = UPDATE_ACADEMY_EFFECT_ACTION;

    constructor(public academy: Academy) {}
}
