<section class="leave-review">
    <div mat-dialog-title class="modal__title">
        <div>
            <span lang key="REVIEW.LEAVE"></span>
            <span class="modal__title--name">{{ (academy$ | async).name }}</span>
        </div>
    </div>

    <div mat-dialog-title class="modal__content">
        <div class="modal__content--marks">
            <div class="modal__content__equipment" *ngIf="(academy$ | async).type === TYPES.GYM">
                <span lang key="REVIEW.EQUIPMENT"></span>
                <div class="stars">
                    <mat-slider class="modal__content__sider" min="0" max="5" step="0.5" [(ngModel)]="review.equipment_rating"></mat-slider>
                    <i class="background"></i>
                </div>
            </div>

            <div class="modal__content__money" *ngIf="(academy$ | async).type === TYPES.COACH">
                <span lang key="REVIEW.MONEY"></span>
                <div class="stars">
                    <mat-slider
                        class="modal__content__sider"
                        min="0"
                        max="5"
                        step="0.5"
                        [(ngModel)]="review.value_for_money_rating"
                    ></mat-slider>
                    <i class="background"></i>
                </div>
            </div>

            <div class="modal__content__willingness" *ngIf="(academy$ | async).type !== TYPES.SPORTS_CLUB">
                <span lang key="REVIEW.WILLINGNESS"></span>
                <div class="stars">
                    <mat-slider
                        class="modal__content__sider"
                        min="0"
                        max="5"
                        step="0.5"
                        [(ngModel)]="review.willingness_to_refer_rating"
                    ></mat-slider>
                    <i class="background"></i>
                </div>
            </div>

            <div class="modal__content__overall">
                <span lang key="REVIEW.OVERALL"></span>
                <div class="stars">
                    <mat-slider
                        class="modal__content__sider"
                        min="0"
                        max="5"
                        step="0.5"
                        [(ngModel)]="review.overall_impression_rating"
                    ></mat-slider>
                    <i class="background"></i>
                </div>
            </div>
        </div>

        <div class="academy__skills">
            <mat-chip-list [multiple]="true" [selectable]="true">
                <mat-chip
                    [class.selected]="skill.model"
                    (click)="selectSkills(skill)"
                    class="skill__ship"
                    *ngFor="let skill of skills"
                    [color]="'#F0F3F5'"
                >
                    <span class="skill__name">{{ skill.name }}</span>
                </mat-chip>
            </mat-chip-list>
        </div>

        <mat-form-field>
            <mat-label>
                <span lang key="REVIEW.ADD_MESSAGE"></span>
            </mat-label>
            <textarea matInput type="text" sanitize [(ngModel)]="review.text"></textarea>
        </mat-form-field>

        <section *ngIf="review.images.length !== 0">
            <section class="upload">
                <section class="upload--image" *ngFor="let image of review.images; let i = index">
                    <img (click)="show(IMAGE_PATH + image.name)" [src]="IMAGE_PATH + image.name" />
                    <mat-icon (click)="removeImage(i)" class="image__remove">{{ ICONS.CLOSE }}</mat-icon>
                </section>
            </section>
        </section>
    </div>

    <div mat-dialog-actions class="modal__actions">
        <div class="modal__actions--left">
            <input multiple id="images" accept=".jpg,.jpeg,.png" type="file" (change)="uploadImage($event)" />
            <button class="reviews__button--upload" mat-button>
                <label for="images">
                    <span lang key="ACTIONS.BROWSE_FILE"></span>
                </label>
            </button>
        </div>

        <div class="modal__actions--right">
            <button (click)="close()" mat-button>
                <span lang key="ACTIONS.CANCEL"></span>
            </button>
            <button
                [disabled]="review.skills.length === 0 || !review.text.trim()"
                class="reviews__button--send"
                mat-button
                (click)="send()"
            >
                <span lang key="ACTIONS.SEND_REVIEW"></span>
            </button>
        </div>
    </div>
</section>
