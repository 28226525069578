import { Router } from '@angular/router';
import { Subscriptions, SUBSCRIPTIONS } from 'src/app/types/state.types';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getEmail, getRole, isAuthorized, getSubscriptions } from 'src/app/store/selectors/auth.selectors';
import { ICONS, PAGES, Role, ROLES, ROUTES } from 'src/app/types/types';
import { SetMenuAction } from 'src/app/store/actions/menu/set-menu,action';
import { GetAcademySubscriptionsEffect } from 'src/app/store/actions/auth/get-academy-subscriptions-effect.action';
import { UpdateSubscriptionsEffect } from 'src/app/store/actions/auth/update-subscriptions-effect.action';

@Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
    public ICONS = { ...ICONS };

    public ROLES = { ...ROLES };

    public email: string;

    public subscriptions: Subscriptions = { ...SUBSCRIPTIONS };

    public role: Role;

    constructor(private location: Location, private store: Store, private router: Router) {}

    public ngOnInit(): void {
        this.store
            .select(isAuthorized)
            .subscribe((status: boolean) => {
                if (!status) {
                    this.router.navigate([ROUTES.GAINER_LANDING]);
                }
            })
            .unsubscribe();
        this.store.select(getRole).subscribe((role: Role) => {
            this.role = role;
            if (this.role === ROLES.ACADEMY) {
                this.store.select(getSubscriptions).subscribe((subscriptions: Subscriptions) => {
                    this.subscriptions = subscriptions;
                });

                this.store.dispatch(new GetAcademySubscriptionsEffect());
            }
        });
        this.store.select(getEmail).subscribe((email: string) => {
            this.email = email;
        });
    }

    public goBack(): void {
        this.location.back();
    }

    public changePassword(): void {
        this.store.dispatch(
            new SetMenuAction({
                open: true,
                page: PAGES.PASSWORD
            })
        );
    }

    public changeSubscription(data: any, key: string): void {
        this.subscriptions = {
            ...this.subscriptions,
            [key]: !!data.checked
        };

        this.store.dispatch(new UpdateSubscriptionsEffect(this.subscriptions));
    }
}
