import { Action } from '@ngrx/store';
import { LoginRequest } from 'src/app/types/api.types';

export const LOGIN_EFFECT = '[AUTH] login request';

export class LoginEffectAction implements Action {
    readonly type = LOGIN_EFFECT;

    constructor(public request: LoginRequest) {}
}
