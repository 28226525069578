<section class="restore--container">
    <div class="restore">
        <h1>
            <span lang key="AUTH.NEW_PASSWORD"></span>
        </h1>
        <form class="restore__form" [formGroup]="restoreForm">
            <mat-form-field>
                <mat-label>
                    <span lang key="AUTH.PASSWORD"></span>
                    <i *ngIf="!restoreForm.controls.password.invalid" class="icon__success"></i>
                </mat-label>
                <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
                <input
                    (input)="
                        restoreForm.controls.repeatPassword.setValue(restoreForm.controls.repeatPassword.value.trim()); showStrength = true
                    "
                    [class.error]="restoreForm.controls.password.invalid && restoreForm.controls.password.touched"
                    matInput
                    #password
                    formControlName="password"
                    [type]="toggle.type"
                />
                <mat-hint align="end" aria-live="polite"> {{ password.value.length }} / {{ passwordComponent.max }} </mat-hint>
            </mat-form-field>

            <mat-password-strength #passwordComponent [password]="password.value"> </mat-password-strength>

            <mat-password-strength-info *ngIf="showStrength" [passwordComponent]="passwordComponent" customCharsCriteriaMsg="">
            </mat-password-strength-info>

            <mat-form-field>
                <mat-label><span lang key="AUTH.PASSWORD_REPEAT"></span></mat-label>
                <input
                    sanitize
                    [class.error]="restoreForm.controls.repeatPassword.invalid && restoreForm.touched"
                    [type]="hideConfirmPassword ? 'password' : 'text'"
                    matInput
                    formControlName="repeatPassword"
                />
                <button
                    mat-icon-button
                    matSuffix
                    (click)="hideConfirmPassword = !hideConfirmPassword"
                    [attr.aria-pressed]="hideConfirmPassword"
                >
                    <mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="restoreForm.controls.repeatPassword.invalid">
                    <span lang key="ERRORS.REPEAT_PASSWORD"></span>
                </mat-error>
            </mat-form-field>

            <button
                [disabled]="restoreForm.controls.password.invalid || restoreForm.controls.repeatPassword.invalid"
                mat-raised-button
                class="restore__button"
                (click)="restore()"
            >
                <span lang key="ACTIONS.CHANGE_PASSWORD"></span>
            </button>
        </form>
    </div>
</section>
