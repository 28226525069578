import { Action } from '@ngrx/store';
import { Role } from 'src/app/types/types';

export const SET_ROLE = '[AUTH] set role';

export class SetRoleAction implements Action {
    readonly type = SET_ROLE;

    constructor(public role: Role) {}
}
