import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api-service.service';
import { Location } from 'src/app/types/locations.types';
import { COOKIES } from 'src/app/types/types';
import { GetLocationByCoordsEffect, GET_LOCATION_BY_COORDS } from '../actions/location/get-location-by-coords-effect.action';
import { GetLocationsAction, GET_LOCATIONS } from '../actions/location/get-locations.action';
import { SetLocationAction } from '../actions/location/set-location.action';
import { SetLocationsAction } from '../actions/location/set-locations.action';

@Injectable()
export class LocationEffects {
    constructor(private actions$: Actions, private api: ApiService, private cookie: CookieService) {}

    public getLocationsByNameEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_LOCATIONS),
            debounceTime(500),
            mergeMap((action: GetLocationsAction) =>
                this.api.getLocations(action.name).pipe(map((locations: Array<Location>) => new SetLocationsAction(locations)))
            )
        )
    );

    public getLocationsByCoordsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_LOCATION_BY_COORDS),
            debounceTime(500),
            mergeMap((action: GetLocationByCoordsEffect) =>
                this.api.getLocationsByNavigation(action.coords.lng, action.coords.lat).pipe(
                    map((locations: Array<Location>) => {
                        if (locations.length >= 1) {
                            this.cookie.set(COOKIES.LOCATION, JSON.stringify(locations[0]));
                            return new SetLocationAction(locations[0]);
                        }
                        return new SetLocationsAction(locations);
                    })
                )
            )
        )
    );
}
