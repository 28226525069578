import { Action } from '@ngrx/store';
import { Academy } from 'src/app/types/academy.types';

export const SET_ACADEMY = '[Academy] set academy';

export class SetAcademyAction implements Action {
    readonly type = SET_ACADEMY;

    constructor(public academy: Academy) {}
}
