<div class="header" [class.home-page]="isHome && !isSearch">
    <section class="logo__section">
        <div class="header__logo" [class.header__logo--search]="isSearch" (click)="home()"><i class="icon__logo"></i></div>
    </section>

    <section class="header__content">
        <search-location class="header__search" *ngIf="isSearch" [button]="true"></search-location>

        <section class="menu__section" [style.width.%]="isSearch ? 50 : 100">
            <div *ngIf="isAuthorized$ | async" class="header__menu">
                <a *ngIf="role === ROLES.GAINER" (click)="favorites()"><span lang key="MENU.FAVORITES"></span></a>
                <a (click)="feedback()"><span lang key="MENU.FEEDBACK"></span></a>

                <i class="icon__user" (click)="openMenuPage('PROFILE')"></i>
            </div>

            <div *ngIf="(isAuthorized$ | async) === false" class="header__menu">
                <a (click)="openMenuPage('LOGIN')"><span lang key="MENU.LOGIN"></span></a>
                <button
                    mat-raised-button
                    analytics
                    [type]="ANALYTIC_TYPES.SIGN_UP_CLICKED"
                    [label]="SIGN_UP_CLICKED.label(router.url)"
                    class="header-btn"
                    (click)="openMenuPage('REGISTER')"
                >
                    <span lang key="MENU.REGISTER"></span>
                </button>
            </div>
        </section>
        <mat-icon [class.menu__icon--search]="isSearch" class="menu__icon" (click)="openMenuPage('PROFILE')">{{ ICONS.MENU }}</mat-icon>
    </section>
</div>
