import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getAuthState } from 'src/app/store/selectors/auth.selectors';
import { AuthState } from 'src/app/types/state.types';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    private auth: AuthState;

    constructor(private store: Store) {
        this.store.select(getAuthState).subscribe((auth: AuthState) => {
            this.auth = { ...auth };
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clonedRequest = req.clone({
            headers: req.headers
                .append('Authorization', `Bearer ${this.auth.accessToken}`)
                .append('Access-Control-Allow-Credentials', 'true')
                .append('Access-Control-Allow-Origin', environment.domain)
        });

        return next.handle(clonedRequest);
    }
}
