import { Action } from '@ngrx/store';
import { Reply } from 'src/app/types/reviews.types';

export const SEND_REPLY_EFFECT = '[REPLY] save effect';

export class SendReplyEffect implements Action {
    readonly type = SEND_REPLY_EFFECT;

    constructor(public reply: Reply, public academy_id: number) {}
}
