<section class="register--container">
    <div class="register">
        <h1 *ngIf="register">
            <div [ngSwitch]="(academy$ | async).type">
                <div *ngSwitchCase="TYPES.COACH">
                    <span lang key="AUTH.COACH_TITLE"></span>
                </div>
                <div *ngSwitchCase="TYPES.GYM">
                    <span lang key="AUTH.GYM_TITLE"></span>
                </div>
                <div *ngSwitchCase="TYPES.SPORTS_CLUB">
                    <span lang key="AUTH.SPORTS_CLUB_TITLE"></span>
                </div>
            </div>
        </h1>
        <h2 *ngIf="register">
            <div [ngSwitch]="(academy$ | async).type">
                <div *ngSwitchCase="TYPES.COACH">
                    <span lang key="AUTH.COACH_SUB_TITLE_HEADER"></span>
                    <ul>
                        <li><span lang key="AUTH.COACH_SUB_TITLE_FIRST_ITEM"></span></li>
                        <li><span lang key="AUTH.COACH_SUB_TITLE_SECOND_ITEM"></span></li>
                        <li><span lang key="AUTH.COACH_SUB_TITLE_THIRD_ITEM"></span></li>
                    </ul>
                    <span lang key="AUTH.COACH_SUB_TITLE_FOOTER"></span>
                </div>
                <div *ngSwitchCase="TYPES.GYM">
                    <span lang key="AUTH.GYM_SUB_TITLE_HEADER"></span>
                    <ul>
                        <li><span lang key="AUTH.GYM_SUB_TITLE_FIRST_ITEM"></span></li>
                        <li><span lang key="AUTH.GYM_SUB_TITLE_SECOND_ITEM"></span></li>
                        <li><span lang key="AUTH.GYM_SUB_TITLE_THIRD_ITEM"></span></li>
                    </ul>
                    <span lang key="AUTH.GYM_SUB_TITLE_FOOTER"></span>
                </div>
                <div *ngSwitchCase="TYPES.SPORTS_CLUB">
                    <span lang key="AUTH.SPORTS_CLUB_SUB_TITLE_HEADER"></span>
                    <ul>
                        <li><span lang key="AUTH.SPORTS_CLUB_SUB_TITLE_FIRST_ITEM"></span></li>
                        <li><span lang key="AUTH.SPORTS_CLUB_SUB_TITLE_SECOND_ITEM"></span></li>
                        <li><span lang key="AUTH.SPORTS_CLUB_SUB_TITLE_THIRD_ITEM"></span></li>
                    </ul>
                    <span lang key="AUTH.SPORTS_CLUB_SUB_TITLE_FOOTER"></span>
                </div>
            </div>
        </h2>

        <form class="register__form" [formGroup]="registerForm">
            <app-upload-image *ngIf="register" [thumbnail]="true" [gallery]="true"></app-upload-image>
            <h4><span lang key="AUTH.PROFILE_INFO"></span></h4>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span class="register__form--label">{{ (academy$ | async).type }}</span
                    >&nbsp;
                    <span lang key="AUTH.TITLE"></span>
                </mat-label>
                <input
                    required
                    [class.error]="registerForm.controls.title.invalid && registerForm.controls.title.touched"
                    type="text"
                    sanitize
                    matInput
                    formControlName="title"
                />
            </mat-form-field>

            <mat-form-field>
                <mat-label>
                    <span lang key="AUTH.SHORT_DESCRIPTION"></span>
                </mat-label>
                <textarea
                    required
                    [class.error]="registerForm.controls.shortDescription.invalid && registerForm.controls.shortDescription.touched"
                    matInput
                    type="text"
                    formControlName="shortDescription"
                    maxlength="175"
                ></textarea>
                <mat-hint class="input__label input__hint"> {{ registerForm.controls.shortDescription.value.length }} / 175 </mat-hint>
            </mat-form-field>

            <mat-form-field>
                <mat-label>
                    <span lang key="AUTH.DESCRIPTION"></span>
                </mat-label>
                <textarea
                    required
                    matInput
                    type="text"
                    maxlength="1000"
                    formControlName="description"
                    [class.error]="registerForm.controls.description.invalid && registerForm.controls.description.touched"
                ></textarea>
                <mat-hint class="input__label input__hint"> {{ registerForm.controls.description.value.length }} / 1000 </mat-hint>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <span lang key="AUTH.LOCATION"></span>
                </mat-label>
                <input
                    autocomplete="off"
                    required
                    (input)="searchLocations($event)"
                    [class.error]="registerForm.controls.location.invalid && registerForm.controls.location.touched"
                    type="text"
                    sanitize
                    matInput
                    formControlName="location"
                />
                <section class="register__form--location-results" *ngIf="(locations$ | async).length">
                    <div (click)="setLocation(location)" *ngFor="let location of locations$ | async">
                        <span>{{ location.place_name }}</span>
                    </div>
                </section>
            </mat-form-field>

            <mat-form-field *ngIf="(academy$ | async).type === TYPES.GYM" class="register__price" appearance="fill">
                <mat-label>
                    <span lang key="AUTH.PRICE"></span>
                </mat-label>
                <span matSuffix lang key="MAIN.CURRENCY"></span>
                <input
                    required
                    (focusout)="priceFocusOut()"
                    [class.error]="registerForm.controls.price.invalid && registerForm.controls.price.touched"
                    type="number"
                    min="0"
                    max="100000"
                    matInput
                    formControlName="price"
                />
            </mat-form-field>

            <app-schedule-week-edit (valid)="setScheduleValid($event)" *ngIf="register"></app-schedule-week-edit>

            <button
                *ngIf="register"
                [disabled]="
                    registerForm.invalid ||
                    !scheduleValid ||
                    academy.thumbnail.id === 0 ||
                    academy.gallery.length === 0 ||
                    (academy.gallery[0].id === 0 && academy.gallery.length === 1)
                "
                mat-raised-button
                class="register__button"
                (click)="nextStep()"
            >
                <span lang key="ACTIONS.CONTINUE"></span>
            </button>
        </form>
    </div>
</section>
