import { Action } from '@ngrx/store';
import { AcademyType } from 'src/app/types/academy.types';

export const SET_TYPE = '[ACADEMIES] set type';

export class SetTypeAction implements Action {
    readonly type = SET_TYPE;

    constructor(public academyType: AcademyType) {}
}
