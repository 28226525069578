import { Directive, EventEmitter, HostListener, Output, ElementRef, Input } from '@angular/core';
import { PAGES, Page } from 'src/app/types/types';

@Directive({
    selector: '[click-outside]'
})
export class OutsideClickDirective {
    @Input() page: Page;

    @Output() outsideClick: EventEmitter<boolean> = new EventEmitter();

    @HostListener('document:mousedown', ['$event'])
    onClick(event: any): void {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            switch (this.page) {
                case PAGES.LOGIN: {
                    let close = true;

                    try {
                        event.path.forEach((element) => {
                            if (!!element.classList && element.classList.contains('modal__error')) {
                                close = false;
                            }
                        });
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error('[click-outside] error: ', error);
                    }

                    if (close) {
                        this.outsideClick.emit(true);
                    }
                    break;
                }

                default: {
                    this.outsideClick.emit(true);
                }
            }
        }
    }

    constructor(private elementRef: ElementRef) {}
}
