import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SetMenuAction } from 'src/app/store/actions/menu/set-menu,action';
import { GetAcademyReviewsEffect } from 'src/app/store/actions/reviews/get-academy-reviews-effect.action';
import { LoadMoreAcademyReviewsEffect } from 'src/app/store/actions/reviews/load-more-academy-reviews-effect.action';
import { ToggleSkillModel } from 'src/app/store/actions/reviews/toggle-skill.action';
import { getAcademy } from 'src/app/store/selectors/academy.selectors';
import { getOwnAcademy, getRole, isAuthorized } from 'src/app/store/selectors/auth.selectors';
import { getReviews, getReviewSkills, getReviewsPagination, getReviewsTotal } from 'src/app/store/selectors/reviews.selectors';
import { Academy, ACADEMY_DEFAULT, Pagination, PAGINATION_DEFAULT } from 'src/app/types/academy.types';
import { AnalyticTypes, ANALYTIC_TYPES, SignUpLabels, SIGN_UP_LABELS } from 'src/app/types/google-analytics.types';
import { Review } from 'src/app/types/reviews.types';
import { Skill } from 'src/app/types/skill.types';
import { Page, Role, Roles, ROLES } from 'src/app/types/types';
import { LeaveReviewComponent } from '../modals/leave-review/leave-review.component';

@Component({
    selector: 'app-reviews',
    templateUrl: './reviews.component.html',
    styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
    public ANALYTIC_TYPES: AnalyticTypes = { ...ANALYTIC_TYPES };

    public SIGN_UP_LABELS: SignUpLabels = { ...SIGN_UP_LABELS };

    public authorized: boolean = false;

    public totalRating: number = 1;

    public ownAcademyID: number = 0;

    public academy: Academy = ACADEMY_DEFAULT;

    public role: Role = ROLES.GAINER;

    public pagination: Pagination = { ...PAGINATION_DEFAULT };

    public ROLES: Roles = { ...ROLES };

    public skills$: Observable<Array<Skill>>;

    public total$: Observable<number>;

    public reviews$: Observable<Array<Review>>;

    constructor(private store: Store, private dialog: MatDialog) {}

    public ngOnInit(): void {
        this.total$ = this.store.select(getReviewsTotal);
        this.reviews$ = this.store.select(getReviews);
        this.skills$ = this.store.select(getReviewSkills);
        this.store.select(getOwnAcademy).subscribe((academy: Academy) => {
            this.ownAcademyID = academy.id;
        });

        this.store.select(getReviewsPagination).subscribe((pagination: Pagination) => {
            this.pagination = pagination;
        });

        this.store.select(isAuthorized).subscribe((status: boolean) => {
            this.authorized = status;
        });

        this.reviews$.subscribe((reviews: Array<Review>) => {
            let count = 0;
            reviews.forEach((review: Review) => {
                count += review.total_rating;
            });
            this.totalRating = count / reviews.length;
        });

        this.store.select(getRole).subscribe((role: Role) => {
            this.role = role;
        });
        this.store.select(getAcademy).subscribe((academy: Academy) => {
            if (academy.id && this.authorized) {
                this.academy = academy;
                this.store.dispatch(
                    new GetAcademyReviewsEffect({
                        page: 1,
                        skills: [academy.skills[0].id ? academy.skills[0].id : 0],
                        id: academy.id
                    })
                );
            }
        });
    }

    public leaveReview(): void {
        this.dialog.open(LeaveReviewComponent, {
            disableClose: true
        });
    }

    public open(page: Page): void {
        this.store.dispatch(
            new SetMenuAction({
                open: true,
                page
            })
        );
    }

    public switchSkill(skill: Skill): void {
        this.store.dispatch(new ToggleSkillModel(skill.id));

        this.store
            .select(getReviewSkills)
            .subscribe((skills: Skill[]) => {
                this.store.dispatch(
                    new GetAcademyReviewsEffect({
                        page: 1,
                        skills: [...skills.filter((s: Skill) => s.model === true).map((s) => s.id)],
                        id: this.academy.id
                    })
                );
            })
            .unsubscribe();
    }

    public loadMore(page: number): void {
        this.store
            .select(getReviewSkills)
            .subscribe((skills: Skill[]) => {
                this.store.dispatch(
                    new LoadMoreAcademyReviewsEffect({
                        page,
                        skills: [...skills.filter((s: Skill) => s.model === true).map((s) => s.id)],
                        id: this.academy.id
                    })
                );
            })
            .unsubscribe();
    }
}
