import { Action } from '@ngrx/store';
import { RestoreRequest } from 'src/app/types/api.types';

export const RESTORE_EFFECT = '[AUTH] restore request';

export class RestoreEffectAction implements Action {
    readonly type = RESTORE_EFFECT;

    constructor(public request: RestoreRequest) {}
}
