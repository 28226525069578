import { Action } from '@ngrx/store';
import { Filter } from 'src/app/types/academy.types';

export const LOAD_MORE_ACADEMIES_EFFECT = '[ACADEMIES] load more effect';

export class LoadMoreAcademiesEffect implements Action {
    readonly type = LOAD_MORE_ACADEMIES_EFFECT;

    constructor(public filter: Filter) {}
}
