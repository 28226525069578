<section class="password--container">
    <div class="password">
        <h1>
            <span lang key="AUTH.CHANGE_PASSWORD"></span>
        </h1>
        <form class="password__form" [formGroup]="changePasswordForm">
            <mat-form-field>
                <mat-label>
                    <span lang key="AUTH.OLD_PASSWORD"></span>
                    <i *ngIf="!changePasswordForm.controls.oldPassword.invalid" class="icon__success"></i>
                </mat-label>
                <input
                    [class.error]="changePasswordForm.controls.oldPassword.invalid && changePasswordForm.controls.oldPassword.touched"
                    matInput
                    sanitize
                    (input)="
                        changePasswordForm.controls.repeatPassword.setValue(changePasswordForm.controls.repeatPassword.value.trim());
                        changePasswordForm.controls.password.setValue(changePasswordForm.controls.password.value.trim())
                    "
                    [type]="hideOldPassword ? 'password' : 'text'"
                    formControlName="oldPassword"
                />
                <button mat-icon-button matSuffix (click)="hideOldPassword = !hideOldPassword" [attr.aria-pressed]="hideOldPassword">
                    <mat-icon>{{ hideOldPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="changePasswordForm.controls.oldPassword.invalid">
                    <span lang key="ERRORS.OLD_PASSWORD"></span>
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>
                    <span lang key="AUTH.ENTER_NEW_PASSWORD"></span>
                    <i *ngIf="!changePasswordForm.controls.password.invalid" class="icon__success"></i>
                </mat-label>
                <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
                <input
                    (input)="
                        changePasswordForm.controls.repeatPassword.setValue(changePasswordForm.controls.repeatPassword.value.trim());
                        showStrength = true
                    "
                    [class.error]="changePasswordForm.controls.password.invalid && changePasswordForm.controls.password.touched"
                    matInput
                    #password
                    formControlName="password"
                    [type]="toggle.type"
                />
                <mat-hint align="end" aria-live="polite"> {{ password.value.length }} / {{ passwordComponent.max }} </mat-hint>
            </mat-form-field>

            <mat-password-strength #passwordComponent [password]="password.value"> </mat-password-strength>

            <mat-password-strength-info *ngIf="showStrength" [passwordComponent]="passwordComponent" customCharsCriteriaMsg="">
            </mat-password-strength-info>

            <mat-form-field>
                <mat-label>
                    <span lang key="AUTH.NEW_PASSWORD_REPEAT"></span>
                    <i *ngIf="!changePasswordForm.controls.repeatPassword.invalid" class="icon__success"></i>
                </mat-label>
                <input
                    sanitize
                    [class.error]="changePasswordForm.controls.repeatPassword.invalid && changePasswordForm.controls.repeatPassword.touched"
                    [type]="hideConfirmPassword ? 'password' : 'text'"
                    matInput
                    formControlName="repeatPassword"
                />
                <button
                    mat-icon-button
                    matSuffix
                    (click)="hideConfirmPassword = !hideConfirmPassword"
                    [attr.aria-pressed]="hideConfirmPassword"
                >
                    <mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="changePasswordForm.controls.repeatPassword.invalid">
                    <span lang key="ERRORS.NEW_REPEAT_PASSWORD"></span>
                </mat-error>
            </mat-form-field>

            <button [disabled]="changePasswordForm.invalid" mat-raised-button class="password__button" (click)="changePassword()">
                <span lang key="ACTIONS.CHANGE_PASSWORD"></span>
            </button>
        </form>
    </div>
</section>
