<section class="modal">
    <div mat-dialog-title class="modal__title">
        <mat-icon (click)="close('cancel')">{{ ICONS.CLOSE }}</mat-icon>
    </div>

    <div mat-dialog-title class="modal__content">
        <mat-icon class="icon__zoom--in" (click)="zoomIn()">{{ ICONS.ZOOM_IN }}</mat-icon>
        <mat-icon class="icon__zoom--out" (click)="zoomOut()">{{ ICONS.ZOOM_OUT }}</mat-icon>

        <div class="current__container">
            <section class="current">
                <img [style.width.%]="zoom" src="{{ gallery.current }}" />
            </section>
        </div>

        <carousel class="carousel" *ngIf="gallery.images.length !== 0">
            <div class="carousel-cell" *ngFor="let image of gallery.images">
                <img
                    *ngIf="image.id"
                    class="carousel-image"
                    (click)="setCurrent(IMAGE_PATH + image.name)"
                    src="{{ IMAGE_PATH + image.name }}"
                />
            </div>
        </carousel>
    </div>

    <div mat-dialog-actions class="modal__actions">
        <button class="modal__button--cancel" mat-button cdkFocusInitial (click)="close('cancel')">
            <span lang key="ACTIONS.CANCEL"></span>
        </button>
    </div>
</section>
